import { useContext } from "react"
import { UserContext } from "app/User/context"
import { getAccounts } from "services/accounts"
import { getDevices } from "services/devices"
import { AccountsContext } from "app/Account/context"
import { AccountTypes, AccountsActions } from "app/Account/actions"
import { DevicesContext } from "app/Devices/context"
import { DevicesActions, DevicesTypes } from "app/Devices/actions"
import { UserActions, UserActionTypes } from "app/User/actions"
import { getUsers } from "services/users"
import { UserTypeEnum } from "../User/types"
import { useFirestoreCompany } from "services/firestore/company"
import { useFirestoreUser } from "services/firestore/user"
import { useFirestoreDevices } from "services/firestore/devices"
import {
  Beacon,
  DeviceCategoryEnum,
  DeviceStockStatusEnum,
  StockDevice,
  Tracker,
} from "app/Devices/types"
import { ITapBarItem } from "lib/Navigation/navigation.types"
import { useFirebaseFunctions } from "services/firebase-functions/functions"
import cogoToast from "@clevertrackdk/cogo-toast"
import { InstallationContext } from "app/Installation/context"
import uniq from "lodash-es/uniq"

export const useBackgroundDataFetch = () => {
  const {
    state: { user },
    dispatch: usersDispatch,
  } = useContext(UserContext)
  const {
    state: { account },
    dispatch: accountsDispatch,
  } = useContext(AccountsContext)
  const { dispatch: devicesDispatch } = useContext(DevicesContext)
  const { getFirebaseCompanies } = useFirestoreCompany()
  const { getAllFirebaseUsers } = useFirestoreUser()
  const { getFirebaseDevicesFromAccounts, getFirebaseDevices } = useFirestoreDevices()
  const { getFotaConfigurationFiles } = useFirebaseFunctions()

  const getApiAccounts = async () => {
    const accountsResponse = await getAccounts()

    if (accountsResponse.result === "OK" && accountsResponse?.accounts) {
      const fbAccounts = await getFirebaseCompanies()
      const mappedAccounts = accountsResponse.accounts.map((acc) => {
        const fbAccount = fbAccounts.find((x) => +x.id === +acc.id)
        if (fbAccount) {
          return {
            ...acc,
            ...fbAccount,
            mapLatitude: fbAccount.map?.center[0],
            mapLongitude: fbAccount.map?.center[1],
            defaultMapZoom: fbAccount.map?.defaultZoom,
          }
        }
        return acc
      })
      accountsDispatch(
        AccountsActions(AccountTypes.SetAccounts, {
          accounts: mappedAccounts,
        })
      )
      accountsDispatch(
        AccountsActions(AccountTypes.SetAccount, {
          account: mappedAccounts.find((acc) => +acc.id === +user?.companyID),
        })
      )
    }
    return
  }

  const getApiUsers = async () => {
    const usersResponse = await getUsers()

    if (usersResponse.result === "OK" && usersResponse?.users) {
      const firebaseUsers = await getAllFirebaseUsers()
      const mappedUsers = usersResponse.users.map((user) => {
        const fbUser = firebaseUsers
          ? firebaseUsers.find((u) => +u.id === +user.id)
          : {}
        const userTypes: UserTypeEnum[] = fbUser?.userTypes
          ? [...fbUser.userTypes]
          : []
        if (user.is_cms_admin) userTypes.push(UserTypeEnum.CMSADMIN)
        if (user.is_admin) userTypes.push(UserTypeEnum.ADMIN)
        // if (userTypes.length === 0) userTypes.push(UserTypeEnum.USER)

        return {
          ...user,
          ...fbUser,
          userTypes,
        }
      })

      usersDispatch(
        UserActions(UserActionTypes.SetUserList, {
          users: mappedUsers,
        })
      )
    }
  }

  const getApiDevices = async () => {
    try {
      const devicesResponse = await getDevices()
      const firebaseDevices = (await getFirebaseDevices()) as StockDevice[]

      if (
        devicesResponse.result === "OK" &&
        devicesResponse?.devices &&
        firebaseDevices.length > 0
      ) {
        const mappedDevices: Partial<
          (Beacon | Tracker) & StockDevice
        >[] = devicesResponse.devices.map((apiDevice) => {
          const firebaseDevice = firebaseDevices.find(
            (dev) => +dev.imei === +apiDevice.imei
          )

          if (firebaseDevice) {
            // There's a match. Firebase knows something about this device.
            return {
              ...firebaseDevice,
              ...apiDevice,
              // We need to ensure we return the right ID.
              // In firebase, the ID is the same as the vehicle_id, and the unit_id is the same as the id of the device from the deviceResponse.
              // It should default to null, if the device is not mounted to a vehicle.
              account: firebaseDevice.account || apiDevice.account,
              accountID: firebaseDevice.accountID || apiDevice.accountID,
              vehicleID:
                apiDevice.vehicle_id === 0
                  ? null
                  : apiDevice.vehicle_id || firebaseDevice.id,
              id: apiDevice.id || firebaseDevice.unit_id,
              deviceType: +apiDevice.deviceType,
              deviceTypeName: apiDevice.deviceTypeName,
              imei: apiDevice.imei.toString() || firebaseDevice.imei.toString(),
              stockStatus:
                firebaseDevice.stockStatus || DeviceStockStatusEnum.Legacy,
              created: firebaseDevice.createdAt ?? apiDevice.created,
              deviceCategory:
                !!apiDevice.uuid || !!firebaseDevice.uuid
                  ? DeviceCategoryEnum.Beacon
                  : DeviceCategoryEnum.Tracker,
            }
          } else {
            // Firebase knows nothing of this device.
            return {
              ...apiDevice,
              account: apiDevice.account,
              accountID: apiDevice.accountID,
              vehicleID:
                apiDevice.vehicle_id === 0 ? null : apiDevice.vehicle_id,
              id: apiDevice.id,
              deviceType: +apiDevice.deviceType,
              deviceTypeName: apiDevice.deviceTypeName,
              imei: apiDevice.imei.toString(),
              uuid: apiDevice.uuid !== "" ? apiDevice.uuid : null,
              stockStatus: DeviceStockStatusEnum.Legacy,
              created: apiDevice.created,
              deviceCategory: !!apiDevice?.uuid
                ? DeviceCategoryEnum.Beacon
                : DeviceCategoryEnum.Tracker,
            }
          }
        })

        // Finally, we also need to load up devices that firestore knows about, but the API doesn't.
        const imeisConsumed = mappedDevices.map((device) => device.imei)
        const stockFirebaseDevices = firebaseDevices
          .filter((device) => !imeisConsumed.includes(device.imei))
          .map((dvc) => {
            return {
              ...dvc,
              id: dvc.unit_id,
              vehicleID: dvc.id,
            }
          })

        // To avoid duplication, we need to merge the mappedFirebaseDevices with data from the API

        devicesDispatch(
          DevicesActions(DevicesTypes.SetDevices, {
            devices: [...mappedDevices, ...stockFirebaseDevices],
          })
        )
      }
    } catch (error) {
      console.log("Error:", error)
    }
  }

  const getApiDevicesFromAccount = async (accountID: string | null = null) => {
    try {
      const accountIDs = uniq([accountID, account?.id]).filter(x => x !== null && x !== undefined)
      const devicesResponse = await getDevices()
      const firebaseDevices = (await getFirebaseDevicesFromAccounts(accountIDs)) as StockDevice[]

      if (
        devicesResponse.result === "OK" &&
        devicesResponse?.devices &&
        firebaseDevices.length > 0
      ) {
        const mappedDevices: Partial<
          (Beacon | Tracker) & StockDevice
        >[] = devicesResponse.devices.filter((x) => accountIDs.includes(x.account_id)).map((apiDevice) => {
          const firebaseDevice = firebaseDevices.find(
            (dev) => +dev.imei === +apiDevice.imei
          )
          if (firebaseDevice) {
            // There's a match. Firebase knows something about this device.
            return {
              ...firebaseDevice,
              ...apiDevice,
              // We need to ensure we return the right ID.
              // In firebase, the ID is the same as the vehicle_id, and the unit_id is the same as the id of the device from the deviceResponse.
              // It should default to null, if the device is not mounted to a vehicle.
              account: firebaseDevice.account || apiDevice.account,
              accountID: firebaseDevice.accountID || apiDevice.accountID,
              vehicleID:
                apiDevice.vehicle_id === 0 ? null : apiDevice.vehicle_id,
              id: apiDevice.id || firebaseDevice.unit_id,
              deviceType: +apiDevice.deviceType,
              deviceTypeName: apiDevice.deviceTypeName,
              imei: apiDevice.imei.toString() || firebaseDevice.imei.toString(),
              stockStatus:
                firebaseDevice.stockStatus || DeviceStockStatusEnum.Legacy,
              created: firebaseDevice.createdAt ?? apiDevice.created,
              deviceCategory:
                !!apiDevice.uuid || !!firebaseDevice.uuid
                  ? DeviceCategoryEnum.Beacon
                  : DeviceCategoryEnum.Tracker,
            }
          } else {
            // Firebase knows nothing of this device.
            return {
              ...apiDevice,
              account: apiDevice.account,
              accountID: apiDevice.accountID,
              vehicleID:
                apiDevice.vehicle_id === 0 ? null : apiDevice.vehicle_id,
              id: apiDevice.id,
              deviceType: +apiDevice.deviceType,
              deviceTypeName: apiDevice.deviceTypeName,
              imei: apiDevice.imei.toString(),
              uuid: apiDevice.uuid !== "" ? apiDevice.uuid : null,
              stockStatus: DeviceStockStatusEnum.Legacy,
              created: apiDevice.created,
              deviceCategory: !!apiDevice?.uuid
                ? DeviceCategoryEnum.Beacon
                : DeviceCategoryEnum.Tracker,
            }
          }
        })

        // Finally, we also need to load up devices that firestore knows about, but the API doesn't.
        const imeisConsumed = mappedDevices.map((device) => device.imei)
        const stockFirebaseDevices = firebaseDevices
          .filter((device) => !imeisConsumed.includes(device.imei))
          .map((dvc) => {
            return {
              ...dvc,
              id: dvc.unit_id,
              vehicleID: dvc.id,
            }
          })

        // To avoid duplication, we need to merge the mappedFirebaseDevices with data from the API

        devicesDispatch(
          DevicesActions(DevicesTypes.SetDevices, {
            devices: [...mappedDevices, ...stockFirebaseDevices],
          })
        )
      }
    } catch (error) {
      console.log("Error:", error)
    }
  }

  const getConfigurationFiles = async () => {
    try {
      const cfgFilesResponse = await getFotaConfigurationFiles()
      if (
        cfgFilesResponse.data.status === "OK" &&
        cfgFilesResponse.data.files.length > 0
      ) {
        devicesDispatch(
          DevicesActions(DevicesTypes.SetConfigurationFiles, {
            files: cfgFilesResponse.data.files,
          })
        )
      }
    } catch (e) {
      cogoToast.error("Kunne ikke indlæse enhedskonfigurationsfiler")
      throw new Error(e)
    }
  }

  return {
    getApiAccounts,
    getApiDevices,
    getApiDevicesFromAccount,
    getApiUsers,
    getConfigurationFiles,
  }
}

export const useNavigationItems = () => {
  const {
    state: { user },
  } = useContext(UserContext)

  const tapBarNavigationItems: ITapBarItem[] = [
    {
      key: "nav_toggle",
      icon: "menu",
      title: "Menu",
      isNavToggle: true,
    },
  ]

  return { tapBarNavigationItems }
}
