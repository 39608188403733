import { useContext } from "react"
import { FirebaseContext } from "context/FirebaseProvider"
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore"
import {
  InstallationScreenEnum,
  InstallationStep,
} from "app/Installation/types"
import { DeviceAuditLogItem } from "app/AuditLog/audit.types"
import { v4 as uuidv4 } from "uuid"

export const useFirestoreDevices = () => {
  const { db } = useContext(FirebaseContext)

  const createFirebaseDevices = async (devices) => {
    if (db) {
      try {
        const batch = writeBatch(db)
        for (const device of devices) {
          batch.set(doc(db, `devices/${device.imei.toString()}`), device)
        }
        const result = await batch.commit()

        return {
          result: "OK",
        }
      } catch (error) {
        console.log(error)
        return {
          result: "Error",
        }
      }
    }
  }

  const getFirebaseDeviceFromIMEI = async (imei: string) => {
    if (db) {
      const firebaseDevice = doc(db, `devices`, imei)
      try {
        const res = await getDoc(firebaseDevice)

        return {
          device: res.data(),
          result: "OK",
        }
      } catch (error) {
        console.log(error)
        return {
          result: "Error",
        }
      }
    }
    return {
      result: "Error",
    }
  }

  const saveFirebaseDevice = async (args: any, deviceID: string) => {
    if (db) {
      const firebaseDevice = doc(db, `devices`, deviceID)
      try {
        const res = await setDoc(
          firebaseDevice,
          {
            ...args,
            updated: serverTimestamp(),
          },
          { merge: true }
        )

        return {
          result: "OK",
        }
      } catch (error) {
        console.log(error)
        return {
          result: "Error",
        }
      }
    }
  }

  const getFirebaseDevices = async () => {
    if (db) {
      try {
        const firebaseDevices = collection(db, `devices`)

        const res = await getDocs(firebaseDevices)

        return res.docs.map((doc) => doc.data())
      } catch (error) {
        console.log(error)
        return {
          result: "Error",
        }
      }
    }
  }

  const getFirebaseDevicesFromAccounts = async (accountIDs: string[] | null = null) => {
    if (db) {
      try {
        const firebaseDevices = collection(db, `devices`)
        if (accountIDs) {
          const q = query(firebaseDevices, where("accountID", "in", accountIDs))
          const res = await getDocs(q)
          return res.docs.map((doc) => doc.data())
        }

        const res = await getDocs(firebaseDevices)

        return res.docs.map((doc) => doc.data())
      } catch (error) {
        console.log(error)
        return {
          result: "Error",
        }
      }
    }
  }

  const assignFirebaseDevicesToAccount = async (devices) => {
    if (db) {
      try {
        const batch = writeBatch(db)
        for (const device of devices) {
          batch.set(doc(db, `devices/${device.imei.toString()}`), device, {
            merge: true,
          })
        }
        await batch.commit()

        return {
          result: "OK",
        }
      } catch (error) {
        console.log(error)
        return {
          result: "Error",
        }
      }
    }
  }

  const getInstallationStepsByImei = async (imei) => {
    if (db) {
      try {
        const firebaseDeviceInstallationSteps = collection(
          db,
          `devices/${imei}/installation`
        )
        const res = await getDocs(firebaseDeviceInstallationSteps)
        return res.docs.map((doc) => doc.data())
      } catch (error) {
        console.log(error)
        return {
          result: "Error",
        }
      }
    }
  }

  const deleteInstallationStepsByImei = async (imei) => {
    if (db) {
      try {
        const firebaseDeviceInstallationSteps = collection(
          db,
          `devices/${imei}/installation`
        )
        const res = await getDocs(firebaseDeviceInstallationSteps)
        const deleteDocsResponse = res.docs.map((doc) => deleteDoc(doc.ref))
        await Promise.all(deleteDocsResponse)
        return {
          result: "OK",
        }
      } catch (error) {
        console.log(error)
        return {
          result: "Error",
        }
      }
    }
  }

  const saveInstallationStepByImei = async (
    imei,
    id: InstallationScreenEnum,
    model: InstallationStep
  ) => {
    if (db) {
      try {
        const firebaseDeviceInstallationStep = doc(
          db,
          `devices/${imei}/installation/${id}`
        )
        await setDoc(firebaseDeviceInstallationStep, {
          ...model,
        })
        return {
          result: "OK",
        }
      } catch (error) {
        console.log(error)
        return {
          result: "Error",
        }
      }
    }
  }

  const newDeviceAuditLogEntry = async (imei, logItem: DeviceAuditLogItem) => {
    if (db) {
      try {
        const id = uuidv4()
        const firebaseDeviceInstallationStep = doc(
          db,
          `devices/${imei}/auditlog/${id}`
        )
        await setDoc(firebaseDeviceInstallationStep, {
          ...logItem,
          id,
        })
        return {
          result: "OK",
        }
      } catch (error) {
        console.log(error)
        return {
          result: "Error",
        }
      }
    }
  }

  return {
    createFirebaseDevices,
    saveFirebaseDevice,
    getFirebaseDeviceFromIMEI,
    getFirebaseDevicesFromAccounts,
    getFirebaseDevices,
    assignFirebaseDevicesToAccount,
    getInstallationStepsByImei,
    deleteInstallationStepsByImei,
    saveInstallationStepByImei,
    newDeviceAuditLogEntry,
  }
}
