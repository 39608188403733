import React from "react"
import styled from "styled-components"
import "twin.macro"
import { default as DefaultSelect } from "react-select"
import Checkbox from "lib/Checkbox"

const StyledSelectWrapper = styled.div`
  position: relative;
  z-index: 500;
`

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: 0,
    border: "none",
    boxShadow: state.isFocused ? "0px 2px 4px -2px rgba(0, 0, 0, 0.3)" : "none",
    // paddingTop: "2.4rem",
    background: "transparent",
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.2)",
    },
  }),

  menu: (provided, state) => ({
    ...provided,
    borderRadius: 0,
  }),

  menuList: (provided) => ({
    ...provided,
    display: "grid",
  }),

  multiValueLabel: (provided, state) => ({
    ...provided,
    borderRadius: 0,
    background: "#647173",
    color: "#fff",
  }),

  indicatorsContainer: (provided, state) => ({
    ...provided,
    minWidth: "3.2rem",
    position: "relative",
    alignItems: "end",
    svg: {
      position: "static",
      right: "1rem",
      bottom: "1rem",
      width: "1.6rem",
    },
  }),

  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none",
  }),

  multiValueRemove: (provided, state) => ({
    ...provided,
    position: "relative",
    width: "2rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    background: "#647173",
    borderRadius: 0,
    color: "#fff",
    ":hover": {
      background: "#FF671D",
      color: "#fff",
    },
    svg: {
      position: "static",
    },
  }),

  option: (provided, state) => ({
    ...provided,
    background: state.isSelected ? "#374649" : "white",
    ":hover": {
      background: "#5f6b6d",
      color: "white",
    },
  }),
}

const CheckboxOption = ({ innerRef, innerProps, data, ...props }) => {
  const handleChange = () => {
    // Call the selectOption prop to toggle selection
    props.selectOption(data)
  }

  return (
    <div
      ref={innerRef}
      {...innerProps}
      tw="flex items-center cursor-pointer justify-between p-4 px-6 hover:bg-brand-gray-brand"
    >
      <span tw="w-3/4 block">{data.label}</span>
      <Checkbox
        id={data.value}
        checked={props.isSelected}
        onChange={handleChange}
        appearance="checkbox"
        icon="check"
        size="md"
      />
    </div>
  )
}

const Select = ({
  children,
  size,
  theme,
  style,
  useCheckbox,
  customStyles: modifiedCustomStyles,
  ...props
}) => {
  const components = useCheckbox ? { Option: CheckboxOption } : {}
  return (
    <StyledSelectWrapper size={size} theme={theme} style={style}>
      <DefaultSelect
        styles={{ ...customStyles, ...modifiedCustomStyles }}
        {...props}
        components={components}
      />
    </StyledSelectWrapper>
  )
}

export { Select, CheckboxOption }
