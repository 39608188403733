import React, { useContext, useMemo, useRef } from "react"
import "twin.macro"
import {
  FlowHeader,
  ScreenHeader,
  StyledPopoverContentContainer,
} from "../components"
import {
  InstallationScreenEnum,
  InstallationStepStateEnum,
  InstallationStepType,
  InstallationVerificationModel,
} from "../../types"
import { InstallationContext } from "../../context"
import { useFileUpload } from "app/FileUpload/hooks"
import { useFormData } from "hooks/useFormData"
import {
  Button,
  ButtonGroup,
  Form,
  Icon,
} from "@clevertrack/shared"
import { useInstallation } from "../../hooks"
import { DeviceTypeCategoryEnum } from "app/Devices/types"
import {
  getInstallationStepState,
  gpsTrackerWithAutoOffSupport,
} from "../helper"
import { debounce, isEqual } from "lodash-es"
import { useFirestoreDevices } from "services/firestore/devices"
import { useTranslation } from "react-i18next"

// Import refactored components
import { MountingPhotoSection } from "./components/MountingPhotoSection"
import { CANDataReaderSection } from "./components/CANDataReaderSection"
import { PlacementPhotoSection } from "./components/PlacementPhotoSection"
import { VehiclePhotoSection } from "./components/VehiclePhotoSection"
import { PowerSourceSection } from "./components/PowerSourceSection"
import { AutoOffSection } from "./components/AutoOffSection"

export const InstallationVerification: React.FC<InstallationStepType> = ({
  onSave,
  ...props
}) => {
  const {
    state: { account, device, installationSteps, currentScreen },
  } = useContext(InstallationContext)
  const { t } = useTranslation()

  const stepData = useMemo(() => {
    if (installationSteps) {
      const step = installationSteps.find(
        (step) =>
          step.stepID === InstallationScreenEnum.InstallationVerification
      )
      if (step) {
        return step
      }
      return null
    }
    return null
  }, [installationSteps])

  const [
    currentModel,
    updateModel,
    resetModel,
    setCurrentModel,
  ] = useFormData<InstallationVerificationModel>(
    stepData?.data
  )
  const { deviceTypeCategory, setCurrentScreen } = useInstallation()
  const { saveFirebaseDevice } = useFirestoreDevices()
  const {
    onUploadDocumentHandler,
    uploadMap,
    removeItemFromUploadMapByKey,
  } = useFileUpload(`${account?.id}/${device?.imei}`, account?.id)

  const autoSaveFunc = useRef(
    debounce((newData) => {
      onSave(InstallationScreenEnum.InstallationVerification, newData, null, {
        autoSave: true,
      })
    }, 2000)
  )

  useMemo(() => {
    if (
      currentModel &&
      currentScreen === InstallationScreenEnum.InstallationVerification &&
      !isEqual(stepData?.data, currentModel)
    ) {
      const newData = {
        ...stepData,
        data: currentModel,
        stepState: InstallationStepStateEnum.Incomplete,
      }
      autoSaveFunc.current.cancel()
      autoSaveFunc.current(newData)
    }
  }, [currentModel, stepData, autoSaveFunc, currentScreen])

  const onSubmitHandler = () => {
    if (autoSaveFunc.current) autoSaveFunc.current.cancel()
    const newData = {
      ...stepData,
      data: currentModel,
      stepState: getInstallationStepState(
        currentModel,
        stepData.optionalDataProps
      ),
    }
    const apiSave = currentModel.deviceMountingNote
      ? saveFirebaseDevice(
        {
          patchVehiclePayload: {
            description: currentModel?.deviceMountingNote ?? null,
            customer_id: device?.customer_id ?? 0,
            subscription_type: device?.subscription_type ?? 0,
            subscription_addon: device?.subscription_addon ?? 0,
            aempid: device?.aempid ?? "",
          },
          deviceCalibrationPayload: {
            description: currentModel.deviceMountingNote,
          },
        },
        device.imei
      )
      : null

    onSave(
      InstallationScreenEnum.InstallationVerification,
      newData,
      Promise.all([apiSave].filter(Boolean))
    )
  }

  const onAcceptFilesHandler = async (files, filename, key) => {
    const result = await onUploadDocumentHandler(files, filename, key)
  }

  useMemo(() => {
    Object.values(uploadMap).map((value) => {
      if (value.key) {
        updateModel(value.key, value.downloadURL)
        updateModel(`${value.key}Path`, value.path)
      }
    })
  }, [uploadMap])

  const mountingDescription = `Tracker${deviceTypeCategory === DeviceTypeCategoryEnum.GPSTrackerWithBattery
    ? ``
    : ` ${t("installation_mounting_photo_description")}`
    } skal være tydeligt synlig`

  return (
    <StyledPopoverContentContainer>
      <FlowHeader />
      <ScreenHeader>{t("installation_mounting")}</ScreenHeader>
      <Form onSubmit={onSubmitHandler} {...props}>
        <div tw="p-4 space-y-8 pb-16">
          {deviceTypeCategory !== DeviceTypeCategoryEnum.Beacon && (
            <MountingPhotoSection
              currentModel={currentModel}
              updateModel={updateModel}
              onAcceptFilesHandler={onAcceptFilesHandler}
              removeItemFromUploadMapByKey={removeItemFromUploadMapByKey}
              deviceTypeCategory={deviceTypeCategory}
              mountingDescription={mountingDescription}
            />
          )}

          {deviceTypeCategory === DeviceTypeCategoryEnum.GPSTrackerWithCAN && (
            <CANDataReaderSection
              currentModel={currentModel}
              updateModel={updateModel}
              setCurrentModel={setCurrentModel}
              onAcceptFilesHandler={onAcceptFilesHandler}
              removeItemFromUploadMapByKey={removeItemFromUploadMapByKey}
            />
          )}

          {deviceTypeCategory !== DeviceTypeCategoryEnum.Beacon && (
            <PlacementPhotoSection
              currentModel={currentModel}
              updateModel={updateModel}
              onAcceptFilesHandler={onAcceptFilesHandler}
              removeItemFromUploadMapByKey={removeItemFromUploadMapByKey}
            />
          )}

          <VehiclePhotoSection
            currentModel={currentModel}
            updateModel={updateModel}
            onAcceptFilesHandler={onAcceptFilesHandler}
            removeItemFromUploadMapByKey={removeItemFromUploadMapByKey}
          />

          {![
            DeviceTypeCategoryEnum.Beacon,
            DeviceTypeCategoryEnum.GPSTrackerWithBattery,
          ].includes(deviceTypeCategory) && (
              <PowerSourceSection
                currentModel={currentModel}
                updateModel={updateModel}
                setCurrentModel={setCurrentModel}
              />
            )}

          {gpsTrackerWithAutoOffSupport.includes(+device?.deviceType) && (
            <AutoOffSection
              currentModel={currentModel}
              stepData={stepData}
              updateModel={updateModel}
              setCurrentModel={setCurrentModel}
              onAcceptFilesHandler={onAcceptFilesHandler}
              removeItemFromUploadMapByKey={removeItemFromUploadMapByKey}
            />
          )}
        </div>

        <ButtonGroup sticky="bottom" tw="bg-white px-4 z-50">
          <Button
            type="button"
            variant="cancel"
            onClick={() => setCurrentScreen(InstallationScreenEnum.Tasks)}
          >
            <span tw="flex items-center">
              <Icon icon="chevron-left" tw="w-4 h-4 mr-2" />
              <span tw="text-xl font-normal">{t("installation_back")}</span>
            </span>
          </Button>
          <Button
            type="submit"
            variant="primary"
            disabled={
              stepData &&
              getInstallationStepState(
                currentModel,
                stepData?.optionalDataProps
              ) !== InstallationStepStateEnum.Completed
            }
          >
            {t("installation_save")}
          </Button>
        </ButtonGroup>
      </Form>
    </StyledPopoverContentContainer>
  )
}