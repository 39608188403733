import React, { useEffect, useState } from "react"
import tw from "twin.macro"
import { useDropzone } from "react-dropzone"
import { Loading, ButtonGroup, IconFlip, Responsive } from "@clevertrack/shared"
import imageCompression from 'browser-image-compression'
import styled from "styled-components"
import Icon, { IconSizeEnum } from "lib/Icon"
import { uniqueId } from "lodash-es"
import cogoToast from "@clevertrackdk/cogo-toast"

type FileUploadProps = {
  templateFilename?: string // Place in static folder on the /import-templates-path
  onAccept?: <T>(data: T) => void
  onCancel?: () => void
  onImport?: () => void
  uploadOnDrop?: boolean
  acceptResult: React.ReactElement
  cancelResult: React.ReactElement
  allowedMimeTypes: MimeType[]
  uploadCompleted: boolean
  uploadLabel?: string
  uploadDescription?: string
  hideAllowedMimeTypes?: boolean
}

type MimeType = {
  mime: string
  ext: string
}

export const MimeTypes: { [key: string]: MimeType } = {
  PDF: { mime: "application/pdf", ext: ".pdf" },
  JPEG: { mime: "image/jpeg", ext: ".jpeg" },
  PNG: { mime: "image/png", ext: ".png" },
  XLS: { mime: "application/vnd.ms-excel", ext: ".xls" },
  XLSX: {
    mime: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ext: ".xlsx",
  },
  DOC: { mime: "application/msword", ext: ".doc" },
  DOCX: {
    mime:
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ext: ".docx",
  },
  CSV: { mime: "text/csv", ext: ".csv" },
}

const StyledDropZone = styled.div<{ dragActive: boolean }>`
  ${tw`relative p-4 mt-8 flex flex-col items-center justify-center border border-dashed border-brand-gray-base transition`}
  ${(props) => (props.dragActive ? tw`border-solid` : ``)}
  .inner {
  }

  svg {
    max-height: none;
    height: 4rem;
    width: 4rem;
    margin-right: 0;
  }
`

const StyledResults = styled.section``

type UploadResultMeta = {
  aborted: boolean
  cursor: number
  delimiter: string
  fields: string[]
  linebreak: string
  truncated: boolean
}

type UploadResult = {
  data: any[]
  errors: any[]
  meta: UploadResultMeta
}

export type FileUploadResponseType = {
  data: UploadResult
}

export const FileUpload: React.FC<FileUploadProps> = ({
  onImport,
  onAccept,
  onCancel,
  uploadOnDrop,
  acceptResult,
  cancelResult,
  allowedMimeTypes,
  uploadCompleted,
  hideAllowedMimeTypes = false,
  uploadLabel = "Upload fil",
  uploadDescription = "eller tag et billede",
  ...props
}) => {
  const [uploadData, setUploadData] = useState<any[]>([])
  const [fileTypeError, setFileTypeError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [uploadError, setUploadError] = useState<boolean>(false)

  const onDrop = async (acceptedFiles) => {
    setLoading(true)
    const filetypes = acceptedFiles.map((x) => x.type)

    if (
      filetypes.some(
        (type) => !allowedMimeTypes.map((x) => x.mime).includes(type)
      )
    ) {
      setLoading(false)
      setFileTypeError(true)
      return
    }

    if (uploadOnDrop && onAccept) {
      // Compress files before uploading, but only for images (mime types JPG or PNG)
      const compressedFiles = await Promise.all(
        acceptedFiles.map(async (file) => {
          if (!file.type.startsWith("image/")) return file
          const compressedFile = await imageCompression(file, {
            maxWidthOrHeight: 1200,
            useWebWorker: true,
          })
          return compressedFile
        })
      )
      onAccept(
        compressedFiles.map((x) => {
          return {
            id: uniqueId(),
            path: x.path,
            size: (x.size / (1024 * 1024)).toFixed(2),
            file: x,
          }
        })
      )
    } else {
      setUploadData(
        acceptedFiles.map((x) => {
          return {
            id: uniqueId(),
            path: x.path,
            size: (x.size / (1024 * 1024)).toFixed(2),
            file: x,
          }
        })
      )
    }

    setLoading(false)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  })

  useEffect(() => {
    if (uploadCompleted) {
      cogoToast.success(
        `${uploadData.length} fil${uploadData.length > 1 ? `er` : ``
        } blev uploadet korrekt`
      )
      setUploadData([])
    }
  }, [uploadCompleted])

  return (
    <StyledDropZone
      {...getRootProps()}
      multiple={false}
      dragActive={isDragActive}
    >
      {loading ? (
        <Loading includeWrapper loadingText="Uploader fil" tw="relative" />
      ) : (
        <>
          <input {...getInputProps()} />
          <IconFlip
            tw="h-24 w-24"
            size={IconSizeEnum.XL}
            iconOff="camera"
            iconOn="upload"
            toggled={isDragActive}
          />
          <p tw="my-0 text-center leading-snug">
            {!isDragActive ? uploadLabel : "Slip"}
            <Responsive
              phone={<small tw="block opacity-60">{uploadDescription}</small>}
              desktop={<></>}
            />
          </p>
          {!hideAllowedMimeTypes && (
            <p tw="mt-4 mb-0 text-xl">
              Tilladte filtyper: {allowedMimeTypes.map((x) => x.ext).join(", ")}
            </p>
          )}
        </>
      )}

      {fileTypeError ? (
        <span tw="text-xl text-brand-red-500 block my-8">
          Ugyldig filtype identificeret.
        </span>
      ) : null}
      {uploadError ? (
        <span tw="text-xl text-brand-red-500 block mb-8">
          Der skete en fejl under behandling af filen. Prøv igen.
        </span>
      ) : null}
    </StyledDropZone>
  )
}
