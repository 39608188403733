import React from "react"
import "twin.macro"
import { useTranslation } from "react-i18next"
import { FormField } from "@clevertrack/shared"
import { ValidationInput } from "@clevertrack/shared"
import Accordion from "lib/Accordion"
import { RadioButtonGroup } from "./RadioButtonGroup"
import { FormFieldToggle } from "components/FormFieldToggle"
import { PhotoUpload } from "../../components/PhotoUpload"

interface CANDataReaderSectionProps {
  currentModel: any
  updateModel: (key: string, value: any) => void
  setCurrentModel: (model: any) => void
  onAcceptFilesHandler: (files: File[], filename: string, key: string) => Promise<void>
  removeItemFromUploadMapByKey: (key: string) => void
}

export const CANDataReaderSection: React.FC<CANDataReaderSectionProps> = ({
  currentModel,
  updateModel,
  setCurrentModel,
  onAcceptFilesHandler,
  removeItemFromUploadMapByKey,
}) => {
  const { t } = useTranslation()

  const handleCANDataReaderCountChange = (count: number) => {
    if (count === 1) {
      const {
        deviceCAN2DataReaderPhoto,
        deviceCAN2DataReaderPhotoPath,
        deviceCAN2DataReaderNote,
        deviceCAN2HighColor,
        deviceCAN2LowColor,
        ...newModel
      } = currentModel
      setCurrentModel({
        ...newModel,
        deviceCANDataReaderCount: 1,
      })
    } else if (count === 2) {
      updateModel("deviceCANDataReaderCount", 2)
      updateModel("deviceCAN2DataReaderPhoto", null)
      updateModel("deviceCAN2DataReaderPhotoPath", null)
      updateModel("deviceCAN2HighColor", null)
      updateModel("deviceCAN2LowColor", null)
    }
  }

  return (
    <>
      <h4 tw="mb-4">{t("installation_can_data_readers")}</h4>
      <span tw="block">{t("installation_can_data_readers_description")}</span>
      <RadioButtonGroup
        options={[
          {
            value: 1,
            label: t("installation_can_data_reader_one"),
            onChange: () => handleCANDataReaderCountChange(1),
          },
          {
            value: 2,
            label: t("installation_can_data_reader_two"),
            onChange: () => handleCANDataReaderCountChange(2),
          },
        ]}
        selectedValue={currentModel?.deviceCANDataReaderCount}
      />

      <Accordion toggled={currentModel?.deviceCANDataReaderCount !== null}>
        {currentModel?.deviceCANDataReaderCount !== null && (
          <>
            <h4>{t("installation_can_data_reader_one_photo")}</h4>
            <PhotoUpload
              photoPropertyKey="deviceCANDataReaderPhoto"
              photoPropertyValue={currentModel?.deviceCANDataReaderPhoto}
              photoFilename="device-can-data-reader-mounting.jpeg"
              photoPath={currentModel?.deviceCANDataReaderPhotoPath}
              photoPathPropertyKey="deviceCANDataReaderPhotoPath"
              uploadLabel=""
              uploadDescription={t("installation_can_data_reader_one_photo_description")}
              onUpdateModel={updateModel}
              onDeletePhoto={() => removeItemFromUploadMapByKey("deviceCANDataReaderPhoto")}
              onAcceptFilesHandler={onAcceptFilesHandler}
            />
            <FormFieldToggle
              untoggleText={t("installation_remove_field")}
              toggleText={t("installation_add_note_to_photo")}
              tw="mt-8"
            >
              <FormField label={t("installation_note_optional")}>
                <ValidationInput
                  defaultValue={currentModel?.deviceCANDataReaderNote}
                  placeholder={t("installation_note_optional_placeholder")}
                  onChange={(e) => updateModel("deviceCANDataReaderNote", e.target.value)}
                />
              </FormField>
            </FormFieldToggle>
            <FormField label={t("installation_can_data_reader_one_color_high")}>
              <ValidationInput
                defaultValue={currentModel?.deviceCANHighColor}
                placeholder={t("installation_can_data_reader_one_color_high_placeholder")}
                onChange={(e) => updateModel("deviceCANHighColor", e.target.value)}
              />
            </FormField>
            <FormField label={t("installation_can_data_reader_one_color_low")}>
              <ValidationInput
                defaultValue={currentModel?.deviceCANLowColor}
                placeholder={t("installation_can_data_reader_one_color_low_placeholder")}
                onChange={(e) => updateModel("deviceCANLowColor", e.target.value)}
              />
            </FormField>
          </>
        )}

        {currentModel?.deviceCANDataReaderCount === 2 && (
          <>
            <h4>{t("installation_can_data_reader_two_photo")}</h4>
            <PhotoUpload
              photoPropertyKey="deviceCAN2DataReaderPhoto"
              photoPropertyValue={currentModel?.deviceCAN2DataReaderPhoto}
              photoFilename="device-can-2-data-reader-mounting.jpeg"
              photoPath={currentModel?.deviceCAN2DataReaderPhotoPath}
              photoPathPropertyKey="deviceCAN2DataReaderPhotoPath"
              uploadLabel=""
              uploadDescription={t("installation_can_data_reader_two_photo_description")}
              onUpdateModel={updateModel}
              onDeletePhoto={() => removeItemFromUploadMapByKey("deviceCAN2DataReaderPhoto")}
              onAcceptFilesHandler={onAcceptFilesHandler}
            />
            <FormFieldToggle
              untoggleText={t("installation_remove_field")}
              toggleText={t("installation_add_note_to_photo")}
              tw="mt-8"
            >
              <FormField label={t("installation_note_optional")}>
                <ValidationInput
                  defaultValue={currentModel?.deviceCAN2DataReaderNote}
                  placeholder={t("installation_note_optional_placeholder")}
                  onChange={(e) => updateModel("deviceCAN2DataReaderNote", e.target.value)}
                />
              </FormField>
            </FormFieldToggle>
            <FormField label={t("installation_can_data_reader_two_color_high")}>
              <ValidationInput
                defaultValue={currentModel?.deviceCAN2HighColor}
                placeholder={t("installation_can_data_reader_two_color_high_placeholder")}
                onChange={(e) => updateModel("deviceCAN2HighColor", e.target.value)}
              />
            </FormField>
            <FormField label={t("installation_can_data_reader_two_color_low")}>
              <ValidationInput
                defaultValue={currentModel?.deviceCAN2LowColor}
                placeholder={t("installation_can_data_reader_two_color_low_placeholder")}
                onChange={(e) => updateModel("deviceCAN2LowColor", e.target.value)}
              />
            </FormField>
          </>
        )}
      </Accordion>
    </>
  )
}
