import React, { useContext, useEffect, useMemo } from "react"
import { UserContext } from "app/User/context"
import "twin.macro"
import { useAccounts } from "app/Account/hooks"
import { DataList } from "app/DataList"
import { SearchProvider } from "app/Search/context"
import { InstallationContext } from "app/Installation/context"
import {
  InstallationActions,
  InstallationTypes,
} from "app/Installation/actions"
import { Account } from "app/Account/types"
import { Installation } from "app/Installation"
import { AccountsContext } from "app/Account/context"
import { useUser } from "app/User/hooks"
import { DevicesContext } from "app/Devices/context"
import { InstallationFlow } from "app/Installation/Flow"
import { Helmet } from "react-helmet"
import orderBy from "lodash-es/orderBy"
import { useTranslation } from "react-i18next"
import { queryResolver } from "utils/queryResolver"

export const InstallerHome: React.FC = ({ ...props }) => {
  const {
    state: { user },
  } = useContext(UserContext)
  const { dispatch } = useContext(InstallationContext)
  const {
    state: { account, accounts },
  } = useContext(AccountsContext)
  const {
    state: { devices },
  } = useContext(DevicesContext)
  const { assignedAccounts } = useAccounts()
  const { currentUserIsExternalFitter } = useUser()
  const { t } = useTranslation()

  const urlParams: { [key: string]: string } | undefined = useMemo(() => {
    if (location.search) {
      return queryResolver(location.search)
    }
  }, [location])

  const onSelectAccountHandler = (item: Account) => {
    dispatch(
      InstallationActions(InstallationTypes.SetAccount, { account: item })
    )
  }

  useEffect(() => {
    if (currentUserIsExternalFitter && account && devices.length > 0) {
      onSelectAccountHandler(account)
    }
  }, [currentUserIsExternalFitter, account, devices.length])

  useEffect(() => {
    if (urlParams?.companyID) {
      const account = accounts.find((acc) => acc.id === urlParams.companyID)
      if (account) onSelectAccountHandler(account)
    }
  }, [urlParams, accounts])

  return (
    <>
      <Helmet title={`${t("installation_title")} | Clevertrack`} />
      <div tw="pb-28">
        <div tw="p-8">
          <h2 tw="m-0">
            {t("installation_hello")} {user?.firstName}
          </h2>
          <p tw="m-0 mt-2 text-2xl">{t("installation_which_account")}</p>
        </div>
        <SearchProvider>
          <DataList
            items={orderBy(assignedAccounts, "name")}
            titleKey="name"
            includeSearch
            searchKeys={["name"]}
            onItemSelect={onSelectAccountHandler}
            searchPlaceHolder={t("installation_search_account_placeholder")}
          />
        </SearchProvider>
        <SearchProvider>
          <Installation />
        </SearchProvider>
        <InstallationFlow />
      </div>
    </>
  )
}
