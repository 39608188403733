import { Button, Input, Icon, Responsive } from "@clevertrack/shared"
import React from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { IconSizeEnum } from "lib/Icon"

const StyledSearchInputWrapper = styled.div`
  ${tw`flex items-center h-full relative`}
`

const StyledSearchIcon = styled(Icon)`
  position: absolute;
  left: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  height: 1.6rem;
  width: 1.6rem;
  margin: 0;
  opacity: 0.8;

  ${(props) => props.theme.media.tablet_landscape_up`
    left: auto;
    right: 1.5rem;
  `}
`

const StyledResetButton = styled(Button)`
  position: absolute;
  right: 1rem;
  top: 48%;
  transform: translateY(-50%);
  height: 2.2rem;
  width: 2.2rem;
  margin: 0;
  border-radius: 50%;
  ${tw`bg-brand-gray-base`}
  opacity: 1;

  &:hover {
    background: ${(props) => props.theme.colors.red};
  }

  svg {
    fill: ${(props) => props.theme.colors.white};
  }
`

const StyledSearchInput = styled(Input)<{
  withPhoneBorder: boolean
  noPad: boolean
}>`
  font-size: initial;
  ${tw`bg-brand-gray-brand border-0 m-0 pl-16 lg:pl-0`}

  &:focus {
    outline: 0;
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    padding-right: 4rem;
  `}
`

export type SearchInputProps = {
  onFocus?: (...args) => void
  onReset?: (...args) => void
  onBlur?: (...args) => void
  onKeyUp?: (...args) => void
  onKeyDown?: (...args) => void
  onSubscribe?: (...args) => void
  onChange?: (...args) => void
  query: string
  withPhoneBorder?: boolean
  placeholder?: string
  noPad?: boolean
  hideSearchIcon?: boolean
}

export const SearchInput: React.FC<SearchInputProps> = ({
  onChange,
  onBlur,
  onFocus,
  onKeyUp,
  onKeyDown,
  onReset,
  placeholder,
  query,
  noPad = false,
  hideSearchIcon,
  withPhoneBorder = false,
  ...props
}) => {
  const onChangeHandler = (e) => {
    if (onChange) onChange(e.target.value)
  }

  return (
    <StyledSearchInputWrapper>
      {!hideSearchIcon && (
        <StyledSearchIcon size={IconSizeEnum.SM} icon="search" />
      )}
      <StyledSearchInput
        value={query}
        onChange={onChangeHandler}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        withPhoneBorder={withPhoneBorder}
        noPad={noPad}
        {...props}
      />
      {query.length > 0 && (
        <StyledResetButton
          type="button"
          variant="danger"
          icon
          size="sm"
          onClick={onReset}
        >
          <Icon size={IconSizeEnum.SM} icon="close" />
        </StyledResetButton>
      )}
    </StyledSearchInputWrapper>
  )
}
