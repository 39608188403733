import React, { useContext, useState, useEffect, useRef } from "react"
import { Button, ButtonGroup, Icon, Loading, FormField, Input } from "@clevertrack/shared"
import styled from "styled-components"
import "twin.macro"
import { InstallationScreenEnum, InstallationStepStateEnum } from "../types"
import { InstallationContext } from "../context"
import {
  IconIllustration,
  SecondaryIconPositionEnum,
} from "app/Visuals/IconIllustration"
import { StyledPopoverContentContainer } from "./components"
import PopOver from "app/PopOver"
import tw from "twin.macro"
import { Device, DeviceStockStatusEnum } from "app/Devices/types"
import { useInstallation } from "../hooks"
import format from "date-fns/format"
import { DataList } from "app/DataList"
import { useDeviceSearch } from "../DeviceSearch/hooks"
import { DeviceSearchHeader } from "../DeviceSearch/Header"
import { InstallationActions, InstallationTypes } from "../actions"
import { hintTheme } from "app/Hint"
import Checkbox from "lib/Checkbox"
import { useFirestoreDevices } from "services/firestore/devices"
import { DeviceAuditLogEvent } from "app/AuditLog/audit.types"
import { AccountsContext } from "app/Account/context"
import { UserContext } from "app/User/context"
import cogoToast from "@clevertrackdk/cogo-toast"
import { replaceDevice } from "services/devices"
import { generateInstallationVerificationStep } from "./helper"
import { useTranslation } from "react-i18next"

const StyledReplaceDevicePopoverContentContainer = styled(
  StyledPopoverContentContainer
)`
  grid-template-rows: 1fr auto;
`

type ReplaceDeviceType = {
  onSelectScreen: (screen: InstallationScreenEnum | null) => void
}

export const ReplaceDevice: React.FC<ReplaceDeviceType> = ({
  onSelectScreen,
}) => {
  const {
    state: { device, account, currentScreen, installationSteps },
    dispatch,
  } = useContext(InstallationContext)
  const { state: { user } } = useContext(UserContext)
  const { state: { account: installerAccount } } = useContext(AccountsContext)
  const [inProgress, setInProgress] = useState(false)
  const [isDeviceDefective, setIsDeviceDefective] = useState<boolean | undefined>(undefined)
  const [defectiveReason, setDefectiveReason] = useState<string>("")
  const { installerStockDevices } = useDeviceSearch()
  const [selectedDevice, setSelectedDevice] = useState<Device | null>(null)
  const {
    saveFirebaseDevice,
    deleteInstallationStepsByImei,
    saveInstallationStepByImei,
    newDeviceAuditLogEntry,
  } = useFirestoreDevices()
  const { resolveInstallationSteps, mapVehicles } = useInstallation()
  const { t } = useTranslation()

  const onReplaceDeviceHandler = async () => {
    try {

      if (selectedDevice && device) {
        setInProgress(true)
        // Implementation for device replacement logic
        const replaceDevicePromise = replaceDevice({
          sourceIMEI: device?.imei?.toString() ?? '',
          destinationIMEI: selectedDevice?.imei?.toString() ?? '',
        })

        // Map the old installation steps for the new device
        const newInstallationSteps = installationSteps?.map(
          (step) => {
            if (step.stepID === InstallationScreenEnum.DeviceHealthValidation) {
              return {
                ...step,
                stepState: InstallationStepStateEnum.NotStarted,
                data: {
                  devicePower: {
                    valid: false,
                    value: "",
                  },
                  deviceIgnition: {
                    valid: false,
                    value: "",
                  },
                  deviceSatcountOK: {
                    valid: false,
                    value: "",
                  },
                  deviceInternalBatteryOK: {
                    valid: false,
                    value: "",
                  },
                },
                optionalDataProps: [],
              }
            }


            if (step.stepID === InstallationScreenEnum.InstallationVerification) {
              return generateInstallationVerificationStep(selectedDevice, installerAccount)
            }

            // If the step is DeviceCalibration, copy it to the selected device, but set its stepState to Incomplete
            if (step.stepID === InstallationScreenEnum.DeviceCalibration) {
              return {
                ...step,
                stepState: InstallationStepStateEnum.Incomplete,
              }
            }

            return step
          }
        )

        // Save installation steps to the new device, but reset steps DeviceHealth and DeviceInstallation
        const firebaseInstallationStepsSavePromise: Promise<{
          result: string;
        }>[] = newInstallationSteps?.map((step) => saveInstallationStepByImei(
          selectedDevice?.imei?.toString(),
          step.stepID,
          step
        ))?.filter(Boolean) ?? []

        // Remove installation steps from the old device
        const firebaseInstallationStepsDeletionPromise = deleteInstallationStepsByImei(
          device?.imei?.toString()
        )

        // Add audit log entry for the old device
        const unmountDate = new Date()
        const firebaseAuditLogEntryPromise = newDeviceAuditLogEntry(
          device?.imei?.toString(),
          {
            event: DeviceAuditLogEvent.Replaced,
            timestamp: +unmountDate,
            user: `${user?.firstName} ${user?.lastName}`,
            userID: user?.id ?? 0,
            userAccount: installerAccount?.name.toString() ?? "",
            userAccountID: +installerAccount?.id || 0,
          }
        )
        // Add audit log entry for the new device
        const firebaseAuditLogEntryPromiseNewDevice = newDeviceAuditLogEntry(
          device?.imei?.toString(),
          {
            event: DeviceAuditLogEvent.ReplacementDevice,
            timestamp: +unmountDate,
            user: `${user?.firstName} ${user?.lastName}`,
            userID: user?.id ?? 0,
            userAccount: installerAccount?.name.toString() ?? "",
            userAccountID: +installerAccount?.id || 0,
          }
        )
        // Update the new device with the required properties from the old device
        const firebaseDeviceUpdatePromise = saveFirebaseDevice(
          {
            account: device?.account,
            accountID: device?.accountID,
            deviceCalibrationPayload: device?.deviceCalibrationPayload || null,
            deviceFeaturesPayload: device?.deviceFeaturesPayload || null,
            vehicleToGroupPayload: device?.vehicleToGroupPayload || null,
            patchVehiclePayload: device?.patchVehiclePayload || null,
            installationStartedOnAccountID: device?.accountID || account?.id || "",
            note: device?.note || null,
            vehicleID: device?.vehicleID,
            vehicle_id: device?.vehicleID,
            name: device?.name,
            id: device?.vehicleID,
            availableUnit: false,
            stockStatus: DeviceStockStatusEnum.Awaiting,
            statusUpdated: +new Date(),
          },
          selectedDevice?.imei?.toString(),
        )

        // Update the old device with 'factory settings', and put it in stock of the installer replacing the device
        const firebaseDeviceUpdatePromiseOldDevice = saveFirebaseDevice(
          {
            account: installerAccount?.name,
            accountID: installerAccount?.id,
            deviceCalibrationPayload: null,
            deviceFeaturesPayload: null,
            vehicleToGroupPayload: null,
            patchVehiclePayload: null,
            vehicleID: null,
            vehicle_id: null,
            name: `${device?.imei}${isDeviceDefective ? ` (${t("installation_device_defective")})` : ""}`,
            note: "",
            defectiveUnit: isDeviceDefective,
            defectiveReason: isDeviceDefective ? defectiveReason : null,
            id: null,
            availableUnit: true,
            stockStatus: DeviceStockStatusEnum.Stock,
            statusUpdated: +new Date(),
          },
          device?.imei?.toString(),
        )

        // Before doing any firebase stuff, let's see if we can successfully replace the device in the backend.

        const replaceDeviceResponse = await replaceDevicePromise

        if (replaceDeviceResponse.result !== "OK") {
          cogoToast.error(t("installation_replace_device_error"))
          return
        }

        const results = await Promise.all([
          ...firebaseInstallationStepsSavePromise,
          firebaseInstallationStepsDeletionPromise,
          firebaseAuditLogEntryPromise,
          firebaseAuditLogEntryPromiseNewDevice,
          firebaseDeviceUpdatePromise,
          firebaseDeviceUpdatePromiseOldDevice,
        ])

        if (results.some((result) => result?.result !== "OK")) {
          cogoToast.error(t("installation_replace_device_error"))
          return
        }

        // Finally, archive the old device
        /* const deleteVehiclePromise = await deleteVehicle(device?.id, 1)

        if (deleteVehiclePromise.result !== "OK") {
          cogoToast.error("Kunne ikke arkivere enheden. Noget gik galt.")
          return
        } */

        const replacedDevice = {
          ...selectedDevice,
          account: device?.account,
          accountID: device?.accountID,
          deviceCalibrationPayload: device?.deviceCalibrationPayload,
          deviceFeaturesPayload: device?.deviceFeaturesPayload,
          vehicleToGroupPayload: device?.vehicleToGroupPayload,
          patchVehiclePayload: device?.patchVehiclePayload,
          installationStartedOnAccountID: account?.id,
          vehicleID: device?.vehicleID,
          name: device?.name,
          id: device?.id,
          availableUnit: false,
          stockStatus: DeviceStockStatusEnum.Awaiting,
        }

        dispatch(
          InstallationActions(InstallationTypes.SetDevice, {
            device: replacedDevice,
          })
        )
        await mapVehicles()
        await resolveInstallationSteps(replacedDevice)
        onSelectScreen(InstallationScreenEnum.Tasks)
        cogoToast.success(t("installation_replace_device_success"))
      }

    } catch (error) {
      cogoToast.error(t("installation_replace_device_error"))
    } finally {
      setInProgress(false)
    }
  }

  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isDeviceDefective && contentRef.current) {
      contentRef.current.scrollTo({
        top: contentRef.current.scrollHeight,
        behavior: 'smooth'
      })
    }
  }, [isDeviceDefective])

  const onCancelHandler = () => {
    setSelectedDevice(null)
    setIsDeviceDefective(false)
    onSelectScreen(InstallationScreenEnum.Tasks)
  }

  const renderConfirmationScreen = () => {
    return (
      <PopOver
        fromBottom
        show={
          selectedDevice !== null &&
          currentScreen === InstallationScreenEnum.ReplaceDevice
        }
        zindex={2300}
        selector="#___dashboard-inner"
        tw="absolute shadow-none overflow-hidden"
      >
        <div id="replace_device_content" ref={contentRef} tw="text-center self-center h-full overflow-y-scroll">
          <div tw="text-center self-center p-16">
            <IconIllustration
              tw="my-8"
              primaryIcon="sync"
              secondaryIcon="tools"
              secondaryIconPosition={SecondaryIconPositionEnum.BottomRight}
            />
            {!inProgress && (
              <>
                <h2 tw="mb-0">{t("installation_replace_device")}</h2>
                <p>{t("installation_replace_device_on_account_note")} <strong tw="text-2xl">{account?.name}</strong></p>
                <div tw="flex items-center justify-center">
                  <span tw="text-2xl">{device?.imei}</span>
                  <Icon tw="mx-6" icon="long-arrow-right" />
                  <strong tw="text-2xl">{selectedDevice?.imei}</strong>
                </div>
                <Button
                  type="button"
                  variant="cancel"
                  disabled={inProgress}
                  onClick={() => setSelectedDevice(null)}
                >
                  <span tw="flex items-center">
                    <span tw="text-xl font-normal">{t("installation_replace_device_select_another_device")}</span>
                  </span>
                </Button>
              </>
            )}

            {inProgress ? (
              <>
                <h2 tw="mb-0">{t("installation_replacing_device")}</h2>
                <Loading includeWrapper={false} loadingText={t("common_please_wait")} />
              </>
            ) : (
              <>
                <div style={hintTheme.default} tw="text-left p-8 mt-8">
                  <h3 tw="mb-4 text-2xl">{t("installation_replace_device_hint_title")}</h3>
                  <ol tw="text-2xl text-left list-inside leading-snug m-0 p-0">
                    <li>{t("installation_replace_device_hint_step_1")}</li>
                    <li>{t("installation_replace_device_hint_step_2")}</li>
                    <li>{t("installation_replace_device_hint_step_3")}</li>
                  </ol>
                </div>
                <div tw="mt-8 mx-auto">
                  <p>{t("installation_replace_device_is_the_device_defective")}</p>
                  <div tw="mx-auto flex items-center justify-center space-x-16 space-y-0">
                    <Checkbox
                      name="isDeviceDefective"
                      checkboxAppearance="radio"
                      checked={isDeviceDefective === true}
                      onChange={() => setIsDeviceDefective(true)}
                    >
                      <span tw="text-2xl">{t("installation_yes")}</span>
                    </Checkbox>
                    <Checkbox
                      name="isDeviceDefective"
                      checkboxAppearance="radio"
                      checked={isDeviceDefective === false}
                      onChange={() => setIsDeviceDefective(false)}
                    >
                      <span tw="text-2xl">{t("installation_no")}</span>
                    </Checkbox>
                  </div>
                  {isDeviceDefective && (
                    <div tw="mt-4 w-full">
                      <FormField label={t("installation_replace_device_is_defective_reason_label")}>
                        <Input
                          type="text"
                          value={defectiveReason}
                          onChange={(e) => setDefectiveReason(e.target.value)}
                          placeholder={t("installation_replace_device_is_defective_reason_placeholder")}
                        />
                      </FormField>
                    </div>
                  )}
                </div>
              </>
            )}

          </div>

          <ButtonGroup sticky="bottom" tw="bg-white px-4">
            <Button
              type="button"
              variant="cancel"
              disabled={inProgress}
              onClick={onCancelHandler}
            >
              <span tw="flex items-center">
                <span tw="text-xl font-normal">{t("installation_cancel")}</span>
              </span>
            </Button>
            <Button
              type="button"
              variant="primary"
              disabled={inProgress || isDeviceDefective === undefined || (isDeviceDefective && !defectiveReason)}
              onClick={onReplaceDeviceHandler}
            >
              <span tw="flex items-center">
                <span tw="text-xl font-bold">{t("installation_confirm")}</span>
              </span>
            </Button>
          </ButtonGroup>
        </div>
      </PopOver>
    )
  }

  const renderDeviceSelectionScreen = () => {
    return (
      <PopOver
        fromBottom
        show={
          selectedDevice === null &&
          currentScreen === InstallationScreenEnum.ReplaceDevice
        }
        zindex={2400}
        selector="#___dashboard-inner"
        tw="absolute shadow-none overflow-hidden bg-white"
      >
        <header tw="p-8 pb-4 sticky top-0 bg-white z-50">
          <span tw="flex items-baseline justify-between">
            <span tw="block mb-2">{t("installation_replace_device_select_device_replacement")}</span>
            <span
              tw="text-brand-500 flex items-center text-right whitespace-nowrap"
              onClick={() =>
                dispatch(
                  InstallationActions(
                    InstallationTypes.SetScreen,
                    InstallationScreenEnum.Tasks
                  )
                )
              }
            >
              <Icon icon="chevron-left" tw="w-4 h-4 mr-2" />
              <span tw="text-lg font-normal">{t("installation_back")}</span>
            </span>
          </span>
          <span tw="text-2xl font-bold">
            <span tw="w-2/3">{device?.imei}</span>
          </span>
          <DeviceSearchHeader />
        </header>
        <h4 tw="p-8 m-0 sticky top-0 bg-white z-50">
          <span>{t("installation_stock")}</span>
        </h4>
        <div tw="overflow-y-scroll h-full pb-48">
          <DataList
            items={installerStockDevices.filter(
              (dev) => dev.stockStatus === DeviceStockStatusEnum.Stock
            )}
            sort={["updated", "desc"]}
            titleKey="name"
            titleIcon="warehouse-alt"
            titleIconStyling={tw`w-6 h-6 mr-4`}
            titleStyling={tw`flex items-center text-brand-blue-50`}
            descriptionKey={["note"]}
            secondaryDescriptionKey={["imei", "deviceTypeName"]}
            secondaryDescriptionStyling={tw`text-lg`}
            tertiaryDescriptionKey={"statusUpdated"}
            tertiaryDescriptionKeyFormatFnc={(descriptionKey) => {
              if (!descriptionKey) return null
              return `${t("installation_stock_updated")} ${format(
                new Date(descriptionKey),
                `dd.MM.yyyy '${t("at")}' HH:mm`
              )}`
            }}
            tertiaryDescriptionStyling={tw`text-lg`}
            tertiaryDescriptionKeySeparator={","}
            onItemSelect={(item) => setSelectedDevice(item)}
          />
        </div>
      </PopOver>
    )
  }

  return (
    <StyledReplaceDevicePopoverContentContainer>
      {renderDeviceSelectionScreen()}
      {renderConfirmationScreen()}
    </StyledReplaceDevicePopoverContentContainer>
  )
}
