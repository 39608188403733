import React, { useCallback, useContext, useMemo, useState } from "react"
import { Button } from "@clevertrack/shared/src/components/Button"
import { Icon } from "@clevertrack/shared/src/components/Icon"
import QrReader from "react-qr-scanner"
import { useLocation } from "@reach/router"
import { isIMEI } from "validator"
import styled from "styled-components"
import tw from "twin.macro"
import { IconSizeEnum } from "lib/Icon"
import { Search } from "app/Search"
import { SearchActions, SearchTypes } from "app/Search/actions"
import { queryResolver } from "utils/queryResolver"
import { SearchContext } from "app/Search/context"
import { InstallationContext } from "../context"
import { useDeviceSearch } from "./hooks"
import PopOver from "app/PopOver"
import { useTranslation } from "react-i18next"

const StyledQrPopover = styled.div`
  ${tw`bg-white w-full h-full flex flex-col items-center justify-center`}

  .overlay {
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 100;
    ${tw`flex flex-col items-center justify-center`}

    svg {
      width: 80vw;
      height: 80vw;
      margin-top: 4.5rem;
      margin-bottom: 4rem;
      fill: white;
      opacity: 0.6;
    }
  }

  .qr-wrapper {
    width: 90vw;
    height: 90vw;
    position: absolute;
    z-index: 50;

    video {
      margin-top: -3rem;
      width: 90vw;
      height: 90vw;
      object-fit: cover;
    }
  }
`

export const DeviceSearchHeader: React.FC = () => {
  const location = useLocation()
  const {
    state: { query },
    dispatch: searchDispatch,
  } = useContext(SearchContext)
  const {
    state: { accountDevices },
  } = useContext(InstallationContext)
  const [qrScanToggled, setQrScanToggled] = useState(false)
  const { datasetSearch } = useDeviceSearch()

  const { t } = useTranslation()

  const urlParams = useMemo(() => {
    if (location.search) {
      return queryResolver(location.search)
    }
  }, [location])

  const onScanQRHandler = useCallback((result) => {
    if (result && isIMEI(result.text)) {
      setQrScanToggled(false)
      searchDispatch(
        SearchActions(SearchTypes.SetQuery, { query: result.text })
      )
    }
  }, [])

  return (
    datasetSearch && (
      <div tw="bg-white mt-8 flex items-center">
        <div tw="flex-1">
          <Search
            dataset={datasetSearch}
            placeholder={t("installation_search_placeholder")}
            query={query}
          />
        </div>
        {urlParams?.disableQR !== "1" && (
          <Button
            variant="icon"
            invert
            tw="p-0 w-12 h-12 m-0 ml-4 -mt-4"
            onClick={() => setQrScanToggled(true)}
          >
            <span tw="flex flex-col items-center">
              <Icon
                icon="qrcode"
                size={IconSizeEnum.AUTO}
                tw="text-brand-black-base w-12 h-12"
              />
              <span tw="text-sm block text-nowrap -mt-2">{t("installation_scan_qr")}</span>
            </span>
          </Button>
        )}

        <PopOver
          fromBottom
          show={qrScanToggled}
          zindex={3000}
          tw="overflow-y-hidden"
        >
          <StyledQrPopover>
            <div className="overlay">
              <h3 tw="m-0">{t("installation_qr_title")}</h3>
              <Icon icon="expand" />

              <Button variant="cancel" onClick={() => setQrScanToggled(false)}>
                {t("installation_qr_cancel")}
              </Button>
              <span tw="block">
                <strong>{t("installation_qr_tips")}: </strong>{t("installation_qr_tips_text")}
              </span>
              <span tw="block">{t("installation_qr_best_on_iphone")}</span>
            </div>
            <div className="qr-wrapper">
              {qrScanToggled && (
                <QrReader
                  key="environment"
                  onScan={onScanQRHandler}
                  constraints={{
                    facingMode: "environment",
                    video: {
                      facingMode: "environment",
                    },
                  }}
                  legacyMode={true}
                />
              )}
            </div>
          </StyledQrPopover>
        </PopOver>
      </div>
    )
  )
}
