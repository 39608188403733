// Devices in stock are maintained on firestore,
// where they are related/moved/assigned to a company (retailer, fitter or admin)

import { DeviceConfiguration } from "app/Configuration/types"
import {
  ApiVehiclePatch,
  ApiVehicleCalibrationPatch,
  ApiVehicleFeaturesPatch,
  ApiVehicleToGroupPatch,
} from "app/Installation/types"

// Once they're being mounted, they are finally assigned to an account through a POST (see https://staging.webapi.clevertrack.dk/Help/Api/POST-api-devices)
export enum DeviceCategoryEnum {
  Tracker = "Tracker",
  Beacon = "Beacon",
}

export enum DeviceTypeCategoryEnum {
  GPSTracker = "GPS Tracker",
  GPSTrackerWithCAN = "GPS Tracker With CAN",
  GPSTrackerWithBattery = "GPS Tracker With Battery",
  Beacon = "Beacon",
}

export enum DeviceActivityStatusEnum {
  Online = "Online",
  Offline = "Offline",
  Inactive = "Inactive",
}

export enum DeviceStockStatusEnum {
  Stock = "På lager",
  Awaiting = "Igangværende montering",
  Installed = "Monteret",
  Uninstalled = "Afmonteret",
  Legacy = "Ikke verificerede i Hub",
}

export enum DeviceCommandsEnum {
  DataAcquisition10Sec = 5,
  DataAcquisition60Sec = 6,
  SetCANAutoScan = 9,
}

type BasicDevice = {
  id: number
  deviceType: DeviceTypeEnum // Must match a number from this list: https://staging.webapi.clevertrack.dk/Help/Api/GET-api-deviceType-cms - VERY important
  deviceTypeName: string
  imei: string
  comment: string
  account: string
  accountID: string
  unit_id: number | undefined
  idleReadInput?: number
}

// This is the basic data model of DeviceItems that comes from the API through GET devices https://staging.webapi.clevertrack.dk/Help/Api/GET-api-devices
// We'll transform this response into a more conventional structure for the frontend,
// by discarding unnecessary props, and adding a category to easily group devices
export type ApiDevice = {
  id: number
  deviceType: DeviceTypeEnum
  deviceTypeName: string
  comment: string
  uuid: string // beacons have this property
  imei: string
  simicc: string // trackers have this property
  phone: string // currently not used
  account: string
  account_id: string
  accountID: string
  disableLogging: number
  created: number // timestamp of creation
  unit_id: number | undefined
  config: DeviceConfiguration
}

export type ApiVehicle = {
  id?: number
  name?: string
  description?: string
  note?: string
  note2?: string
  unit_id?: number
  AEMPID?: string
  deviceType?: DeviceTypeEnum
  imei: string
  simicc?: string
  account_id?: number
  CAN_RPM_low?: number
  CAN_RPM_high?: number
  AIN2_PRES_low?: number
  AIN2_PRES_high?: number
  AIN2_PRES_zero?: number
  idleReadInput?: number
  engineHourInput?: number
  fuelLitreInput?: number
  mileageInput?: number
  totalEngineHour_offset?: number
  ShowAutoTurnOff?: number
  AutoTurnOffGen2?: number
  AutoTurnOff?: number
  ShowImmobilizer?: number
  Immobilizer?: number
  AutoTurnOffTime?: number
  EngineLowTemperatur?: number
  Engine_load_low?: number
  Engine_load_high?: number
  DOutOnIgnition?: number
  DisclaimerRead?: number
  DisclaimerReadTimestamp?: number
  FirstActivation?: number
  FirstActivationTime?: number
  Door_Enable?: number
  Door_Status_Ena?: number
  Door_openFrom?: number
  Door_openTo?: number
  Door_email?: string
  disableLogging?: number
  created?: number
  customer_id?: number
  subscription_type?: number
  subscription_addon?: number
  deleted?: boolean
}

export enum DeviceFeatureEnum {
  AutoTurnOff = "autoTurnOff",
  AutoTurnOffGen2 = "autoTurnOffGen2",
  AutoTurnOffTime = "autoTurnOffTime",
  Immobilizer = "immobilizer",
  LowerIdleRPM = "lowerIdleRPM",
  ShowAutoTurnOff = "showAutoTurnOff",
  ShowIdleLow = "showIdleLow",
  ShowImmobilizer = "showImmobilizer",
  LowerIdleAIN1 = "lowerIdleAIN1", // float
  HigherIdleAIN1 = "higherIdleAIN1", // float
  ZeroIdleAIN1 = "zeroIdleAIN1", // float
  engineLowTemperatur = "engineLowTemperatur", // int
  EngineLoadLow = "engineLoadLow", // int
  EngineLoadHigh = "engineLoadHigh", // int
  idleReadInput = "idleReadInput", // int, 1-4
  DOutOnIgnition = "dOutOnIgnition", // int
  FirstActivation = "firstActivation", // int
  FirstActivationTime = "firstActivationTime", // float, 0-120
  DisclaimerRead = "disclaimerRead", // int, 0-1
  DisclaimerReadTimestamp = "disclaimerReadTimestamp", // timestamp
}

export enum DeviceCANFeatureEnum {
  IdleReadInput = "idleReadInput",
  RPMLow = "RPM_low",
  RPMHigh = "RPM_high",
  AIN2Low = "AIN2_low",
  AIN2High = "AIN2_high",
  AIN2Zero = "AIN2_zero",
  EngineLoadLow = "EngineLoadLow",
  EngineLoadHigh = "EngineLoadHigh",
  EngineHourInput = "engineHourInput",
  FuelLitreInput = "fuelLitreInput",
  MileageInput = "mileageInput",
  ShowImmobilizer = "ShowImmobilizer",
  ShowAutoTurnOff = "ShowAutoTurnOff",
  AutoTurnOffGen2 = "AutoTurnOffGen2",
}

export type Beacon = BasicDevice & {
  uuid: string
  deviceCategory: DeviceCategoryEnum
}

export type Tracker = BasicDevice & {
  simicc: string
  deviceCategory: DeviceCategoryEnum
}

export type StockDevice = {
  imei: string
  model: string
  simicc?: string
  uuid?: string
  createdAt: string
  account?: string
  activityStatus: DeviceActivityStatusEnum
  stockStatus: DeviceStockStatusEnum
  statusUpdated?: number
  installationStartedOnAccountID?: number
  productCode?: string
  features: { [key in DeviceFeatureEnum]: any }
}

export type Device = StockDevice &
  ApiDevice &
  ApiVehicle & {
    vehicleID?: number
    deviceCalibrationPayload: ApiVehicleCalibrationPatch
    deviceFeaturesPayload: ApiVehicleFeaturesPatch
    patchVehiclePayload: ApiVehiclePatch
    vehicleToGroupPayload: ApiVehicleToGroupPatch
  }

export enum DeviceTypeEnum {
  "AEMP1 (maskinintegration)" = 12,
  "AEMP2 (maskinintegration)" = 16,
  "Blue PUCK RHT" = 5,
  "Blue PUCK T" = 4,
  "FMB001 (biler)" = 15,
  "FMB002 (biler)" = 6,
  "FMB003 (biler)" = 7,
  "FMB010 (biler)" = 3,
  "FMB125" = 19,
  "FMB125 (maskiner m. fuel flow måler)" = 44,
  "FMB130 (biler)" = 11,
  "FMB140 ALL-CAN (biler u. brændstofdata)" = 36,
  "FMB140 ALL-CAN (biler)" = 21,
  "FMB140 ALL-CAN (el-biler)" = 20,
  "FMB140 ALL-CAN (maskiner m. AEMP)" = 34,
  "FMB140 ALL-CAN (maskiner m. trykføler + AEMP)" = 39,
  "FMB140 ALL-CAN (maskiner m. trykføler)" = 28,
  "FMB140 ALL-CAN (maskiner u. brændstofdata)" = 38,
  "FMB140 ALL-CAN (maskiner u. CAN)" = 31,
  "FMB140 ALL-CAN (maskiner)" = 8,
  "FMB140 LV-CAN (biler u. brændstofdata)" = 35,
  "FMB140 LV-CAN (biler)" = 18,
  "FMB140 LV-CAN (maskiner m. AEMP)" = 33,
  "FMB140 LV-CAN (maskiner m. trykføler + AEMP)" = 41,
  "FMB140 LV-CAN (maskiner m. trykføler)" = 27,
  "FMB140 LV-CAN (maskiner)" = 24,
  "FMB204 (biler)" = 37,
  "FMB204 (maskiner m. trykføler)" = 29,
  "FMB204 (maskiner/materiel)" = 1,
  "FMB204 (materielscanner)" = 25,
  "FMB204 (trailere)" = 30,
  "FMB204 m. AEMP (maskiner)" = 32,
  "FMB240 ALL-CAN (maskiner)" = 46,
  "FMB240 LV-CAN (biler)" = 45,
  "FMB640 (biler)" = 23,
  "FMC150 (maskiner)" = 48,
  "FMC234 (maskiner m. trykføler)" = 49,
  "FMC234 (maskiner)" = 47,
  "Probe" = 10,
  "Probe Mini" = 14,
  "Probe Plus" = 43,
  "Probe Ultra" = 13,
  "TAT100" = 17,
  "FMC003" = 51,
  "FMC150 ext" = 52, // To be deciced
  "FMC130" = 53,
  "FMC234" = 54,
  "FMC250 ext" = 55, // To be decided
  "FMC150" = 56,
  "FMC250" = 57,
  "TAT140" = 58,
  "FMC125" = 59,
  "FMB001" = 60,
  "FMB003" = 61,
  "FMB130" = 62,
  "FMB140 LV-CAN" = 63,
  "FMB140 ALL-CAN" = 64,
  "FMB204" = 65,
  "FMB240 LV-CAN" = 66,
  "FMB240 ALL-CAN" = 67,
  "FMB640" = 68,
}

export enum DisplayKeyEnum {
  Address = "address",
  LastConfirmationTime = "lastConfirmationTime",
  LastSeenBy = "lastSeenBy",
  Speed = "speed",
  FirstStartToday = "firstStartToday",
  LatestStopToday = "latestStopToday",
  DrivingHoursToday = "drivingHoursToday",
  IdleHoursToday = "idleHoursToday",
  StopHoursToday = "stopHoursToday",
  DailyDistanceToday = "dailyDistanceToday",
  EngineHoursToday = "engineHoursToday",
  FuelConsumptionToday = "fuelConsumptionToday",
  TotalDistance = "totalDistance",
  TotalEngineHours = "totalEngineHours",
  TotalDrivingHours = "totalDrivingHours",
  TotalMovingHours = "totalMovingHours",
  LastService = "lastService",
  NextService = "nextService",
  Temperature = "temperature",
  Humidity = "humidity",
  TotalIdleHours = "totalIdleHours",
  TotalFuel = "totalFuel",
  MachineWithCAN = "machineWithCAN",
  LastIgnitionStop = "lastIgnitionStop",
  BatteryLongevity = "batteryLongevity",
  BatteryLongevityVolt72 = "batteryLongevityVolt72",
  FuelLevel = "fuelLevel",
}

export type ApiDisplayKey = {
  id: number
  keyName: DisplayKeyEnum
  description: string
}

export type DisplayKeyValueType =
  | "TimeSpan"
  | "int"
  | "float"
  | "DateTime"
  | "string"

export type DisplayKey = {
  name: DisplayKeyEnum
  value: string | number
  type: DisplayKeyValueType
}

export type KpiDataType = "TimeSpan" | "float" | "DateTime" | "string" | "int"

export type Kpi = {
  key: string
  icon: string
  kpiTitle?: string
  unsetValue: null | string
  translationKey?: string
  unit?: string
  type?: KpiDataType
  formatDataFnc?: (...args) => any
  data?: any
  value?: any
  sortOrder?: number
}

export enum BatteryChargingStatusEnum {
  CHARGING = "battery_charging",
  NOT_CHARGING = "battery_not_charging",
  CONNECTED = "battery_charger_connected",
  DISCONNECTED = "battery_charger_disconnected",
}

export type Category = Kpi[]

export type ApiVehicleGroup = {
  id: number
  name: string
  description: string
}
