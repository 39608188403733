import { ApiVehicle } from "app/Devices/types"

// Installation Flow Screens
export enum InstallationScreenEnum {
  Tasks = "Tasks",
  DeviceInformation = "DeviceInformation",
  DataValidation = "DataValidation",
  DeviceHealthValidation = "DeviceHealthValidation",
  DeviceCalibration = "DeviceCalibration",
  InstallationVerification = "InstallationVerification",
  Uninstall = "Uninstall",
  ReplaceDevice = "ReplaceDevice",
}

export enum APIVehicleKindEnum {
  Van = "Varebil",
  Tractor = "Traktor",
  Trailer = "Påhængsvogn",
  Machine = "Motorredskab",
  Truck = "Lastbil",
  SemiTrailer = "Sættevogn",
  Car = "Personbil",
}

export enum InstallationStepStateEnum {
  Completed = "Completed",
  Incomplete = "Incomplete",
  NotStarted = "Not started",
}

export const NoneMachineVehicleTypes = [
  APIVehicleKindEnum.Car,
  APIVehicleKindEnum.Truck,
  APIVehicleKindEnum.Van,
  APIVehicleKindEnum.SemiTrailer,
  APIVehicleKindEnum.Trailer,
]

export type DeviceInformationModel = {
  legacyVehicleName?: string
  vehicleName: string
  vehicleDescription: string
  vehicleNumber?: string | null
  vehicleRegistrationNumber?: string | null
  vehicleLicensePlatePhoto?: string | null
  vehicleLicensePlatePhotoPath?: string | null
  vehicleFrameNumber: string | null
  vehicleTypePlatePhoto?: string | null
  vehicleTypePlatePhotoPath?: string | null
  vehicleBrand: string | null
  vehicleModel: string | null
  vehicleYear: string | null
  vehicleUniqueness: string | null
  vehicleGroup: { label: string; value: any }[] | null
}

export type DataValidationModel = {
  programNumber: number
  totalEngineHoursAvailable: boolean
  totalDistanceAvailable: boolean
  totalFuelAvailable: boolean
}

type DeviceHealthValidationMap = {
  valid: boolean
  value: string
}

export type DeviceHealthValidationModel = {
  devicePower: DeviceHealthValidationMap
  deviceIgnition: DeviceHealthValidationMap
  deviceSatcountOK: DeviceHealthValidationMap
  deviceInternalBatteryOK: DeviceHealthValidationMap
}

export type DeviceCalibrationModel = {
  deviceConfiguration?: number | null
  deviceIdleConfiguration?: number | null
  totalEngineHours: number
  totalDistance: number
  noCalibrationDataNote?: string | null
  lowerIdleRPM?: number
  zeroIdleAIN1?: number
  lowerIdleAIN1?: number
  engineLoadLow?: number
}

export enum ExtendedBoolean {
  YES = "Yes",
  NO = "No",
  UNKNOWN = "Unknown",
}

export enum MainSwitchBypassBoolean {
  YES = "Yes",
  NO = "No",
  POWERBYPASSED = "Power Bypassed",
}

export type InstallationVerificationModel = {
  deviceCANDataReaderCount?: number | null
  deviceCANDataReaderPhoto?: string
  deviceCANDataReaderPhotoPath?: string
  deviceCANDataReaderNote?: string
  deviceCANHighColor?: string
  deviceCANLowColor?: string
  deviceCAN2DataReaderPhoto?: string
  deviceCAN2DataReaderPhotoPath?: string
  deviceCAN2DataReaderNote?: string
  deviceCAN2HighColor?: string
  deviceCAN2LowColor?: string
  deviceMountingPhoto: string
  deviceMountingPhotoPath: string
  deviceMountingNote: string
  devicePlacementPhoto: string
  devicePlacementPhotoPath: string
  devicePlacementNote: string
  vehicleFrontPhoto: string
  vehicleFrontPhotoPath: string
  vehicleFrontNote: string
  isMainSwitchPresent: ExtendedBoolean | null
  isMainSwitchControlledByGround?: boolean | null // If true, there's a risk the tracker will register false ignition
  isDeviceMountedWithIgnitionWireRelay?: boolean | null // A relay should be mounted, to prevent false ignition, if the installer finds it necessary
  isDeviceBypassingMainSwitch?: MainSwitchBypassBoolean | null // If true, a reason should be provided for bypassing the main switch
  autoOffMounted?: boolean | null
  autoOffRelayMountPhoto?: string
  autoOffRelayMountPhotoPath?: string
  autoOffRelayMountNote?: string
  autoOffTurnsOffDisplay?: boolean | null
  autoOffTurnsOffLight?: boolean | null
  autoOffRequiresTurnKey?: boolean | null
}

export type InstallationStepDataProps =
  | DeviceInformationModel
  | DataValidationModel
  | DeviceHealthValidationModel
  | DeviceCalibrationModel
  | InstallationVerificationModel

export type InstallationStepOptionalDataProps =
  | keyof DeviceInformationModel
  | keyof DataValidationModel
  | keyof DeviceHealthValidationModel
  | keyof DeviceCalibrationModel
  | keyof InstallationVerificationModel

export type InstallationStep = {
  stepID: InstallationScreenEnum
  stepState: InstallationStepStateEnum
  data: InstallationStepDataProps
  optionalDataProps: InstallationStepOptionalDataProps[]
}

export type InstallationStepType = {
  onSave: <T>(
    screen: InstallationScreenEnum,
    data: InstallationStep,
    apiSave: Promise<T> | null,
    options?: {
      autoSave: boolean
    }
  ) => Promise<void>
}

// Typings for required API Payloads, when the installation is completed.
export type ApiVehiclePatch = {
  name: string
  note: string
  account_id: string
}

export type ApiVehicleCalibrationPatch = {
  note: string
  note2: string
  name: string
  description: string
  mileage: number
  totalEngineHours: number
}

export type ApiVehicleFeaturesPatch = {
  note: string // Unchanged from ApiVehiclePatch, but required in the payload
  note2: string
  name: string
  description: string
  mileage: number
  totalEngineHours: number
  // Auto off features
  lowerIdleRPM: number
  zeroIdleAIN1: number
  lowerIdleAIN1: number
  engineLoadLow: number
}

export type ApiVehicleToGroupPatch = {
  vehicle_id: number
  groups: number[]
}
