import React, { useReducer, createContext } from "react"
import { AccountsActionMap, AccountTypes } from "./actions"
import { Account } from "./types"
import { ApiVehicle } from "app/Devices/types"

/**
 * Set initial state and create the context. The Provider can be wrapped around any component up the tree
 * This approach enables us to keep things really modular
 */

type InitialAccountStateType = {
  accounts: Account[] | []
  account: Account | null
  viewAccount: Account | null
  accountVehicles: ApiVehicle[] | []
}

const initialState: InitialAccountStateType = {
  accounts: [],
  account: null,
  viewAccount: null,
  accountVehicles: [],
}

const AccountsContext = createContext<{
  state: InitialAccountStateType
  dispatch: React.Dispatch<any>
}>({ state: initialState, dispatch: () => null })

/**
 * Tracker reducer
 * Keeps track of selected trackers and the trackers loaded in from the backend
 */

const accountsReducer = (
  state: InitialAccountStateType,
  action: AccountsActionMap
) => {
  switch (action.type) {
    case AccountTypes.SetAccounts:
      return {
        ...state,
        accounts: action.payload.accounts,
      }
    case AccountTypes.SetViewAccount:
      return {
        ...state,
        viewAccount: action.payload.viewAccount,
      }
    case AccountTypes.SetAccount:
      return {
        ...state,
        account: action.payload.account,
      }
    case AccountTypes.SetAccountVehicles:
      return {
        ...state,
        accountVehicles: action.payload.accountVehicles,
      }
    case AccountTypes.ClearAccount:
      return {
        ...state,
        account: null,
      }
    default:
      return state
  }
}

const AccountsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountsReducer, initialState)

  return (
    <AccountsContext.Provider value={{ state, dispatch }}>
      {children}
    </AccountsContext.Provider>
  )
}

export { AccountsContext, AccountsProvider }
