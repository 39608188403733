import React from "react"
import "twin.macro"
import { useTranslation } from "react-i18next"
import { PhotoUploadWithNote } from "./PhotoUploadWithNote"

interface VehiclePhotoSectionProps {
  currentModel: any
  updateModel: (key: string, value: any) => void
  onAcceptFilesHandler: (files: File[], filename: string, key: string) => Promise<void>
  removeItemFromUploadMapByKey: (key: string) => void
}

export const VehiclePhotoSection: React.FC<VehiclePhotoSectionProps> = ({
  currentModel,
  updateModel,
  onAcceptFilesHandler,
  removeItemFromUploadMapByKey,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <h4>{t("installation_vehicle_photo")}</h4>
      <PhotoUploadWithNote
        photoPropertyKey="vehicleFrontPhoto"
        photoPropertyValue={currentModel?.vehicleFrontPhoto}
        photoFilename="vehicle-front-photo.jpeg"
        photoPath={currentModel?.vehicleFrontPhotoPath}
        photoPathPropertyKey="vehicleFrontPhotoPath"
        uploadLabel=""
        uploadDescription={t("installation_vehicle_photo_description")}
        notePropertyKey="vehicleFrontNote"
        noteValue={currentModel.vehicleFrontNote}
        onUpdateModel={updateModel}
        onDeletePhoto={() => removeItemFromUploadMapByKey("vehicleFrontPhoto")}
        onAcceptFilesHandler={onAcceptFilesHandler}
      />
    </>
  )
}
