import React from "react"
import "twin.macro"
import { useTranslation } from "react-i18next"
import { PhotoUploadWithNote } from "./PhotoUploadWithNote"

interface PlacementPhotoSectionProps {
  currentModel: any
  updateModel: (key: string, value: any) => void
  onAcceptFilesHandler: (files: File[], filename: string, key: string) => Promise<void>
  removeItemFromUploadMapByKey: (key: string) => void
}

export const PlacementPhotoSection: React.FC<PlacementPhotoSectionProps> = ({
  currentModel,
  updateModel,
  onAcceptFilesHandler,
  removeItemFromUploadMapByKey,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <h4>{t("installation_placement_photo")}</h4>
      <PhotoUploadWithNote
        photoPropertyKey="devicePlacementPhoto"
        photoPropertyValue={currentModel?.devicePlacementPhoto}
        photoFilename="device-placement.jpeg"
        photoPath={currentModel?.devicePlacementPhotoPath}
        photoPathPropertyKey="devicePlacementPhotoPath"
        uploadLabel=""
        uploadDescription={t("installation_placement_photo_description")}
        notePropertyKey="devicePlacementNote"
        noteValue={currentModel.devicePlacementNote}
        onUpdateModel={updateModel}
        onDeletePhoto={() => removeItemFromUploadMapByKey("devicePlacementPhoto")}
        onAcceptFilesHandler={onAcceptFilesHandler}
      />
    </>
  )
}
