import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import "twin.macro"
import { isIMEI } from "validator"
import { MainContainer } from "app/MainContainer"
import { useFirebaseFunctions } from "services/firebase-functions/functions"
import {
  Button,
  ButtonGroup,
  Form,
  FormField,
  Input,
  Loading,
  Textarea,
} from "@clevertrack/shared"
import { DataTable } from "app/DataTable"
import format from "date-fns/format"
import { DevicesContext } from "app/Devices/context"
import { CollectionFilter } from "utils/collection/filter"
import { useFirestoreDevices } from "services/firestore/devices"
import cogoToast from "@clevertrackdk/cogo-toast"
import { DeviceStockStatusEnum } from "app/Devices/types"
import { Select } from "lib/Select"
import { deviceTypeObject } from "app/Devices/helper"
import { createDevice } from "services/devices"
import uniq from "lodash-es/uniq"
import styled from "styled-components"
import { useDeviceConfiguration } from "app/Configuration/hooks"
import { ConfigurationContext } from "app/Configuration/context"

const StyledStockContainer = styled.div`
  display: grid;
  grid-template-columns: 25% auto;
`

export const CreateDevice: React.FC = ({ ...props }) => {
  const {
    state: { devices },
  } = useContext(DevicesContext)
  const {
    state: { configurationList },
  } = useContext(ConfigurationContext)
  const { getFotaDevice, getInactiveFotaDevices } = useFirebaseFunctions()
  const {
    configurationListOptions,
    updateDeviceConfiguration,
  } = useDeviceConfiguration()
  const [imeiNumbers, setImeiNumbers] = useState<string>("")
  const [dataset, setDataset] = useState<any[] | null>(null)
  const [filteredDataset, setFilteredDataset] = useState<any[] | null>(null)
  const [selectedFilters, setSelectedFilters] = useState<CollectionFilter[]>([])
  const [boxNo, setBoxNo] = useState<string>("")
  const [boxID, setBoxID] = useState<string>("")
  const [newDevices, setNewDevices] = useState<any[]>([])
  const [selectedConfig, setSelectedConfig] = useState<{
    label: string
    value: any
  } | null>(null)
  const [selectedShipment, setSelectedShipment] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  const { createFirebaseDevices } = useFirestoreDevices()

  const onAddImeiNumbers = (e) => {
    setImeiNumbers(e.target.value)
  }

  const onLoadInactiveDevices = async () => {
    setLoading(true)
    const res = await getInactiveFotaDevices({ shipmentID: null })
    if (res.data.status === "OK") {
      resolveDataset(res.data.devices)
    }
    setLoading(false)
  }

  /* const deviceFilterGroups = useMemo(() => {
    const shipments = uniqBy(dataset, "shipment")
      .filter((x) => x.shipment !== "")
      .map((x) => x.shipment)
      .filter(Boolean)
    const models = uniqBy(dataset, "model")
      .filter((x) => x.model !== "")
      .map((x) => x.model)
      .filter(Boolean)
    return [
      {
        dataKey: "shipment",
        label: "Forsendelse",
        options: shipments.map((x) => ({ label: x, value: x })),
      },
      {
        dataKey: "model",
        label: "Model",
        options: models.map((x) => ({ label: x, value: x })),
      },
    ]
  }, [dataset]) */

  useMemo(async () => {
    const validImeiNumbers = imeiNumbers
      .split("\n")
      .filter((x) => x !== "")
      .filter((x) => isIMEI(x))
    if (validImeiNumbers.length > 0) {
      const filteredDataset = dataset?.filter(
        (x) =>
          x.shipment === selectedShipment.value &&
          validImeiNumbers.includes(x.imei.toString())
      )
      if (filteredDataset) setFilteredDataset(filteredDataset)
    }
  }, [imeiNumbers, selectedShipment])

  const resolveDataset = (rawDevices) => {
    const newDevices = rawDevices.map((device) => {
      const foundDevice = devices.find((x) => +x.imei === device.imei)
      return {
        imei: device.imei,
        model: device.model ?? device.description,
        productCode: device.product_code,
        account: foundDevice?.account,
        accountID: foundDevice?.accountID,
        shipment: device.shipment,
        simicc:
          device.iccid && device.iccid.length === 20
            ? device.iccid.substr(10, 9)
            : device.iccid,
        activityStatus: device.activity_status,
        createdAt: device.created_at,
        deviceType: +foundDevice?.deviceType,
        deviceTypeName: foundDevice?.deviceTypeName,
      }
    })
    setDataset(newDevices)
  }

  const [shipmentIDs] = useMemo(() => {
    const uniqueShipmentIDs = uniq(dataset?.map((x) => x.shipment))
      .filter(Boolean)
      .filter((x) => x !== "")
    return [uniqueShipmentIDs]
  }, [dataset])

  /*   const onScanQRHandler = useCallback(
    (result) => {
      if (result && !imeiNumbers.includes(result.text)) {
        setImeiNumbers((prev) =>
          prev.length > 0 ? prev.concat(`\n${result.text}`) : result.text
        )
      }
    },
    [imeiNumbers]
  ) */

  const onChangeModuleTypeHandler = (opt) => {
    if (filteredDataset) {
      const mappedDevices = filteredDataset.map((dev) => ({
        ...dev,
        deviceType: opt.value,
        deviceTypeName: opt.label,
      }))

      setFilteredDataset(mappedDevices)
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    try {
      let result
      const configurableDevices = []
      if (filteredDataset) {
        const cfg = selectedConfig
          ? configurationList.find(
            (config) => config.id === selectedConfig.value
          )
          : null
        const existingDevices = devices
          .filter(
            (x) =>
              imeiNumbers.includes(x.imei?.toString()) && x.accountID === "0"
          )
          .map(({ id, createdAt, ...device }) => {
            return {
              ...device,
              created: createdAt,
              unit_id: id,
            }
          })

        if (!existingDevices || existingDevices.length === 0) {
          const createUnitsPromises = filteredDataset.map((item) => {
            return createDevice({
              imei: item.imei.toString(),
              uuid: item.uuid.toString(),
              simicc: item.simicc,
              comment: "",
              deviceType: +item.deviceType,
            })
          })

          const apiCreateResults = await Promise.all(createUnitsPromises)

          const payload = filteredDataset.map((item) => {
            const apiResult = apiCreateResults.find(
              (result) => result.data?.imei === item.imei
            )
            configurableDevices.push({
              imei: item.imei.toString(),
              id: apiResult?.data?.unit_id,
            })
            return {
              ...item,
              shipment: selectedShipment.value,
              account: null,
              stockStatus: DeviceStockStatusEnum.Stock,
              stockCreatedDate: new Date(),
              unit_id: apiResult?.data?.unit_id,
              config: cfg,
              boxID,
              boxNo,
            }
          })

          result = await createFirebaseDevices(payload)
        } else {
          const payload = filteredDataset.map((item) => {
            const apiResult = existingDevices.find(
              (existingDevice) =>
                existingDevice?.imei.toString() === item.imei.toString()
            )
            configurableDevices.push({
              imei: item.imei.toString(),
              id: apiResult?.unit_id,
            })
            return {
              ...item,
              shipment: selectedShipment.value,
              account: null,
              stockStatus: DeviceStockStatusEnum.Stock,
              stockCreatedDate: new Date(),
              unit_id: apiResult?.unit_id,
              config: cfg,
              boxID,
              boxNo,
            }
          })
          result = await createFirebaseDevices(payload)
        }

        if (result.result === "OK") {
          if (configurableDevices.length > 0 && cfg) {
            const result = await updateDeviceConfiguration(
              configurableDevices,
              cfg
            )
          }
          cogoToast.success("Nye enheder er lagerført")
          setBoxNo("")
          setBoxID("")
          setImeiNumbers("")
          setSelectedShipment(null)
          setFilteredDataset(null)
        } else {
          cogoToast.error("Kunne ikke oprette batch")
        }
      } else {
        cogoToast.error("Ingen enheder valgt")
      }
    } catch (error) {
      cogoToast.error("Kunne ikke oprette batch")
    }
  }

  useEffect(() => {
    // First, fetch inactive devices
    onLoadInactiveDevices()
  }, [])

  return (
    <MainContainer
      header={
        <>
          <h2>Opret nye lagerenheder</h2>
        </>
      }
    >
      <StyledStockContainer>
        <div tw="p-8">
          <Form onSubmit={onSubmit} tw="h-full">
            <div tw="space-y-8 h-full">
              {shipmentIDs.length > 0 && (
                <FormField label="Forsendelse" css={{ zIndex: 500 }}>
                  <Select
                    tw="pt-6"
                    options={shipmentIDs.map((shipment) => ({
                      label: shipment,
                      value: shipment,
                    }))}
                    value={selectedShipment}
                    placeholder="Vælg forsendeles ID"
                    onChange={(val) => setSelectedShipment(val)}
                  />
                </FormField>
              )}

              {/* <FormField label="Angiv batchnavn" tw="w-1/4">
              <Input
                type="text"
                id="batchname"
                placeholder="Pakkelistens navn"
                value={batchname}
                onChange={(e) => setBatchname(e.target.value)}
              />
            </FormField> */}
              {/* <ButtonGroup tw="p-0 space-x-2 ml-8">
              {/* <Button
                type="button"
                variant="default"
                onClick={onLoadInactiveDevices}
              >
                Hent forsendelser
              </Button> */}
              {/* <Button type="submit" variant="primary">
                Gem batch
              </Button>}
            </ButtonGroup> */}
              <FormField label="IMEI-numre i kassen">
                <Textarea
                  placeholder="Indsæt IMEI-numre (adskil med ny linje)"
                  onBlur={onAddImeiNumbers}
                  onChange={onAddImeiNumbers}
                  value={imeiNumbers}
                ></Textarea>
              </FormField>
              <FormField label="Modultype" tw="z-200">
                <Select
                  tw="pt-6"
                  options={deviceTypeObject}
                  placeholder="Vælg modultype"
                  onChange={onChangeModuleTypeHandler}
                />
              </FormField>
              <FormField label="Konfiguration" tw="z-200">
                <Select
                  tw="pt-6"
                  options={configurationListOptions}
                  placeholder="Vælg konfiguration"
                  onChange={(opt) => setSelectedConfig(opt)}
                />
              </FormField>
              <FormField label="Box No.">
                <Input
                  type="text"
                  id="boxNo"
                  placeholder="Box nummer"
                  value={boxNo}
                  onChange={(e) => setBoxNo(e.target.value)}
                />
              </FormField>
              <FormField label="Scan kasse ID (Data Matrix label)">
                <Input
                  type="text"
                  id="boxID"
                  placeholder="Box ID"
                  value={boxID}
                  onChange={(e) => setBoxID(e.target.value)}
                />
              </FormField>
              <Button
                type="submit"
                variant="primary"
                disabled={
                  !boxID ||
                  !boxNo ||
                  !deviceTypeObject ||
                  filteredDataset.length === 0
                }
              >
                Opret lagerenheder
              </Button>
            </div>
          </Form>
        </div>
        {loading && (
          <Loading loadingText="Indlæser forsendelser" includeWrapper={false} />
        )}
        {filteredDataset && (
          <>
            {/* <Filter
            filterGroups={deviceFilterGroups}
            onFilterUpdated={(filter) => setSelectedFilters(filter)}
          /> */}
            <div tw="px-8 p-4 border-solid border-0 border-t border-brand-gray-brand">
              <DataTable
                dataset={filteredDataset}
                filters={selectedFilters}
                columnConfig="1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"
                columns={[
                  { key: "imei", title: "IMEI" },
                  { key: "account", title: "Konto" },
                  { key: "model", title: "Model" },
                  { key: "productCode", title: "Produktkode" },
                  { key: "deviceTypeName", title: "Modultype" },
                  { key: "shipment", title: "Forsendelse" },
                  { key: "simicc", title: "SIMICC" },
                  { key: "activityStatus", title: "Status" },
                  {
                    key: "createdAt",
                    title: "Batchdato",
                    formatData: (timestamp) =>
                      format(new Date(timestamp), "dd-MM-yyyy HH:mm"),
                  },
                ]}
              />
            </div>
          </>
        )}
      </StyledStockContainer>
    </MainContainer>
  )
}
