import React from "react"
import "twin.macro"
import { useTranslation } from "react-i18next"
import { FormField } from "@clevertrack/shared"
import { Textarea } from "@clevertrack/shared"
import { PhotoUpload } from "../../components/PhotoUpload"

interface MountingPhotoSectionProps {
  currentModel: any
  updateModel: (key: string, value: any) => void
  onAcceptFilesHandler: (files: File[], filename: string, key: string) => Promise<void>
  removeItemFromUploadMapByKey: (key: string) => void
  deviceTypeCategory: number
  mountingDescription: string
}

export const MountingPhotoSection: React.FC<MountingPhotoSectionProps> = ({
  currentModel,
  updateModel,
  onAcceptFilesHandler,
  removeItemFromUploadMapByKey,
  deviceTypeCategory,
  mountingDescription,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <h4>{t("installation_mounting_photo")}</h4>
      <PhotoUpload
        photoPropertyKey="deviceMountingPhoto"
        photoPropertyValue={currentModel?.deviceMountingPhoto}
        photoFilename="device-mounting.jpeg"
        photoPath={currentModel?.deviceMountingPhotoPath}
        photoPathPropertyKey="deviceMountingPhotoPath"
        uploadLabel=""
        uploadDescription={mountingDescription}
        onUpdateModel={updateModel}
        onDeletePhoto={() => removeItemFromUploadMapByKey("deviceMountingPhoto")}
        onAcceptFilesHandler={onAcceptFilesHandler}
      />
      <FormField label={t("installation_mounting_note")}>
        <Textarea
          defaultValue={currentModel.deviceMountingNote}
          placeholder={t("installation_mounting_note_placeholder")}
          onChange={(e) => updateModel("deviceMountingNote", e.target.value)}
        />
      </FormField>
    </>
  )
}
