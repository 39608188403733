import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import "twin.macro"
import {
  FlowHeader,
  ScreenHeader,
  StyledForm,
  StyledPopoverContentContainer,
} from "./components"
import {
  DeviceCalibrationModel,
  InstallationScreenEnum,
  InstallationStepOptionalDataProps,
  InstallationStepStateEnum,
  InstallationStepType,
} from "../types"
import { useFormData } from "hooks/useFormData"
import { InstallationContext } from "../context"
import { DevicesContext } from "app/Devices/context"
import uniqBy from "lodash-es/uniqBy"
import {
  Button,
  ButtonGroup,
  FormField,
  Icon,
  Input,
  Textarea,
  ValidationInput,
} from "@clevertrack/shared"
import { Select } from "lib/Select"
import { FormFieldToggle } from "components/FormFieldToggle"
import styled from "styled-components"
import tw from "twin.macro"
import Accordion from "lib/Accordion"
import { getDisplayKey } from "app/Devices/helper"
import { DisplayKeyEnum } from "app/Devices/types"
import format from "date-fns/format"
import { getInstallationStepState } from "./helper"
import debounce from "lodash-es/debounce"
import { useInstallation } from "../hooks"
import isEqual from "lodash-es/isEqual"
import { useRealtimeDeviceValues } from "services/realtime/vehicles"
import { InstallationActions, InstallationTypes } from "../actions"
import { useFirestoreDevices } from "services/firestore/devices"
import uniq from "lodash-es/uniq"
import { useDeviceConfiguration } from "app/Configuration/hooks"
import { useTranslation } from "react-i18next"

const LogIcon = styled.span<{ showAnalogVoltageLog: boolean }>`
  svg {
    ${tw`ml-2 transition-transform origin-center inline-block`}
    ${(props) =>
    props.showAnalogVoltageLog
      ? `transform: rotate(90deg);`
      : `transform: rotate(0deg);`}
  }
`

export const DeviceCalibration: React.FC<InstallationStepType> = ({
  onSave,
  ...props
}) => {
  const {
    state: {
      device,
      currentDeviceValues,
      installationSteps,
      fotaDevice,
      currentScreen,
    },
    dispatch,
  } = useContext(InstallationContext)
  const {
    state: { files },
  } = useContext(DevicesContext)
  const { getDeviceValuesByID } = useRealtimeDeviceValues()
  const { saveFirebaseDevice } = useFirestoreDevices()
  const { setCurrentScreen } = useInstallation()
  const { configurationListOptions } = useDeviceConfiguration()
  const { t } = useTranslation()

  const stepData = useMemo(() => {
    if (installationSteps) {
      const step = installationSteps.find(
        (step) => step.stepID === InstallationScreenEnum.DeviceCalibration
      )
      if (step) {
        return step
      }
      return null
    }
    return null
  }, [installationSteps])

  const [currentModel, updateModel, resetModel, setCurrentModel] = useFormData<
    DeviceCalibrationModel
  >(stepData?.data)
  const [optionalDataProps, setOptionalDataProps] = useState<
    InstallationStepOptionalDataProps[]
  >(stepData?.optionalDataProps ?? [])

  const [analogVoltageLog, setAnalogVoltageLog] = useState<
    { time: string; value: string }[]
  >([])
  const [showAnalogVoltageLog, setShowAnalogVoltageLog] = useState(false)

  const onSnapshotHandler = (snapshot) => {
    dispatch(
      InstallationActions(InstallationTypes.SetCurrentDeviceValues, {
        currentDeviceValues: snapshot.val(),
      })
    )
  }

  const unsubscribeToDevice = useMemo(() => {
    if (device && device.id) {
      return getDeviceValuesByID(device?.id.toString(), onSnapshotHandler)
    }
    return null
  }, [device])

  useEffect(() => {
    if (!device && unsubscribeToDevice) {
      // unsubscribe
      unsubscribeToDevice()
    }
  }, [device, unsubscribeToDevice])

  const autoSaveFunc = useRef(
    debounce((newData) => {
      onSave(InstallationScreenEnum.DeviceCalibration, newData, null, {
        autoSave: true,
      })
    }, 2000)
  )

  useMemo(() => {
    if (
      currentModel &&
      currentScreen === InstallationScreenEnum.DeviceCalibration &&
      !isEqual(stepData?.data, currentModel)
    ) {
      const newData = {
        ...stepData,
        data: currentModel,
        stepState: getInstallationStepState(currentModel, optionalDataProps),
        optionalDataProps,
      }
      autoSaveFunc.current.cancel()
      autoSaveFunc.current(newData)
    }
  }, [currentModel, autoSaveFunc, stepData, currentScreen])

  const [availableConfigs, selectedConfig] = useMemo(() => {
    if (configurationListOptions && device.config) {
      const selected = configurationListOptions.find(
        (x) => x.label === device?.config.name
      )
      return [configurationListOptions, selected]
    }
    return [null, null]
  }, [device, configurationListOptions])

  const onSubmitHandler = () => {
    if (autoSaveFunc.current) autoSaveFunc.current.cancel()
    const newData = {
      ...stepData,
      data: currentModel,
      stepState: getInstallationStepState(currentModel, optionalDataProps),
      optionalDataProps: optionalDataProps,
    }
    const apiSave: Promise<any>[] = []
    apiSave.push(
      saveFirebaseDevice(
        {
          deviceCalibrationPayload: {
            note: device.note ?? "",
            note2: device.note2 ?? "",
            name: device.name ?? "",
            mileage: currentModel.totalDistance ?? 0,
            totalEngineHours: currentModel.totalEngineHours ?? 0,
          },
        },
        device.imei
      )
    )

    if (
      device &&
      device.features &&
      device?.config?.features?.idleReadInput !== 0
    ) {
      apiSave.push(
        saveFirebaseDevice(
          {
            deviceFeaturesPayload: {
              ...device.features,
              lowerIdleRPM:
                currentModel.lowerIdleRPM ?? device.features.lowerIdleRPM,
              zeroIdleAIN1:
                currentModel.zeroIdleAIN1 ?? device.features.zeroIdleAIN1,
              lowerIdleAIN1:
                currentModel.lowerIdleAIN1 ?? device.features.lowerIdleAIN1,
              engineLoadLow:
                currentModel.engineLoadLow ?? device.features.engineLoadLow,
            },
          },
          device.imei
        )
      )
    }

    onSave(
      InstallationScreenEnum.DeviceCalibration,
      newData,
      Promise.all(apiSave.filter(Boolean))
    )
  }

  const [totalEngineHoursToggled, totalDistanceToggled] = useMemo(() => {
    const toggledFields = [true, true, true]
    if (optionalDataProps.includes("totalEngineHours")) {
      toggledFields[0] = false
    }

    if (optionalDataProps.includes("totalDistance")) {
      toggledFields[1] = false
    }

    return toggledFields
  }, [])

  const renderLowerIdleRPM = () => {
    return (
      <FormField label={t("installation_device_calibration_lower_idle_rpm")}>
        <Input
          type="number"
          min="0"
          placeholder={t(
            "installation_device_calibration_lower_idle_rpm_placeholder"
          )}
          required
          defaultValue={currentModel?.lowerIdleRPM || ``}
          onChange={(e) => updateModel("lowerIdleRPM", e.target.value)}
        />
      </FormField>
    )
  }

  const renderEngineLoadLow = () => {
    return (
      <>
        <FormField label={t("installation_device_calibration_engine_load_low")}>
          <Input
            type="number"
            min="0"
            max="100"
            step=""
            placeholder={t(
              "installation_device_calibration_engine_load_low_placeholder"
            )}
            defaultValue={currentModel?.engineLoadLow || ``}
            onChange={(e) => updateModel("engineLoadLow", e.target.value)}
          />
        </FormField>
      </>
    )
  }

  const renderPressureTransmitterSettings = () => {
    return (
      <>
        <div tw="flex justify-between text-lg mb-0 mt-4 pb-4 px-4">
          <span tw="block">
            <span>
              {t("installation_device_calibration_current_voltage")}:{" "}
            </span>
            <span tw="text-green-600">
              {device?.analog1Voltage > 0
                ? (device?.analog1Voltage / 1000).toFixed(3)
                : "0.000"}{" "}
              volt
            </span>
          </span>
          <span
            tw="block ml-auto cursor-pointer transition-colors hover:(text-brand-500)"
            onClick={() => setShowAnalogVoltageLog((prev) => !prev)}
          >
            {showAnalogVoltageLog
              ? t("installation_device_calibration_hide_measurements")
              : t("installation_device_calibration_show_measurements")}
            <LogIcon {...{ showAnalogVoltageLog }}>
              <Icon icon="chevron-right" size="xs" />
            </LogIcon>
          </span>
        </div>
        <Accordion toggled={showAnalogVoltageLog}>
          <div tw="pb-4 px-4">
            {analogVoltageLog
              .sort((a, b) => (a.time > b.time ? -1 : 1))
              .map(({ time, value }, index) => {
                const colorStr = tw`text-green-600`
                return (
                  <span key={`log_${time}_${index}`} tw="text-lg block">
                    <span tw="inline-block mr-24">{`Kl. ${time}:`}</span>
                    <span css={[colorStr]} tw="-ml-1">
                      {(+value / 1000).toFixed(3)} volt
                    </span>
                  </span>
                )
              })}
          </div>
        </Accordion>
        <FormField label={t("installation_device_calibration_lower_idle_ain1")}>
          <Input
            type="number"
            min="0"
            step=".01"
            placeholder={t(
              "installation_device_calibration_lower_idle_ain1_placeholder"
            )}
            defaultValue={currentModel?.lowerIdleAIN1 || ``}
            onChange={(e) => updateModel("lowerIdleAIN1", e.target.value)}
          />
        </FormField>
        <FormField label={t("installation_device_calibration_zero_idle_ain1")}>
          <Input
            type="number"
            min="0"
            step=".01"
            placeholder={t(
              "installation_device_calibration_zero_idle_ain1_placeholder"
            )}
            defaultValue={currentModel?.zeroIdleAIN1 || ``}
            onChange={(e) => updateModel("zeroIdleAIN1", e.target.value)}
          />
        </FormField>
      </>
    )
  }

  useEffect(() => {
    if (currentDeviceValues) {
      const timestamp = getDisplayKey(
        currentDeviceValues.values,
        DisplayKeyEnum.LastConfirmationTime
      )

      if (timestamp && timestamp.value) {
        const time = format(new Date(timestamp.value), "HH:mm:ss")
        const newAnalogVoltageLog = uniqBy(
          [
            {
              time,
              value: currentDeviceValues.analog1Voltage,
            },
            ...analogVoltageLog,
          ],
          "time"
        ).slice(0, 10)

        setAnalogVoltageLog(newAnalogVoltageLog)
      }
    }
  }, [currentDeviceValues])

  const renderIdleCalibration = () => {
    switch (device?.config?.features?.idleReadInput) {
      case 1:
      case 3:
        return renderLowerIdleRPM()
      case 2:
        return renderPressureTransmitterSettings()
      case 4:
        return renderEngineLoadLow()
    }
  }

  return (
    <StyledPopoverContentContainer>
      <FlowHeader />
      <ScreenHeader>{t("installation_device_calibration")}</ScreenHeader>
      <StyledForm onSubmit={onSubmitHandler} {...props}>
        <div tw="p-4 space-y-8 pb-16">
          <FormField label={t("installation_device_calibration_configuration")}>
            <Select
              isDisabled
              menuPlacement="auto"
              placeholder={t(
                "installation_device_calibration_select_configuration"
              )}
              options={availableConfigs}
              onChange={(opt) => updateModel("deviceConfiguration", opt.value)}
              value={selectedConfig}
              tw="pt-4"
            />
          </FormField>
          <FormFieldToggle
            toggleQuestion={t(
              "installation_device_calibration_no_engine_hours_toggle_question"
            )}
            untoggleText={t(
              "installation_device_calibration_no_engine_hours_toggle_untoggle_text"
            )}
            toggleText={t(
              "installation_device_calibration_no_engine_hours_toggle_text"
            )}
            onDisable={() => {
              const { totalEngineHours, ...newModel } = currentModel
              setCurrentModel(newModel)
              setOptionalDataProps((prev) => {
                return uniq([...prev, "totalEngineHours"])
              })
            }}
            onEnable={() => {
              updateModel(
                "totalEngineHours",
                stepData?.data?.totalEngineHours ?? null
              )
              setOptionalDataProps((prev) => {
                return prev.filter((x) => x !== "totalEngineHours")
              })
            }}
            defaultToggled={totalEngineHoursToggled}
          >
            <FormField
              label={t("installation_device_calibration_engine_hours")}
              validationKey="engineHours"
            >
              <ValidationInput
                id="engineHours"
                defaultValue={currentModel?.totalEngineHours}
                placeholder={t(
                  "installation_device_calibration_engine_hours_placeholder"
                )}
                step="0.1"
                type="number"
                onChange={(e) =>
                  updateModel("totalEngineHours", e.target.value)
                }
              />
            </FormField>
          </FormFieldToggle>
          <FormFieldToggle
            toggleQuestion={t(
              "installation_device_calibration_no_distance_toggle_question"
            )}
            untoggleText={t(
              "installation_device_calibration_no_distance_toggle_untoggle_text"
            )}
            toggleText={t(
              "installation_device_calibration_no_distance_toggle_text"
            )}
            onDisable={() => {
              const { totalDistance, ...newModel } = currentModel
              setCurrentModel(newModel)
              setOptionalDataProps((prev) => {
                return uniq([...prev, "totalDistance"])
              })
            }}
            onEnable={() => {
              updateModel(
                "totalDistance",
                stepData?.data?.totalDistance ?? null
              )
              setOptionalDataProps((prev) => {
                return prev.filter((x) => x !== "totalDistance")
              })
            }}
            defaultToggled={totalDistanceToggled}
          >
            <FormField
              label={t("installation_device_calibration_distance_counter")}
              validationKey="totalDistance"
            >
              <ValidationInput
                id="totalDistance"
                defaultValue={currentModel?.totalDistance}
                placeholder={t(
                  "installation_device_calibration_distance_counter_placeholder"
                )}
                type="number"
                onChange={(e) => updateModel("totalDistance", e.target.value)}
              />
            </FormField>
          </FormFieldToggle>
          <Accordion
            toggled={
              typeof currentModel?.totalDistance === `undefined` &&
              typeof currentModel?.totalEngineHours === `undefined`
            }
          >
            <FormField
              label={t(
                "installation_device_calibration_no_calibration_data_note"
              )}
            >
              <Textarea
                required={
                  typeof currentModel?.totalDistance === `undefined` &&
                  typeof currentModel?.totalEngineHours === `undefined`
                }
                defaultValue={currentModel?.noCalibrationDataNote}
                placeholder={t(
                  "installation_device_calibration_no_calibration_data_note_placeholder"
                )}
                onChange={(e) =>
                  updateModel("noCalibrationDataNote", e.target.value)
                }
              />
            </FormField>
          </Accordion>

          {[1, 2, 3, 4].includes(device?.config?.features?.idleReadInput) && (
            <div>
              <h4 tw="pt-4 px-4 mt-0 bg-white">
                {t("installation_device_calibration_idle_calibration")}
              </h4>
              {renderIdleCalibration()}
            </div>
          )}
        </div>
        <ButtonGroup sticky="bottom" tw="bg-white px-4">
          <Button
            type="button"
            variant="cancel"
            onClick={() => setCurrentScreen(InstallationScreenEnum.Tasks)}
          >
            <span tw="flex items-center">
              <Icon icon="chevron-left" tw="w-4 h-4 mr-2" />
              <span tw="text-xl font-normal">{t("installation_back")}</span>
            </span>
          </Button>
          <Button
            type="submit"
            variant="primary"
            disabled={
              stepData &&
              getInstallationStepState(currentModel, optionalDataProps) !==
              InstallationStepStateEnum.Completed
            }
          >
            {t("installation_save")}
          </Button>
        </ButtonGroup>
      </StyledForm>
    </StyledPopoverContentContainer>
  )
}
