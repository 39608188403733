import React, { useContext, useState } from "react"
import { Button, ButtonGroup } from "@clevertrack/shared"
import cogoToast from "@clevertrackdk/cogo-toast"
import "twin.macro"
import { AccountsContext } from "app/Account/context"
import { MainContainer } from "app/MainContainer"
import { deleteAccount, saveAccount } from "services/accounts"
import { AccountForm } from "app/Account/Form"
import { useAccount } from "app/Account/hooks"
import { useFirestoreCompany } from "services/firestore/company"
import { Account, ClientApiAccount } from "app/Account/types"

import { StyledFormGrid } from "app/Grid/FormGrid"
import { FeatureComponent } from "app/FeatureComponent"
import { UserTypeEnum } from "app/User/types"
import { ContextMenu } from "app/ContextMenu"
import Icon, { IconSizeEnum } from "lib/Icon"

export const AccountView: React.FC = ({ ...props }) => {
  const {
    state: { viewAccount },
  } = useContext(AccountsContext)
  const { saveFirebaseCompany } = useFirestoreCompany()
  const [confirmDeleteAccount, setConfirmDeleteAccount] = useState(false)

  useAccount()

  const onDeleteHandler = async () => {
    if (viewAccount) {
      try {
        const result = await deleteAccount(viewAccount.id)

        if (result.data.result === "OK") {
          cogoToast.success("Kontoen blev slettet")
        }
      } catch (error) {
        cogoToast.error("Kunne ikke slette kontoen")
      }
    }
  }

  const onSaveHandler = async (updatedCompany: Account) => {
    try {
      const [fbRes, apiRes] = await Promise.all([
        saveFirebaseCompany(
          {
            vatNumber:
              updatedCompany.vatNumber === undefined
                ? null
                : updatedCompany.vatNumber,
            name: updatedCompany.name,
            description: updatedCompany.description,
            email: updatedCompany.email ?? null,
            features: updatedCompany.features ?? {},
            functions: updatedCompany.functions ?? {},
            installerAccountFeatures:
              updatedCompany.installerAccountFeatures ?? [],
            msisdn:
              updatedCompany.msisdn === undefined
                ? null
                : updatedCompany.msisdn,
            map:
              updatedCompany.mapLatitude &&
                updatedCompany.mapLongitude &&
                updatedCompany.defaultMapZoom
                ? {
                  center: [
                    +updatedCompany.mapLatitude,
                    +updatedCompany.mapLongitude,
                  ],
                  defaultZoom: +updatedCompany.defaultMapZoom,
                }
                : null,
          },
          updatedCompany.id.toString()
        ),
        saveAccount({
          ...updatedCompany,
          contact_phone: updatedCompany.contactPhone,
        } as ClientApiAccount),
      ])

      if (fbRes === "OK" && apiRes.data.result === "OK") {
        cogoToast.success(`Kontoen '${updatedCompany.name}' blev gemt`)
      }
    } catch (error) {
      cogoToast.error(`Kontoen '${updatedCompany.name}' blev ikke gemt`)
      console.log(error)
    }
  }

  return (
    <MainContainer
      header={
        <>
          <h2>{viewAccount?.name}</h2>
          <FeatureComponent allowedUserRoles={[UserTypeEnum.CMSADMIN]}>
            <ContextMenu>
              <ul tw="list-none p-2 px-2 m-0">
                {!confirmDeleteAccount ? (
                  <li
                    onClick={() => setConfirmDeleteAccount(true)}
                    tw="cursor-pointer transition-all text-brand-red-500 flex px-4 py-4 items-center space-x-4 hover:(bg-brand-gray-brand)"
                  >
                    <Icon icon="trash-alt" size={IconSizeEnum.MD} />
                    <span>Slet konto</span>
                  </li>
                ) : (
                  <li tw="cursor-pointer transition-all text-brand-red-500 flex flex-col px-4 py-4 items-start">
                    <span tw="flex items-center space-x-4">
                      <Icon icon="trash-alt" size={IconSizeEnum.MD} />
                      <span>Slet konto?</span>
                    </span>

                    <ButtonGroup position="center" tw="w-full">
                      <Button
                        onClick={() => setConfirmDeleteAccount(false)}
                        size="sm"
                        variant="transparent"
                        tw="text-brand-black-base font-normal m-0"
                      >
                        Annullér
                      </Button>
                      <Button
                        onClick={() => onDeleteHandler()}
                        size="sm"
                        variant="danger"
                        tw="m-0"
                      >
                        Bekræft
                      </Button>
                    </ButtonGroup>
                  </li>
                )}
              </ul>
            </ContextMenu>
          </FeatureComponent>
        </>
      }
    >
      <div>
        {viewAccount && (
          <AccountForm
            account={viewAccount}
            onSubmit={onSaveHandler}
            basicTitle="Stamdata"
            settingsTitle="Opsætning"
            saveButtonText="Gem stamdata"
            showSettings
          />
        )}
      </div>
    </MainContainer>
  )
}
