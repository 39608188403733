import { useMemo, useContext } from "react"
import { ApiAccountTypeEnum } from "app/Account/types"
import { freetextSearch } from "app/Search/helper"
import { InstallationContext } from "../context"
import { DevicesContext } from "app/Devices/context"
import {
  Beacon,
  DeviceStockStatusEnum,
  StockDevice,
  Tracker,
} from "app/Devices/types"
import { SearchContext } from "app/Search/context"
import { AccountsContext } from "app/Account/context"
import uniqBy from "lodash-es/uniqBy"

export const useDeviceSearch = () => {
  const {
    state: { account, accountDevices },
  } = useContext(InstallationContext)
  const {
    state: { devices },
  } = useContext(DevicesContext)
  const {
    state: { query },
  } = useContext(SearchContext)
  const {
    state: { account: installerAccount },
  } = useContext(AccountsContext)

  const installerDevices = useMemo(() => {
    if (
      installerAccount &&
      installerAccount.description === ApiAccountTypeEnum.Installer &&
      devices
    ) {
      return devices
        .filter((dev) => +dev.accountID === +installerAccount.id)
        .map((x) => ({
          ...x,
          name: x.imei,
        }))
    }
    return []
  }, [installerAccount, devices])

  const datasetSearch = useMemo(() => {
    const searchDevices = uniqBy([...installerDevices, ...accountDevices], 'imei')
    if (searchDevices.length === 0) return null
    return freetextSearch(searchDevices, {
      threshold: 0.05,
      location: 4,
      distance: 20,
      ignoreLocation: true,
      keys: ["imei", "name", "deviceTypeName"],
      includeScore: true,
    })
  }, [accountDevices, installerDevices])

  const [
    installerStockDevices,
    installerAwaitingDevices,
    stockDevices,
    awaitingDevices,
    installedDevices,
    unverifiedDevices,
  ] = useMemo(() => {
    if (datasetSearch && query.length >= 2) {
      const searchResults = datasetSearch
        .search(query)
        .map((x) => x.item) as Partial<(Beacon | Tracker) & StockDevice[]>
      return [
        searchResults.filter((x) => +x.accountID === +installerAccount.id),
        searchResults.filter(
          (x) =>
            x.stockStatus === DeviceStockStatusEnum.Awaiting &&
            +x?.installationStartedOnAccountID === +account.id
        ),
        searchResults.filter(
          (x) =>
            x.stockStatus === DeviceStockStatusEnum.Stock &&
            +x.accountID === +account.id
        ),
        searchResults.filter(
          (x) => x.stockStatus === DeviceStockStatusEnum.Awaiting
        ),
        searchResults.filter(
          (x) => x.stockStatus === DeviceStockStatusEnum.Installed
        ),
        searchResults.filter(
          (x) => x.stockStatus === DeviceStockStatusEnum.Legacy
        ),
      ]
    }
    return [
      installerDevices.filter((x) => +x.accountID === +installerAccount.id),
      installerDevices.filter(
        (x) =>
          x.stockStatus === DeviceStockStatusEnum.Awaiting &&
          +x?.installationStartedOnAccountID === +account?.id
      ),
      accountDevices.filter(
        (x) => x.stockStatus === DeviceStockStatusEnum.Stock
      ),
      accountDevices.filter(
        (x) => x.stockStatus === DeviceStockStatusEnum.Awaiting
      ),
      accountDevices.filter(
        (x) => x.stockStatus === DeviceStockStatusEnum.Installed
      ),
      accountDevices.filter(
        (x) => x.stockStatus === DeviceStockStatusEnum.Legacy
      ),
    ]
  }, [query, accountDevices, installerDevices, datasetSearch, account])



  return {
    installerDevices,
    datasetSearch,
    installerStockDevices,
    installerAwaitingDevices,
    stockDevices,
    awaitingDevices,
    installedDevices,
    unverifiedDevices,
  }
}
