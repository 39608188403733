import at from "lodash-es/at"

export type CollectionFilter = {
  key: string
  group: string
  label: string
  value: string
  labelValue: string
}

export const filterCollection = (
  collection: any[],
  filters: CollectionFilter[]
) => {
  if (!collection) return []
  const reducedFilters = filters.reduce((acc, curr) => {
    if (acc[curr.key]) {
      return {
        ...acc,
        [curr.key]: [...acc[curr.key], curr.value],
      }
    }
    return {
      ...acc,
      [curr.key]: [curr.value],
    }
  }, {})

  const filterKeys = Object.keys(reducedFilters)

  const filteredCollection = collection.filter((item) => {
    if (filterKeys.length > 0) {
      return (
        filterKeys
          .map((key) => {
            // A key can be:
            // - A list of items to match
            // - An object path
            // - A simple string
            // First, let's see if it's a simple string
            if (
              reducedFilters[key].some(
                (filterValue) => item[key] === filterValue
              )
            )
              return true

            // Next, let's see if it's an object path. In that case, the key must contain a path using dot-notation, e.g. 'config.name'
            if (key.includes(".")) {
              const itemValueAtObjectPath = at(item, key)[0]
              return reducedFilters[key].some(
                (filterValue) => itemValueAtObjectPath === filterValue
              )
            }

            // Otherwise, check if it's a list of things to match
            if (
              item[key] &&
              typeof item[key] === "object" &&
              item[key].hasOwnProperty("length")
            ) {
              return reducedFilters[key].some((value) =>
                item[key].includes(value)
              )
            }
          })
          .filter(Boolean).length === filterKeys.length
      )
    }
    return true
  })

  return filteredCollection
}
