import React from "react"
import "twin.macro"
import { FormField } from "@clevertrack/shared"
import { ValidationInput } from "@clevertrack/shared"
import { FormFieldToggle } from "components/FormFieldToggle"
import { PhotoUpload } from "../../components/PhotoUpload"
import { useTranslation } from "react-i18next"

interface PhotoUploadWithNoteProps {
  photoPropertyKey: string
  photoPropertyValue: string | null
  photoFilename: string
  photoPath: string | null
  photoPathPropertyKey: string
  uploadLabel: string
  uploadDescription: string
  notePropertyKey: string
  noteValue: string | null
  onUpdateModel: (key: string, value: any) => void
  onDeletePhoto?: () => void
  onAcceptFilesHandler: (files: File[], filename: string, key: string) => Promise<void>
  defaultToggled?: boolean | string | null
}

export const PhotoUploadWithNote: React.FC<PhotoUploadWithNoteProps> = ({
  photoPropertyKey,
  photoPropertyValue,
  photoFilename,
  photoPath,
  photoPathPropertyKey,
  uploadLabel,
  uploadDescription,
  notePropertyKey,
  noteValue,
  onUpdateModel,
  onDeletePhoto,
  onAcceptFilesHandler,
  defaultToggled,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <PhotoUpload
        photoPropertyKey={photoPropertyKey}
        photoPropertyValue={photoPropertyValue}
        photoFilename={photoFilename}
        photoPath={photoPath}
        photoPathPropertyKey={photoPathPropertyKey}
        uploadLabel={uploadLabel}
        uploadDescription={uploadDescription}
        onUpdateModel={onUpdateModel}
        onDeletePhoto={onDeletePhoto}
        onAcceptFilesHandler={onAcceptFilesHandler}
      />
      <FormFieldToggle
        untoggleText={t("installation_remove_field")}
        toggleText={t("installation_add_note_to_photo")}
        defaultToggled={defaultToggled}
      >
        <FormField label={t("installation_note_optional")} tw="mt-4">
          <ValidationInput
            defaultValue={noteValue}
            placeholder={t("installation_note_optional_placeholder")}
            onChange={(e) => onUpdateModel(notePropertyKey, e.target.value)}
          />
        </FormField>
      </FormFieldToggle>
    </>
  )
}
