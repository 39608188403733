import { Flow } from "app/Flow"
import React, { useCallback, useContext, useMemo } from "react"
import "twin.macro"
import { Tasks } from "./Tasks"
import PopOver from "app/PopOver"
import { Responsive } from "@clevertrack/shared"
import { DataValidation } from "./DataValidation"
import { InstallationContext } from "../context"
import { InstallationActions, InstallationTypes } from "../actions"
import { DeviceCalibration } from "./DeviceCalibration"
import { DeviceInformation } from "./DeviceInformation"
import { DeviceHealthValidation } from "./DeviceHealthValidation"
import { InstallationVerification } from "./InstallationVerification"
import {
  DeviceInformationModel,
  InstallationScreenEnum,
  InstallationStep,
  InstallationStepStateEnum,
} from "../types"
import { useFirestoreDevices } from "services/firestore/devices"
import { useFirestoreInstallationGuide } from "services/firestore/installationguide"
import cogoToast from "@clevertrackdk/cogo-toast"
import {
  Device,
  DeviceStockStatusEnum,
  DeviceTypeCategoryEnum,
} from "app/Devices/types"
import { useInstallation } from "../hooks"
import { DevicesContext } from "app/Devices/context"
import { DevicesActions, DevicesTypes } from "app/Devices/actions"
import { Uninstall } from "./Uninstall"
import { DeviceAuditLogEvent } from "app/AuditLog/audit.types"
import { UserContext } from "app/User/context"
import { AccountsContext } from "app/Account/context"
import {
  createVehicle,
  patchVehicle,
  patchVehicleCalibration,
  patchVehicleFeatures,
  vehicleToGroup,
} from "services/vehicles"
import { useBackgroundDataFetch } from "app/Dashboard/hooks"
import { setDeviceDisplayKeys, setVehicleTag } from "services/devices"
import { useTranslation } from "react-i18next"
import { ReplaceDevice } from "./ReplaceDevice"
import { SearchProvider } from "app/Search/context"

export const InstallationFlow: React.FC = ({ ...props }) => {
  const {
    state: { currentScreen, device, account, installationSteps },
    dispatch,
  } = useContext(InstallationContext)
  const {
    state: { user },
  } = useContext(UserContext)
  const {
    state: { account: userAccount },
  } = useContext(AccountsContext)
  const { dispatch: devicesDispatch } = useContext(DevicesContext)
  const {
    getInstallationStepsByImei,
    saveInstallationStepByImei,
  } = useFirestoreDevices()
  const {
    saveFirebaseDevice,
    getFirebaseDeviceFromIMEI,
    newDeviceAuditLogEntry,
  } = useFirestoreDevices()
  const { deviceTypeCategory, mapVehicles } = useInstallation()
  const { createInstallationGuideEntry } = useFirestoreInstallationGuide()
  const { t } = useTranslation()

  const setCurrentScreen = (screen: InstallationScreenEnum | null) => {
    dispatch(InstallationActions(InstallationTypes.SetScreen, screen))
  }

  const createNewVehicle = async () => {
    try {
      if (account && device && device.imei) {
        const deviceStatusChanged = +new Date()
        const newDevice = await getFirebaseDeviceFromIMEI(device.imei)

        if (newDevice.result === "OK" && newDevice.device) {
          // Good to go to create new vehicle
          const {
            deviceCalibrationPayload,
            deviceFeaturesPayload,
            patchVehiclePayload,
            vehicleToGroupPayload,
            config,
          } = newDevice.device as Device

          let vehicleID
          let isUpdate = false
          if (newDevice.device.id) {
            // If the device has already been created, use that ID instead of creating a new vehicle
            vehicleID = newDevice.device.id
            isUpdate = true
          } else {
            // ...create the vehicle, and retrieve the ID
            const { result, vehicles } = await createVehicle(
              { unit_id: device.unit_id, name: device.imei },
              account.id
            )

            if (result === "OK") {
              vehicleID = vehicles ? vehicles[0].id : null
            }
          }

          if (vehicleID) {
            const vehiclePatch = patchVehicle(
              {
                ...patchVehiclePayload,
                account_id: account.id,
              },
              vehicleID
            )
            const featuresPatch = deviceFeaturesPayload
              ? patchVehicleFeatures(deviceFeaturesPayload, vehicleID)
              : null
            const groupsPatch = vehicleToGroupPayload
              ? vehicleToGroup(vehicleID, vehicleToGroupPayload.groups)
              : null
            const displayKeysPatch = config?.displayKeys
              ? setDeviceDisplayKeys(vehicleID, config.displayKeys)
              : null
            const vehicleTagPost = config?.tag
              ? setVehicleTag(vehicleID, config.tag)
              : null

            // Ensure calibration is invoked first, to
            const calibrationPatch = deviceCalibrationPayload
              ? await patchVehicleCalibration(
                deviceCalibrationPayload,
                vehicleID
              )
              : null

            const firebasePatch = saveFirebaseDevice(
              isUpdate
                ? {
                  accountID: account.id,
                  account: account.name,
                  id: vehicleID,
                  name: patchVehiclePayload.name,
                  installationStartedOnAccountID: null,
                  imei: device.imei.toString(),
                  stockStatus: DeviceStockStatusEnum.Installed,
                }
                : {
                  accountID: account.id,
                  account: account.name,
                  id: vehicleID,
                  name: patchVehiclePayload.name,
                  installationStartedOnAccountID: null,
                  imei: device.imei.toString(),
                  stockStatus: DeviceStockStatusEnum.Installed,
                  statusUpdated: deviceStatusChanged,
                  installedByAccount: userAccount?.name,
                  installedByAccountID: userAccount?.id,
                },
              device.imei
            )

            const results = await Promise.all(
              [
                vehiclePatch,
                featuresPatch,
                groupsPatch,
                firebasePatch,
                displayKeysPatch,
                vehicleTagPost,
              ].filter(Boolean)
            )
            if (
              [...results, calibrationPatch]
                .map((x) => x.result)
                .some((res) => res !== "OK")
            ) {
              cogoToast.error(t("installation_error_finishing_installation"))
              return
            } else {
              devicesDispatch(
                DevicesActions(DevicesTypes.UpdateDeviceByID, {
                  device: {
                    ...device,
                    name: patchVehiclePayload.name,
                    stockStatus: DeviceStockStatusEnum.Installed,
                    statusUpdated: deviceStatusChanged,
                  },
                })
              )

              dispatch(
                InstallationActions(InstallationTypes.SetDevice, {
                  device: {
                    ...device,
                    name: patchVehiclePayload.name,
                    stockStatus: DeviceStockStatusEnum.Installed,
                    statusUpdated: deviceStatusChanged,
                  },
                })
              )
              cogoToast.success(
                isUpdate
                  ? t("installation_installation_updated")
                  : t("installation_installation_finished")
              )
              await mapVehicles()
              setCurrentScreen(InstallationScreenEnum.Tasks)
              return
            }
          }
        }
      }
      cogoToast.error(t("installation_no_account_selected"))
    } catch (error) {
      cogoToast.error(`${t("installation_error")}: ${error}`)
    }
  }

  useMemo(() => {
    if (!device?.imei || !account) return
    const deviceStatusChanged = +new Date()
    if (
      installationSteps &&
      installationSteps?.find((step) =>
        [
          InstallationStepStateEnum.NotStarted,
          InstallationStepStateEnum.Incomplete,
        ].includes(step.stepState)
      )
    ) {
      // Still work to do
      if (
        installationSteps.some(
          (step) => step.stepState === InstallationStepStateEnum.Incomplete
        ) &&
        device.stockStatus !== DeviceStockStatusEnum.Awaiting
      ) {
        // Only set the device in "Awaiting installation" state in case some step is incomplete. Otherwise, keep their old state.
        saveFirebaseDevice(
          {
            installationStartedOnAccountID: account.id,
            imei: device.imei.toString(),
            stockStatus: DeviceStockStatusEnum.Awaiting,
            statusUpdated: deviceStatusChanged,
          },
          device.imei.toString()
        ).then((res) => {
          if (res.result === "OK") {
            devicesDispatch(
              DevicesActions(DevicesTypes.UpdateDeviceByID, {
                device: {
                  ...device,
                  stockStatus: DeviceStockStatusEnum.Awaiting,
                  statusUpdated: deviceStatusChanged,
                },
              })
            )
          }
        })
      }
    }
  }, [installationSteps, device])

  const onSaveInstallationStep = useCallback(
    async (
      screen: InstallationScreenEnum,
      data: InstallationStep,
      apiSave: Promise<any> | Promise<any>[] | null,
      options
    ) => {
      try {
        if (device?.imei) {
          if (options?.autoSave) {
            await saveInstallationStepByImei(device?.imei, screen, data)
            const steps = (await getInstallationStepsByImei(
              device?.imei
            )) as InstallationStep[]
            dispatch(
              InstallationActions(InstallationTypes.SetInstallationSteps, {
                steps,
              })
            )
          } else {
            const res = await saveInstallationStepByImei(
              device?.imei,
              screen,
              data
            )
            const steps = (await getInstallationStepsByImei(
              device?.imei
            )) as InstallationStep[]
            if (res.result === "OK") {
              const isFinished = !steps.find((step) =>
                [
                  InstallationStepStateEnum.NotStarted,
                  InstallationStepStateEnum.Incomplete,
                ].includes(step.stepState)
              )
              if (apiSave) {
                const results = await apiSave
                if (!results.map((x) => x.result).some((res) => res !== "OK")) {
                  if (isFinished) {
                    createNewVehicle()
                  } else {
                    cogoToast.success(t("installation_information_saved"))
                    setCurrentScreen(InstallationScreenEnum.Tasks)
                  }
                }
              } else {
                if (isFinished) {
                  createNewVehicle()
                } else {
                  cogoToast.success(t("installation_information_saved"))
                  setCurrentScreen(InstallationScreenEnum.Tasks)
                }
              }

              if (isFinished && steps) {
                // Save installation guide data
                const deviceInfoData = steps.find(
                  (x) => x.stepID === InstallationScreenEnum.DeviceInformation
                )?.data as DeviceInformationModel
                await createInstallationGuideEntry(
                  deviceInfoData?.vehicleBrand,
                  deviceInfoData?.vehicleModel,
                  deviceInfoData.vehicleYear,
                  device.imei,
                  `${account?.id}/${device?.imei}`
                )
              }

              if (
                isFinished &&
                device.stockStatus !== DeviceStockStatusEnum.Installed
              ) {
                await newDeviceAuditLogEntry(device?.imei?.toString(), {
                  event: DeviceAuditLogEvent.Installed,
                  timestamp: +new Date(),
                  user: `${user?.firstName} ${user?.lastName}`,
                  userID: user?.id ?? 0,
                  userAccount: userAccount?.name.toString() ?? "",
                  userAccountID: +userAccount?.id ?? 0,
                })
              }

              dispatch(
                InstallationActions(InstallationTypes.SetInstallationSteps, {
                  steps,
                })
              )
            }
          }
        }
      } catch (error) {
        cogoToast.error(t("installation_error_saving_information"))
        throw new Error(error)
      }
    },
    [device, account, user, userAccount]
  )

  const screenSet = useMemo(() => {
    const screens = [
      {
        key: "tasks",
        children: (
          <Tasks
            onSelectScreen={(screen: InstallationScreenEnum | null) =>
              setCurrentScreen(screen)
            }
          />
        ),
        wrapper: (
          <PopOver
            fromRight
            show={
              currentScreen === InstallationScreenEnum.Tasks ||
              currentScreen !== null
            }
            zindex={2200}
            selector="#___dashboard-inner"
            tw="absolute shadow-none overflow-hidden"
          />
        ),
      },
      {
        key: "uninstall",
        children: (
          <Uninstall
            onSelectScreen={(screen: InstallationScreenEnum | null) =>
              setCurrentScreen(screen)
            }
          />
        ),
        wrapper: (
          <PopOver
            fromBottom
            show={currentScreen === InstallationScreenEnum.Uninstall}
            zindex={2300}
            selector="#___dashboard-inner"
            tw="absolute shadow-none overflow-hidden"
          />
        ),
      },
      {
        key: "replaceDevice",
        children: <ReplaceDevice onSelectScreen={setCurrentScreen} />,
        wrapper: (
          <PopOver
            fromRight
            show={currentScreen === InstallationScreenEnum.ReplaceDevice}
            zindex={2300}
            selector="#___dashboard-inner"
            tw="absolute shadow-none overflow-hidden"
          />
        ),
      },
    ]

    if (installationSteps && installationSteps.length > 0) {
      screens.push(
        ![
          DeviceTypeCategoryEnum.Beacon,
          DeviceTypeCategoryEnum.GPSTrackerWithBattery,
        ].includes(deviceTypeCategory)
          ? {
            key: "deviceCalibration",
            children: <DeviceCalibration onSave={onSaveInstallationStep} />,
            wrapper: (
              <PopOver
                fromRight
                show={
                  currentScreen === InstallationScreenEnum.DeviceCalibration
                }
                zindex={2300}
                selector="#___dashboard-inner"
                tw="absolute shadow-none overflow-hidden"
              />
            ),
          }
          : null,
        {
          key: "deviceInformation",
          children: <DeviceInformation onSave={onSaveInstallationStep} />,
          wrapper: (
            <PopOver
              fromRight
              show={currentScreen === InstallationScreenEnum.DeviceInformation}
              zindex={2300}
              selector="#___dashboard-inner"
              tw="absolute shadow-none overflow-hidden"
            />
          ),
        },
        {
          key: "installationVerification",
          children: (
            <InstallationVerification onSave={onSaveInstallationStep} />
          ),
          wrapper: (
            <PopOver
              fromRight
              show={
                currentScreen ===
                InstallationScreenEnum.InstallationVerification
              }
              zindex={2300}
              selector="#___dashboard-inner"
              tw="absolute shadow-none overflow-hidden"
            />
          ),
        },
        /* {
          key: "dataValidation",
          children: <DataValidation />,
          wrapper: (
            <PopOver
              fromRight
              show={
                currentScreen === InstallationScreenEnum.DataValidation &&
                toggled
              }
              zindex={2300}
              selector="#___dashboard-inner"
              tw="absolute shadow-none overflow-hidden"
            />
          ),
        }, */
        ![
          DeviceTypeCategoryEnum.Beacon,
          DeviceTypeCategoryEnum.GPSTrackerWithBattery,
        ].includes(deviceTypeCategory)
          ? {
            key: "deviceValidation",
            children: (
              <DeviceHealthValidation onSave={onSaveInstallationStep} />
            ),
            wrapper: (
              <PopOver
                fromRight
                show={
                  currentScreen ===
                  InstallationScreenEnum.DeviceHealthValidation
                }
                zindex={2300}
                selector="#___dashboard-inner"
                tw="absolute shadow-none overflow-hidden"
              />
            ),
          }
          : null
      )
    }

    return screens.filter(Boolean)
  }, [currentScreen, installationSteps, device])

  return (
    <SearchProvider>
      <Responsive phone={<Flow screenSet={screenSet} />} />
    </SearchProvider>
  )
}
