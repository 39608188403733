import React from "react"
import "twin.macro"
import { useTranslation } from "react-i18next"
import { RadioButtonGroup } from "./RadioButtonGroup"
import { PhotoUploadWithNote } from "./PhotoUploadWithNote"

interface AutoOffSectionProps {
  currentModel: any
  stepData: any
  updateModel: (key: string, value: any) => void
  setCurrentModel: (model: any) => void
  onAcceptFilesHandler: (files: File[], filename: string, key: string) => Promise<void>
  removeItemFromUploadMapByKey: (key: string) => void
}

export const AutoOffSection: React.FC<AutoOffSectionProps> = ({
  currentModel,
  stepData,
  updateModel,
  setCurrentModel,
  onAcceptFilesHandler,
  removeItemFromUploadMapByKey,
}) => {
  const { t } = useTranslation()

  const handleAutoOffMountedChange = (value: boolean) => {
    if (value) {
      updateModel("autoOffMounted", true)
      updateModel(
        "autoOffRelayMountPhoto",
        stepData?.data?.autoOffRelayMountPhoto ?? null
      )
      updateModel(
        "autoOffRelayMountPhotoPath",
        stepData?.data?.autoOffRelayMountPhotoPath ?? null
      )
      updateModel(
        "autoOffRelayMountNote",
        stepData?.data?.autoOffRelayMountNote ?? null
      )
    } else {
      const {
        autoOffRelayMountPhoto,
        autoOffRelayMountPhotoPath,
        autoOffRelayMountNote,
        ...newModel
      } = currentModel
      setCurrentModel({
        ...newModel,
        autoOffMounted: false,
      })
    }
  }

  return (
    <>
      <h4>{t("installation_auto_off")}</h4>
      <div>
        <span>{t("installation_auto_off_description")}</span>
        <RadioButtonGroup
          options={[
            {
              value: true,
              label: t("installation_yes"),
              onChange: () => handleAutoOffMountedChange(true),
            },
            {
              value: false,
              label: t("installation_no"),
              onChange: () => handleAutoOffMountedChange(false),
            },
          ]}
          selectedValue={currentModel?.autoOffMounted}
        />
        
        {currentModel?.autoOffMounted === true && (
          <div tw="space-y-8">
            <h4>{t("installation_auto_off_relay_mount_photo")}</h4>
            <PhotoUploadWithNote
              photoPropertyKey="autoOffRelayMountPhoto"
              photoPropertyValue={currentModel?.autoOffRelayMountPhoto}
              photoFilename="auto-off-relay-mount-photo.jpeg"
              photoPath={currentModel?.autoOffRelayMountPhotoPath}
              photoPathPropertyKey="autoOffRelayMountPhotoPath"
              uploadLabel=""
              uploadDescription={t("installation_auto_off_relay_mount_photo_description")}
              notePropertyKey="autoOffRelayMountNote"
              noteValue={currentModel?.autoOffRelayMountNote}
              onUpdateModel={updateModel}
              onDeletePhoto={() => removeItemFromUploadMapByKey("autoOffRelayMountPhoto")}
              onAcceptFilesHandler={onAcceptFilesHandler}
              defaultToggled={currentModel?.autoOffRelayMountNote}
            />
            
            <h4>{t("installation_additional_info")}</h4>
            <div>
              <span>{t("installation_auto_off_turns_off_display")}</span>
              <RadioButtonGroup
                options={[
                  {
                    value: true,
                    label: t("installation_yes"),
                    onChange: () => updateModel("autoOffTurnsOffDisplay", true),
                  },
                  {
                    value: false,
                    label: t("installation_no"),
                    onChange: () => updateModel("autoOffTurnsOffDisplay", false),
                  },
                ]}
                selectedValue={currentModel?.autoOffTurnsOffDisplay}
              />
            </div>
            
            <div>
              <span>{t("installation_auto_off_turns_off_light")}</span>
              <RadioButtonGroup
                options={[
                  {
                    value: true,
                    label: t("installation_yes"),
                    onChange: () => updateModel("autoOffTurnsOffLight", true),
                  },
                  {
                    value: false,
                    label: t("installation_no"),
                    onChange: () => updateModel("autoOffTurnsOffLight", false),
                  },
                ]}
                selectedValue={currentModel?.autoOffTurnsOffLight}
              />
            </div>
            
            <div>
              <span>{t("installation_auto_off_requires_turn_key")}</span>
              <RadioButtonGroup
                options={[
                  {
                    value: true,
                    label: t("installation_yes"),
                    onChange: () => updateModel("autoOffRequiresTurnKey", true),
                  },
                  {
                    value: false,
                    label: t("installation_no"),
                    onChange: () => updateModel("autoOffRequiresTurnKey", false),
                  },
                ]}
                selectedValue={currentModel?.autoOffRequiresTurnKey}
              />
            </div>
          </div>
        )}
      </div>
    </>
  )
}
