import { useState, useRef, SetStateAction, Dispatch, useEffect } from "react"

export const useFormData = <T extends object>(
  model?: T | null,
  onUpdateCallback?: () => void
): [
  T | null,
  (key: keyof T, value: any) => void,
  () => void,
  Dispatch<SetStateAction<T | null>>
] => {
  const modelRef = useRef<T | null>(model ?? null)
  const [dataModel, setDataModel] = useState<T | null>(model ?? null)

  useEffect(() => {
    modelRef.current = model ?? null
    setDataModel(model ?? null)
  }, [model])

  const updateModel = (key: keyof T, value: any) => {
    if (dataModel !== null) {
      setDataModel(
        (prev) =>
          ({
            ...prev,
            [key]: value,
          } as T)
      )
    }
    if (onUpdateCallback) onUpdateCallback()
  }

  const resetModel = () => {
    setDataModel(modelRef.current)
  }

  return [dataModel, updateModel, resetModel, setDataModel]
}
