import { Link } from "gatsby"
import React, { useContext, useEffect } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import Navigation from "./Navigation"
import User from "app/User"
import { useBackgroundDataFetch, useNavigationItems } from "./hooks"
import { AppContext } from "context"
import { UserContext } from "app/User/context"
import { Responsive } from "@clevertrack/shared"
import { TapBar } from "lib/Navigation/TapBar"
import NavUntoggle from "lib/Navigation/Untoggle"
import { GlobalAppActions, GlobalAppTypes } from "context/App/actions"
import OffCanvasNav from "app/OffCanvasNav"
import { usePermissions } from "app/FeatureComponent/hooks"
import { ApiAccountTypeEnum } from "app/Account/types"
import { FeatureComponent } from "app/FeatureComponent"
import { UserTypeEnum } from "app/User/types"
import { useDeviceConfiguration } from "app/Configuration/hooks"

const StyledDashboard = styled.main<{ columns: string }>`
  overflow: hidden;
  ${(props) => props.theme.media.tablet_landscape_up`
    height: 100vh;
    display: grid;
    grid-template-columns: ${props.columns};
  `}
`

const StyledBackdrop = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1200;
  pointer-events: ${(props) => (props.show ? "all" : "none")};
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity ease-out 0.3s;
`

const StyledNavUntoggle = styled(NavUntoggle)`
  border: none;
  margin: 0;

  &:focus {
    outline: 0;
  }
`

const StyledSidebar = styled.header`
  ${tw`bg-brand-gray-brand`}
  ${(props) => props.theme.media.tablet_landscape_up`
    display: grid;
    grid-template-rows: 10rem auto;
    height: 100%;
    .logo {
      display: flex;
      align-items: center;
      font-family: ${props.theme.fontStacks.bold};
      // height: 4rem;
      ${tw`m-8`}

      span {
        font-size: 2.4rem;
        margin-top: 0.5rem;
        ${tw`block ml-4`}
      }

      img {
        align-self: center;
        line-height: 0;
        width: 4rem;
        height: 4rem;
      }
    }
  `}
`

const StyledContent = styled.div`
  /* filter: drop-shadow(0 20px 25px -5px rgb(0 0 0 / 0.1))
    drop-shadow(0 8px 10px -6px rgb(0 0 0 / 0.1)); */
  overflow-y: scroll;
  height: 100vh;
  ${tw`border-0 shadow-xl bg-white lg:h-full`}
`

const StyledWrapper = styled.section`
  ${tw`relative max-w-full`}
  overflow-x: hidden;
  overflow-y: hidden;
  ${(props) => props.theme.media.tablet_landscape_up`
    display: grid;
    grid-template-rows: auto;
    ${tw`bg-brand-gray-brand`}
  `}
`

const StyledNav = styled.nav`
  ${(props) => props.theme.media.tablet_landscape_up`

  `}
`

const StyledUser = styled.div`
  position: relative;

  ${(props) => props.theme.media.tablet_landscape_up`
    margin-left: auto;
    display: flex;
  `}
`

export const Dashboard: React.FC = ({ children, ...props }) => {
  const {
    state: { user },
  } = useContext(UserContext)
  const {
    state: { navToggled, backgroundDataFetched },
    dispatch,
  } = useContext(AppContext)
  const { getConfigs } = useDeviceConfiguration()
  const {
    getApiAccounts,
    getApiDevices,
    getApiUsers,
    getConfigurationFiles,
  } = useBackgroundDataFetch()
  const { tapBarNavigationItems } = useNavigationItems()
  const { hasAccountAccess, hasAccess } = usePermissions()

  useEffect(() => {
    if (!backgroundDataFetched) {
      const getData = async () => {
        await Promise.all([
          getApiAccounts(),
          getApiDevices(),
          getApiUsers(),
          getConfigurationFiles(),
          getConfigs(),
        ])
        dispatch(GlobalAppActions(GlobalAppTypes.SetBackgroundDataFetched, {}))
      }
      getData()
    }
  }, [backgroundDataFetched])

  const onNavToggle = () =>
    dispatch(GlobalAppActions(GlobalAppTypes.ToggleNav, {}))

  const handleNavToggle = (toggled) => {
    dispatch(GlobalAppActions(GlobalAppTypes.ToggleNav, { toggled }))
  }

  const renderSidebar = () => {
    return (
      <StyledSidebar>
        <Link to="/app" className="logo">
          <img src="/gradient-logomark.svg" />
          <span>Hub</span>
        </Link>

        <StyledNav>
          <Navigation>
            <User />
          </Navigation>
        </StyledNav>
      </StyledSidebar>
    )
  }

  const renderOffCanvasNav = () => {
    return (
      <>
        <OffCanvasNav
          navToggle={
            <StyledNavUntoggle onToggle={() => handleNavToggle(false)} />
          }
          nav={
            <Navigation
              user={user}
              onSelectNavItem={() => handleNavToggle(false)}
            />
          }
        >
          <StyledUser>
            <User tw="pl-4" />
          </StyledUser>
        </OffCanvasNav>
        <StyledBackdrop
          onClick={() => handleNavToggle(false)}
          show={navToggled}
        />
        <TapBar
          key="tap-nav"
          items={tapBarNavigationItems}
          onNavToggle={onNavToggle}
        />
      </>
    )
  }

  return (
    <StyledDashboard
      columns={
        hasAccountAccess([ApiAccountTypeEnum.Installer]) ? `20rem 1fr` : `1fr`
      }
    >
      <FeatureComponent allowedAccountTypes={[ApiAccountTypeEnum.Installer]}>
        <Responsive
          phone={renderOffCanvasNav()}
          tabletLandscape={renderSidebar()}
        />
      </FeatureComponent>
      <StyledWrapper id="___dashboard-inner">
        <StyledContent>{children}</StyledContent>
      </StyledWrapper>
    </StyledDashboard>
  )
}
