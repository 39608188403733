import React, { useContext, useMemo, useState } from "react"
import "twin.macro"
import { MainContainer } from "app/MainContainer"
import { DataTable } from "app/DataTable"
import { StockType } from "app/DataTable/components/StockType"
import { Filter } from "app/DataTable/Filter"
import { deviceFilterGroups, deviceStockStatus } from "app/Devices/helper"
import { CollectionFilter } from "utils/collection/filter"
import uniq from "lodash-es/uniq"
import { DevicesContext } from "app/Devices/context"
import { SearchProvider } from "app/Search/context"
import { navigate } from "gatsby"
import { IconSizeEnum } from "lib/Icon"
import { Button, Icon, Loading } from "@clevertrack/shared"
import format from "date-fns/format"
import { Select } from "lib/Select"
import { AccountsContext } from "app/Account/context"
import PopOver from "app/PopOver"
import cogoToast from "@clevertrackdk/cogo-toast"
import { useFirestoreDevices } from "services/firestore/devices"
import { useBackgroundDataFetch } from "app/Dashboard/hooks"
import { useAccounts } from "app/Account/hooks"
import { ApiVehicle, Device, DeviceStockStatusEnum } from "app/Devices/types"
import { ConfigurationContext } from "app/Configuration/context"
import { useDeviceConfiguration } from "app/Configuration/hooks"
import type { DeviceConfiguration } from "app/Configuration/types"
import { Account, ApiAccountTypeEnum } from "app/Account/types"
import { createVehicle } from "services/vehicles"
import { AccountsActions, AccountTypes } from "app/Account/actions"
import { UserTypeEnum } from "app/User/types"
import { usePermissions } from "app/FeatureComponent/hooks"
import { useDeviceFilter } from "app/Devices/hooks"
import {
  searchDevice,
  setDeviceDisplayKeys,
  SetDeviceDisplayKeysResponse,
  setVehicleTag,
  SetVehicleTagResponse,
} from "services/devices"

enum SelectedDeviceActions {
  AssignToAccount = "assignToAccount",
  UpdateConfiguration = "updateConfiguration",
  UpdateStockStatus = "updateStockStatus",
}

const availableActions = [
  {
    label: "Tildel enheder til konto",
    value: SelectedDeviceActions.AssignToAccount,
  },
  {
    label: "Konfigurer enheder",
    value: SelectedDeviceActions.UpdateConfiguration,
  },
  {
    label: "Opdatér lagerstatus",
    value: SelectedDeviceActions.UpdateStockStatus,
  },
]

export const Devices: React.FC = ({ ...props }) => {
  const {
    state: { devices },
  } = useContext(DevicesContext)
  const {
    state: { accounts },
    dispatch,
  } = useContext(AccountsContext)
  const {
    state: { configurationList },
  } = useContext(ConfigurationContext)
  const {
    configurationListOptions,
    updateDeviceConfiguration,
    configurationUpdatePartOptions,
  } = useDeviceConfiguration()
  const { hasAccess } = usePermissions()
  const [isLoading, setIsLoading] = useState(false)
  const [selectedRows, setSelectedRows] = useState<Device[]>([])
  const [selectedAccount, setSelectedAccount] = useState<{
    id: string
    name: string
  } | null>(null)
  const [selectedConfig, setSelectedConfig] = useState<{
    label: string
    value: any
  } | null>(null)
  const [selectedConfigParts, setSelectedConfigParts] = useState<
    { label: string; value: any }[]
  >([])
  const [selectedStockStatus, setSelectedStockStatus] = useState(null)
  const {
    selectedFilters,
    setSelectedFilters,
    availableDeviceFilterGroups,
  } = useDeviceFilter()
  const [
    selectedAction,
    setSelectedAction,
  ] = useState<SelectedDeviceActions | null>(null)
  const { availableAccounts } = useAccounts()
  const {
    assignFirebaseDevicesToAccount,
    saveFirebaseDevice,
  } = useFirestoreDevices()

  const { getApiDevices } = useBackgroundDataFetch()

  const onSelectAccountHandler = (account: Account) => {
    dispatch(
      AccountsActions(AccountTypes.SetViewAccount, {
        viewAccount: account,
      })
    )
    navigate(`/app/accounts/${account.id}/devices`)
  }

  const onResetState = () => {
    setSelectedAccount(null)
    setSelectedAction(null)
    setSelectedConfig(null)
    setSelectedConfigParts([])
    setSelectedStockStatus(null)
    setIsLoading(false)
  }

  const onMultiSelectHandler = async (rows) => {
    setSelectedRows(rows)
  }

  const onAssignDevicesToAccount = async () => {
    try {
      if (selectedAccount) {
        const mappedDevices = selectedRows.map(
          ({ id, created, deviceTypeName, stockStatus, vehicleID, ...row }) => {
            return {
              ...row,
              id: null,
              account: selectedAccount.name,
              accountID: selectedAccount.id,
              stockStatus: stockStatus ?? DeviceStockStatusEnum.Stock,
              unit_id: id,
            }
          }
        )

        const destinationAccountData = accounts.find(
          (acc) => acc.id === selectedAccount.id
        )

        if (
          destinationAccountData &&
          destinationAccountData.description !== ApiAccountTypeEnum.Installer
        ) {

          const mappedDevicesToCreate = mappedDevices.filter(
            (device) => !device.vehicleID
          )

          const createVehiclesOnAccountPromises = mappedDevicesToCreate.map(
            (device) =>
              createVehicle(
                {
                  unit_id: device?.unit_id,
                  name: device?.imei,
                },
                destinationAccountData?.id
              )
          )

          const createVehiclesResults = await Promise.all(
            createVehiclesOnAccountPromises
          )

          if (createVehiclesResults.some((res) => res.result !== "OK")) {
            cogoToast.error(
              `Kunne ikke tildele de valgte enheder til kundekontoen.`
            )
            return
          } else {
            // Success, also add tagname and displayKeys
            const createdDevicesMap = createVehiclesResults
              .flatMap((res) => res?.vehicles || null)
              .filter(Boolean) as ApiVehicle[]
            if (createdDevicesMap.length > 0) {
              const updatePromises = createdDevicesMap
                .flatMap((vehicle) => {
                  const selectedRow = mappedDevices.find(
                    (device) => device.unit_id === vehicle.unit_id
                  )
                  if (selectedRow && selectedRow.config) {
                    const updates = [
                      setDeviceDisplayKeys(
                        vehicle.id,
                        selectedRow.config.displayKeys
                      ),
                      setVehicleTag(vehicle.id, selectedRow.config.tag),
                    ] as [
                        Promise<SetDeviceDisplayKeysResponse>,
                        Promise<SetVehicleTagResponse>
                      ]
                    return updates
                  } else return null
                })
                .filter((x) => x !== null)
              const results = await Promise.all(updatePromises)
              if (results.some((res) => res.result !== "OK")) {
                cogoToast.error(
                  `Kunne ikke opdatere en eller flere af de valgte enheder med display keys og/eller tagname.`
                )
                return
              }
            }
          }
        }

        const result = await assignFirebaseDevicesToAccount(mappedDevices)
        if (result?.result === "OK") {
          cogoToast.success(
            `${mappedDevices.length} enheder tildelt til '${selectedAccount.name}`
          )

          await getApiDevices()
          onResetState()
        }
      }
    } catch (error) {
      cogoToast.error("Kunne ikke tildele enheder til konto")
    }
  }

  const onUpdateDeviceConfiguration = async () => {
    try {
      if (selectedConfig) {
        const cfg = configurationList.find(
          (config) => config.id === selectedConfig.value
        )
        if (cfg) {
          setIsLoading(true)
          const result = await updateDeviceConfiguration(
            selectedRows,
            cfg,
            true,
            selectedConfigParts
              .map((x) => x.value)
              .filter(Boolean) as (keyof DeviceConfiguration)[]
          )

          if (result) {
            await getApiDevices()
            onResetState()
          }
        } else {
          cogoToast.error("Ingen konfiguration valgt.")
        }
      }
    } catch (error) {
      cogoToast.error("Kunne ikke opdatere enhederne med ny konfiguration.")
      console.log(error)
    }
  }

  const onUpdateStockStatus = async () => {
    try {
      const updatePromises = selectedRows.map((row) => {
        return saveFirebaseDevice(
          { stockStatus: selectedStockStatus },
          row.imei
        )
      })
      const result = await Promise.all(updatePromises)
      if (result && !result.map((x) => x?.result).includes("Error")) {
        cogoToast.success("Enheder opdateret med ny lagerstatus")
      } else {
        cogoToast.error(
          "Nogle enheder kunne ikke opdateres med ny lagerstatus."
        )
      }
      await getApiDevices()
      onResetState()
    } catch (error) {
      cogoToast.error("Kunne ikke opdatere enhederne med ny lagerstatus.")
      console.log(error)
    }
  }

  return (
    <MainContainer
      header={
        <>
          <h2>Enheder</h2>
          <Button
            type="button"
            variant="primary"
            icon="right"
            size="sm"
            tw="ml-8"
            onClick={() => navigate("/app/devices/create")}
          >
            <span>Opret enheder</span>
            <Icon icon="plus" size={IconSizeEnum.SM} />
          </Button>
        </>
      }
    >
      <SearchProvider>
        <Filter
          filterGroups={availableDeviceFilterGroups}
          dataset={devices}
          onFilterUpdated={(filter) => setSelectedFilters(filter)}
          freetextPlaceholder="Søg i enheder"
          tw="relative z-80"
        />
        <PopOver
          tw="z-100 py-4 px-8 bottom-0 left-0 flex items-center absolute bg-white shadow-none overflow-visible"
          show={selectedRows.length > 0}
          selector="#dataTableFooter"
          fromBottom
        >
          <div tw="w-1/5 mr-8">
            <Select
              tw="bg-brand-gray-brand"
              placeholder="Vælg en handling"
              options={availableActions}
              onChange={(opt) => setSelectedAction(opt.value)}
              value={availableActions.find((x) => x.value === selectedAction)}
              menuPlacement="auto"
            />
          </div>
          {selectedAction === SelectedDeviceActions.AssignToAccount && (
            <>
              <div tw="w-1/5">
                <Select
                  tw="bg-brand-gray-brand"
                  placeholder="Vælg en konto"
                  options={availableAccounts}
                  onChange={(opt) => setSelectedAccount(opt.value)}
                  menuPlacement="auto"
                />
              </div>
              <Button
                type="button"
                variant="default"
                icon="right"
                size="sm"
                tw="ml-8"
                onClick={onAssignDevicesToAccount}
              >
                <span>
                  Tildel {selectedRows.length} enheder til{" "}
                  {selectedAccount ? selectedAccount.name : "konto"}
                </span>
                <Icon icon="key" size={IconSizeEnum.SM} />
              </Button>
            </>
          )}
          {selectedAction === SelectedDeviceActions.UpdateStockStatus && (
            <>
              <div tw="w-1/5">
                <Select
                  tw="bg-brand-gray-brand"
                  placeholder="Vælg en status"
                  options={deviceStockStatus}
                  onChange={(opt) => setSelectedStockStatus(opt.value)}
                  menuPlacement="auto"
                />
              </div>
              <Button
                type="button"
                variant="default"
                icon="right"
                size="sm"
                tw="ml-8"
                onClick={onUpdateStockStatus}
              >
                <span>
                  Opdatér {selectedRows.length} enheder med lagerstatus:{" "}
                  {selectedStockStatus ? selectedStockStatus : "<Vælg status>"}
                </span>
                <Icon icon="key" size={IconSizeEnum.SM} />
              </Button>
            </>
          )}
          {selectedAction === SelectedDeviceActions.UpdateConfiguration && (
            <>
              <div tw="w-1/5 mr-8">
                <Select
                  tw="bg-brand-gray-brand"
                  placeholder="Vælg en konfiguration"
                  options={configurationListOptions}
                  onChange={(opt) => setSelectedConfig(opt)}
                  value={selectedConfig}
                  menuPlacement="auto"
                />
              </div>
              <div>
                <Select
                  tw="bg-brand-gray-brand"
                  placeholder="Vælg hvad der skal opdateres"
                  options={configurationUpdatePartOptions}
                  onChange={(opt) => setSelectedConfigParts(opt)}
                  isMulti
                  menuPlacement="auto"
                />
              </div>
              <Button
                type="button"
                variant="default"
                icon="right"
                size="sm"
                disabled={
                  !selectedConfig ||
                  selectedConfigParts.length === 0 ||
                  isLoading
                }
                tw="ml-8"
                onClick={onUpdateDeviceConfiguration}
              >
                {isLoading ? (
                  <>
                    <span tw="relative pr-16">
                      Opdaterer {selectedRows.length} enheder med
                      konfigurationen '
                      {selectedConfig ? selectedConfig.label : "konfiguration"}'
                      <Icon
                        icon="spinner"
                        size={IconSizeEnum.MD}
                        tw="right-4 absolute text-white animate-spin"
                      />
                    </span>
                  </>
                ) : (
                  <>
                    <span>
                      Opdatér {selectedRows.length} enheder med konfigurationen
                      '{selectedConfig ? selectedConfig.label : "konfiguration"}
                      '
                    </span>
                    <Icon icon="upload" size={IconSizeEnum.SM} />
                  </>
                )}
              </Button>
            </>
          )}
        </PopOver>
        <DataTable
          id="dataTable"
          idProps={["id", "imei", "simicc"]}
          columns={[
            { key: "id", title: "Device ID" },
            { key: "vehicleID", title: "Vehicle ID" },
            {
              key: "accountID",
              title: "Konto",
              formatData: (d) => {
                const acc = accounts.find((a) => +a.id === +d)
                if (acc) {
                  return (
                    <span
                      tw="cursor-pointer hover:text-brand-500 transition-all"
                      onClick={
                        hasAccess([UserTypeEnum.CMSADMIN])
                          ? () => onSelectAccountHandler(acc)
                          : () => ({})
                      }
                    >
                      {acc.name}
                    </span>
                  )
                } else {
                  return <span tw="opacity-60">Ingen konto</span>
                }
              },
            },
            {
              key: "stockStatus",
              title: "Monteringsstatus",
              component: StockType,
              formatData: (d) => d.replace("montering", ""),
            },
            {
              key: "installationStartedOnAccountID",
              title: "Installeres for",
              formatData: (d) => {
                if (d === null) return null
                return (
                  <span tw="text-lg px-2 py-1 bg-brand-gray-brand inline-block overflow-hidden truncate whitespace-nowrap max-w-full">
                    {accounts.find((acc) => acc.id === d).name}
                  </span>
                )
              },
            },
            {
              key: "statusUpdated",
              title: "Status ændret",
              formatData: (d) => format(new Date(d), "dd-MM-yyyy 'kl. ' HH:mm"),
            },
            { key: "deviceCategory", title: "Type" },
            { key: "deviceTypeName", title: "Enhedstype" },
            {
              key: "config",
              title: "Konfiguration",
              formatData: (d) => d.name,
            },
            { key: "imei", title: "IMEI/ID" },
            { key: "simicc", title: "SIMICC" },
            {
              key: "created",
              title: "Oprettet",
              formatData: (d) => format(new Date(d), "dd-MM-yyyy"),
            },
          ]}
          columnConfig="2rem 8rem 6rem 1fr 1fr 1fr 1fr 8rem 1fr 1fr 1fr 1fr 8rem"
          multiSelectIdentifierKey="imei"
          onMultiSelect={onMultiSelectHandler}
          filters={selectedFilters}
          dataset={devices}
          itemsPerPage={300}
          searchKeys={["id", "account", "imei", "simicc"]}
          // onRowSelect={onSelectAccountHandler}
          tw="px-8 relative z-30"
          compactSettings={{
            titleKey: "imei",
            descriptionKey: ["id", "deviceTypeName", "simicc"],
            secondaryDescriptionKey: ["account", "stockStatus"],
            secondaryDescriptionKeySeparator: " - ",
          }}
        />
      </SearchProvider>
    </MainContainer>
  )
}
