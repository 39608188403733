import { useContext, useEffect, useMemo } from "react"
import { getAccountVehicleGroups, getAccountVehicles } from "services/accounts"
import { InstallationContext } from "./context"
import { InstallationActions, InstallationTypes } from "./actions"
import { DevicesContext } from "app/Devices/context"
import {
  Beacon,
  Device,
  DeviceStockStatusEnum,
  DeviceTypeCategoryEnum,
  StockDevice,
  Tracker,
} from "app/Devices/types"
import {
  assetTracker,
  generateDeviceCalibrationStep,
  generateDeviceHealthStep,
  generateDeviceInformationStep,
  generateInstallationVerificationStep,
  gpsTrackerDeviceTypes,
  gpsTrackerWithBattery,
  gpsTrackerWithCANDeviceTypes,
} from "./Flow/helper"
import { InstallationScreenEnum, InstallationStep } from "./types"
import { useFirebaseFunctions } from "services/firebase-functions/functions"
import { deviceTypeObject } from "app/Devices/helper"
import { useFirestoreDevices } from "services/firestore/devices"
import { getVehicleCalibration, getVehicleFeatures } from "services/vehicles"
import { AccountsContext } from "app/Account/context"
import { useBackgroundDataFetch } from "app/Dashboard/hooks"

export const useInstallation = () => {
  const {
    state: { account, vehicles: accountVehicles, accountDevices, device },
    dispatch,
  } = useContext(InstallationContext)
  const { getApiDevicesFromAccount } = useBackgroundDataFetch()
  const {
    state: { devices },
    dispatch: devicesDispatch,
  } = useContext(DevicesContext)
  const {
    state: { account: installerAccount },
  } = useContext(AccountsContext)
  const {
    saveFirebaseDevice,
    getInstallationStepsByImei,
    saveInstallationStepByImei,
  } = useFirestoreDevices()
  const { getFotaDevice } = useFirebaseFunctions()

  const getAccountApiVehicles = async (accountID) => {
    // Needs rework, to reflect newly updated installations better
    const response = await getAccountVehicles(accountID)
    const groupResponse = await getAccountVehicleGroups(accountID)

    if (groupResponse.result === "OK" && groupResponse.groups) {
      dispatch(
        InstallationActions(InstallationTypes.SetAccountVehicleGroups, {
          groups: groupResponse.groups,
        })
      )
    }

    if (response.result === "OK" && response.accountVehicles) {
      dispatch(
        InstallationActions(InstallationTypes.SetVehicles, {
          accountVehicles: response.accountVehicles.filter((x) => !x.deleted),
        })
      )
      return response.accountVehicles.filter((x) => !x.deleted)
    }
  }

  const getFotaDevicByIMEI = async (imei) => {
    const fotaReq = await getFotaDevice({ deviceImei: imei })
    if (fotaReq.data.status === "OK" && fotaReq.data.device) {
      dispatch(
        InstallationActions(InstallationTypes.SetFotaDeviceData, {
          fotaDevice: fotaReq.data.device,
        })
      )
    }
  }

  const deviceTypeCategory = useMemo(() => {
    if (+device?.deviceType) {
      if (gpsTrackerDeviceTypes.includes(+device?.deviceType))
        return DeviceTypeCategoryEnum.GPSTracker
      if (gpsTrackerWithCANDeviceTypes.includes(+device?.deviceType))
        return DeviceTypeCategoryEnum.GPSTrackerWithCAN
      if (gpsTrackerWithBattery.includes(+device?.deviceType))
        return DeviceTypeCategoryEnum.GPSTrackerWithBattery
      if (assetTracker.includes(+device?.deviceType))
        return DeviceTypeCategoryEnum.Beacon
    }
    return null
  }, [device])

  const assertDeviceTypeCategory = (dev) => {
    if (+dev?.deviceType) {
      if (gpsTrackerDeviceTypes.includes(+dev?.deviceType))
        return DeviceTypeCategoryEnum.GPSTracker
      if (gpsTrackerWithCANDeviceTypes.includes(+dev?.deviceType))
        return DeviceTypeCategoryEnum.GPSTrackerWithCAN
      if (gpsTrackerWithBattery.includes(+dev?.deviceType))
        return DeviceTypeCategoryEnum.GPSTrackerWithBattery
      if (assetTracker.includes(+dev?.deviceType))
        return DeviceTypeCategoryEnum.Beacon
    }
    return null
  }

  const resolveInstallationSteps = async (device) => {
    if (device?.imei) {
      const savedSteps = (await getInstallationStepsByImei(
        device.imei
      )) as InstallationStep[]

      const deviceInfoStep = savedSteps.find(
        (step) => step.stepID === InstallationScreenEnum.DeviceInformation
      )
      const installationVerificationStep = savedSteps.find(
        (step) =>
          step.stepID === InstallationScreenEnum.InstallationVerification
      )
      const deviceCalibrationStep = savedSteps.find(
        (step) => step.stepID === InstallationScreenEnum.DeviceCalibration
      )
      const deviceHealthStep = savedSteps.find(
        (step) => step.stepID === InstallationScreenEnum.DeviceHealthValidation
      )

      // Needs some rework. Values fetched from the api, is being overwritten,
      // by data from the step itself.
      // Pass the step data to the generator instead, and use preexisting values from the API,
      // where applicable.
      const steps = [
        deviceInfoStep
          ? {
            ...deviceInfoStep,
            data: {
              ...generateDeviceInformationStep(device).data,
              ...deviceInfoStep.data,
            },
          }
          : generateDeviceInformationStep(device),
        installationVerificationStep
          ? {
            ...installationVerificationStep,
            data: {
              ...generateInstallationVerificationStep(
                device,
                installerAccount
              ).data,
              ...installationVerificationStep.data,
            },
          }
          : generateInstallationVerificationStep(device, installerAccount),
        deviceCalibrationStep
          ? {
            ...deviceCalibrationStep,
            data: {
              ...generateDeviceCalibrationStep(device).data,
            },
          }
          : generateDeviceCalibrationStep(device),
        deviceHealthStep
          ? {
            ...deviceHealthStep,
            data: {
              ...generateDeviceHealthStep(device).data,
              ...deviceHealthStep.data,
            },
          }
          : generateDeviceHealthStep(device),
      ]

      if (savedSteps.length === 0) {
        // Fresh item, load up defaults into firestore
        await Promise.all(
          [
            saveInstallationStepByImei(
              device?.imei,
              InstallationScreenEnum.DeviceInformation,
              steps.find(
                (s) => s.stepID === InstallationScreenEnum.DeviceInformation
              )
            ),
            ![
              DeviceTypeCategoryEnum.Beacon,
              DeviceTypeCategoryEnum.GPSTrackerWithBattery,
            ].includes(assertDeviceTypeCategory(device))
              ? saveInstallationStepByImei(
                device?.imei,
                InstallationScreenEnum.DeviceCalibration,
                steps.find(
                  (s) => s.stepID === InstallationScreenEnum.DeviceCalibration
                )
              )
              : null,
            saveInstallationStepByImei(
              device?.imei,
              InstallationScreenEnum.InstallationVerification,
              steps.find(
                (s) =>
                  s.stepID === InstallationScreenEnum.InstallationVerification
              )
            ),
            ![
              DeviceTypeCategoryEnum.Beacon,
              DeviceTypeCategoryEnum.GPSTrackerWithBattery,
            ].includes(assertDeviceTypeCategory(device))
              ? saveInstallationStepByImei(
                device?.imei,
                InstallationScreenEnum.DeviceHealthValidation,
                steps.find(
                  (s) =>
                    s.stepID === InstallationScreenEnum.DeviceHealthValidation
                )
              )
              : null,
          ].filter(Boolean)
        )
      }

      dispatch(
        InstallationActions(InstallationTypes.SetInstallationSteps, {
          steps,
        })
      )
      return
    }

    dispatch(
      InstallationActions(InstallationTypes.SetInstallationSteps, {
        steps: null,
      })
    )

    return
  }

  const onSelectDeviceHandler = async (item) => {
    if (
      item.stockStatus === DeviceStockStatusEnum.Stock &&
      account?.id &&
      item.unit_id &&
      !item.availableUnit
    ) {
      await saveFirebaseDevice(
        {
          installationStartedOnAccountID: account.id,
          imei: item.imei.toString(),
          stockStatus: DeviceStockStatusEnum.Awaiting,
          statusUpdated: +new Date(),
          unit_id: item.unit_id,
        },
        item.imei.toString()
      )
    }

    if (item.imei) {
      const accountVehicle =
        accountVehicles.find((dvc) => +dvc.imei === +item.imei) ?? {}
      const apiCalibReq = getVehicleCalibration(accountVehicle?.id ?? item.vehicleID)
      const apiFeatureReq = getVehicleFeatures(accountVehicle?.id ?? item.vehicleID)
      const [apiDeviceCalibration, apiFeatures] = await Promise.all([
        apiCalibReq,
        apiFeatureReq,
      ])
      const itemWithMeta = {
        ...item,
        ...accountVehicle,
        id: accountVehicle?.id ?? null,
        unit_id: item?.unit_id ?? accountVehicle?.unit_id,
        name: accountVehicle?.name ?? item.imei,
        deviceType: +item?.deviceType,
        deviceTypeName: item?.deviceTypeName,
      }

      if (
        apiDeviceCalibration.result === "OK" &&
        apiDeviceCalibration.vehicleCalibrationData
      ) {
        itemWithMeta.calibration = apiDeviceCalibration.vehicleCalibrationData
      }

      if (apiFeatures.result === "OK" && apiFeatures.features) {
        itemWithMeta.features = apiFeatures.features
      }

      await resolveInstallationSteps(itemWithMeta)

      await saveFirebaseDevice(
        {
          ...itemWithMeta,
        },
        item.imei.toString()
      )

      dispatch(
        InstallationActions(InstallationTypes.SetDevice, {
          device: itemWithMeta,
        })
      )
      dispatch(
        InstallationActions(
          InstallationTypes.SetScreen,
          InstallationScreenEnum.Tasks
        )
      )
      return
    }

    await resolveInstallationSteps(item)

    dispatch(InstallationActions(InstallationTypes.SetDevice, { device: item }))
    dispatch(
      InstallationActions(
        InstallationTypes.SetScreen,
        InstallationScreenEnum.Tasks
      )
    )
  }

  const mapVehicles = async () => {
    // First, fetch all devices from firebase and backend. Sets them in state.
    await getApiDevicesFromAccount(account?.id ?? null)
    // Next, fetch all vehicles from backend from the selected account. Sets them in state.
    if (account?.id) await getAccountApiVehicles(account.id)
  }

  // Whenever devices or vehicles are updated in state, map the vehicle data to a matching device
  useMemo(() => {
    if (account && devices && devices.length > 0) {
      const devicesByAccountID = devices
        .filter(
          (x) =>
            +x?.accountID === +account.id ||
            +x?.installationStartedOnAccountID === +account.id
        )
        .filter((x) => x.imei !== null)

      const reducedDevices: Device[] = devicesByAccountID.reduce((accDevices: Device[], currDevice) => {
        // First, check if this device already exists in accumulator
        const indexOfDevice = accDevices?.findIndex(
          (dev) => +dev.imei === +currDevice.imei
        )

        if (indexOfDevice > -1) {
          accDevices[indexOfDevice] = {
            ...accDevices[indexOfDevice],
            ...currDevice,
          }
          // Device found, add current device data to the same element
          return accDevices
        } else {
          // Find the vehicle matching device imei
          const vehicleData = accountVehicles.find(
            (vehicle) => +vehicle.imei === +currDevice.imei
          )

          if (!vehicleData || !currDevice.imei) {
            // E.g. imei missing from device data
            const vehicleDataByID = accountVehicles
              .filter((x) => x.deleted === false)
              .find((vehicle) => +vehicle?.id === +currDevice?.id)

            accDevices.push({
              ...vehicleDataByID,
              ...currDevice,
              id: vehicleDataByID?.id ?? currDevice?.id ?? null,
              unit_id: currDevice.unit_id,
              name: vehicleDataByID?.name ?? currDevice.imei,
              deviceType: +currDevice?.deviceType,
              deviceTypeName:
                currDevice?.deviceTypeName ??
                deviceTypeObject.find(
                  (x) => x.value === +currDevice?.deviceType
                )?.label,
            })
            return accDevices
          } else {
            accDevices.push({
              ...vehicleData,
              ...currDevice,
              id: vehicleData?.id ?? currDevice?.id ?? null,
              name: vehicleData?.name ?? currDevice.imei,
              unit_id: currDevice.unit_id,
              deviceType: +currDevice?.deviceType,
              deviceTypeName:
                currDevice?.deviceTypeName ??
                deviceTypeObject.find(
                  (x) => x.value === +currDevice?.deviceType
                )?.label,
            })
            return accDevices
          }
        }
      }, [])

      dispatch(
        InstallationActions(InstallationTypes.SetAccountDevices, {
          accountDevices: reducedDevices,
        })
      )
      return
    }

    dispatch(
      InstallationActions(InstallationTypes.SetAccountDevices, {
        accountDevices: [],
      })
    )
  }, [devices, accountVehicles])

  const setCurrentScreen = (screen: InstallationScreenEnum) => {
    dispatch(InstallationActions(InstallationTypes.SetScreen, screen))
  }

  return {
    getAccountApiVehicles,
    mapVehicles,
    getFotaDevicByIMEI,
    resolveInstallationSteps,
    deviceTypeCategory,
    assertDeviceTypeCategory,
    setCurrentScreen,
    onSelectDeviceHandler,
  }
}
