import React, { useContext, useEffect, useMemo, useState } from "react"

import PopOver from "app/PopOver"
import tw from "twin.macro"
import { InstallationContext } from "./context"
import { useLockBodyScroll } from "hooks/useLockBodyScroll"
import { DevicesContext } from "app/Devices/context"
import { DataList } from "app/DataList"
import { SearchContext } from "app/Search/context"
import {
  DeviceStockStatusEnum,
} from "app/Devices/types"
import { Icon, Button, ButtonGroup, Card, Tag, Loading } from "@clevertrack/shared"
import { SearchActions, SearchTypes } from "app/Search/actions"
import { InstallationActions, InstallationTypes } from "./actions"
import { useAccounts } from "app/Account/hooks"
import { useUser } from "app/User/hooks"
import { useInstallation } from "./hooks"
import format from "date-fns/format"
import { AccountsContext } from "app/Account/context"
import { FeatureComponent } from "app/FeatureComponent"
import { ApiAccountTypeEnum } from "app/Account/types"
import uniqBy from "lodash-es/uniqBy"
import { useTranslation } from "react-i18next"
import { DeviceSearchHeader } from "./DeviceSearch/Header"
import { useDeviceSearch } from "./DeviceSearch/hooks"

export const Installation: React.FC = ({ ...props }) => {
  const {
    state: { account },
    dispatch,
  } = useContext(InstallationContext)
  const {
    state: { account: installerAccount },
  } = useContext(AccountsContext)
  const {
    state: { results, query, suggestions },
    dispatch: searchDispatch,
  } = useContext(SearchContext)
  const {
    state: { devices },
  } = useContext(DevicesContext)
  useLockBodyScroll(true)
  const { assignedAccounts } = useAccounts()
  const [loading, setLoading] = useState(false)
  const { onSelectDeviceHandler, mapVehicles } = useInstallation()

  const { t } = useTranslation()
  const {
    installerStockDevices,
    installerAwaitingDevices,
    stockDevices,
    awaitingDevices,
    installedDevices,
    unverifiedDevices,
  } = useDeviceSearch()

  const { currentUserIsExternalFitter } = useUser()
  const [showStockDevices, setShowStockDevices] = useState(false)

  const alternateAccount = useMemo(() => {
    if (results.length === 0 && query.length > 0) {
      const findDevice = devices.find((dev) => dev.imei === query)
      if (findDevice) {
        return assignedAccounts.find(
          (acc) => acc.id === findDevice.accountID
        ) ?? installerAccount.id === findDevice.accountID
          ? installerAccount
          : null
      }
      return null
    }
    return null
  }, [devices, results, query, assignedAccounts])

  const switchAccount = async (account) => {
    dispatch(InstallationActions(InstallationTypes.SetAccount, { account }))
  }

  const onSelectNewAccountHandler = () => {
    dispatch(InstallationActions(InstallationTypes.ClearAccount))
    dispatch(
      InstallationActions(InstallationTypes.SetAccountDevices, {
        accountDevices: [],
      })
    )
  }

  useEffect(() => {
    const loadVehicles = async () => {
      if (account) {
        setLoading(true)
        await mapVehicles()
        setLoading(false)
      }
    }
    loadVehicles()
  }, [account])

  const onCancelHandler = () => {
    setShowStockDevices(false)
    searchDispatch(SearchActions(SearchTypes.ResetSearch))
  }

  const renderAlternateAccount = () => {
    if (alternateAccount && installerAccount) {
      const isOwnAccount = alternateAccount.id === installerAccount.id
      const foundDevice = devices.find((dev) => dev.imei === query)
      return isOwnAccount ? (
        <>
          <Card tw="leading-snug mt-8 text-xl m-0 p-4 py-8 bg-brand-gray-brand space-y-4">
            <span tw="text-lg m-0">
              {t("installation_device_found_on_own_stock")}
            </span>
            <strong tw="block">
              <span tw="block text-lg">IMEI: {foundDevice?.imei}</span>
              <span tw="block text-lg">
                {t("installation_device_type")}: {foundDevice?.deviceTypeName}
              </span>
            </strong>
            <Button
              variant="primary"
              size="sm"
              onClick={() => onSelectDeviceHandler(foundDevice)}
            >
              {t("installation_assign_device")} {account?.name}
            </Button>
          </Card>
        </>
      ) : (
        <>
          <p tw="text-xl">
            {t("installation_device_found_on_other_stock")} {query}
            {t("installation_found_on")} {alternateAccount.name}
          </p>
          <Button
            variant="default"
            size="sm"
            onClick={() => switchAccount(alternateAccount)}
          >
            {t("installation_switch_to")} {alternateAccount.name}
          </Button>
        </>
      )
    }
    return null
  }

  return (
    <PopOver
      fromBottom
      show={account !== null}
      zindex={2000}
      selector="#___dashboard-inner"
      tw="absolute shadow-none overflow-hidden"
    >
      <div tw="w-full h-full overflow-y-scroll bg-white">
        <header tw="p-8 pb-4 sticky top-0 bg-white z-50">
          <span tw="flex items-baseline justify-between">
            <span tw="block mb-2">{t("installation_for")}</span>
            {!currentUserIsExternalFitter && (
              <span
                tw="text-brand-500 flex items-center text-right whitespace-nowrap"
                onClick={onSelectNewAccountHandler}
              >
                <Icon icon="chevron-left" tw="w-4 h-4 mr-2" />
                <span tw="text-lg font-normal">
                  {t("installation_select_other_account")}
                </span>
              </span>
            )}
          </span>
          <span tw="text-2xl font-bold">
            <span tw="w-2/3">{account?.name}</span>
          </span>
          <DeviceSearchHeader />
        </header>
        <div id="deviceList">
          {!showStockDevices && (
            <>
              {stockDevices.length > 0 && (
                <>
                  <h4 tw="p-8 m-0 sticky top-52 bg-white z-50">
                    <span>{t("installation_stock")}</span>
                  </h4>
                  <DataList
                    items={stockDevices}
                    sort={["updated", "desc"]}
                    titleKey="name"
                    titleIcon="warehouse-alt"
                    titleIconStyling={tw`w-6 h-6 mr-4`}
                    titleStyling={tw`flex items-center text-brand-blue-50`}
                    descriptionKey={"note"}
                    secondaryDescriptionKey={["imei", "deviceTypeName"]}
                    secondaryDescriptionStyling={tw`text-lg`}
                    tertiaryDescriptionKey={"statusUpdated"}
                    tertiaryDescriptionKeyFormatFnc={(descriptionKey) => {
                      if (!descriptionKey) return null
                      return `${t("installation_stock_updated")} ${format(
                        new Date(descriptionKey),
                        `dd.MM.yyyy '${t("at")}' HH:mm`
                      )}`
                    }}
                    tertiaryDescriptionStyling={tw`text-lg`}
                    tertiaryDescriptionKeySeparator={","}
                    onItemSelect={onSelectDeviceHandler}
                  />
                </>
              )}
              {(awaitingDevices.length > 0 ||
                installerAwaitingDevices.length > 0) && (
                  <>
                    <h4 tw="p-8 m-0 sticky top-52 bg-white z-50">
                      {t("installation_in_progress")}
                    </h4>
                    <DataList
                      sort={["updated", "desc"]}
                      items={uniqBy(
                        [...awaitingDevices, ...installerAwaitingDevices],
                        "imei"
                      )}
                      titleKey="name"
                      titleIcon="exclamation-triangle"
                      titleIconStyling={tw`w-6 h-6 mr-4`}
                      titleStyling={tw`flex items-center text-brand-yellow-200`}
                      descriptionKey={"note"}
                      secondaryDescriptionKey={["imei", "deviceTypeName"]}
                      secondaryDescriptionStyling={tw`text-lg`}
                      tertiaryDescriptionKey={"statusUpdated"}
                      tertiaryDescriptionKeyFormatFnc={(descriptionKey) => {
                        if (!descriptionKey) return null
                        return `${t("installation_started")} ${format(
                          new Date(descriptionKey),
                          `dd.MM.yyyy '${t("at")}' HH:mm`
                        )}`
                      }}
                      tertiaryDescriptionStyling={tw`text-lg`}
                      tertiaryDescriptionKeySeparator={","}
                      onItemSelect={onSelectDeviceHandler}
                    />
                  </>
                )}
              {installedDevices.length > 0 && (
                <>
                  <h4 tw="p-8 m-0 sticky top-52 bg-white z-50">
                    {t("installation_installed")}
                  </h4>
                  <DataList
                    sort={["updated", "desc"]}
                    items={installedDevices}
                    titleKey="name"
                    titleIcon="check"
                    titleIconStyling={tw`w-6 h-6 mr-4`}
                    titleStyling={tw`flex items-center text-green-600`}
                    descriptionKey={"note"}
                    secondaryDescriptionKey={["imei", "deviceTypeName"]}
                    secondaryDescriptionStyling={tw`text-lg`}
                    tertiaryDescriptionKey={"statusUpdated"}
                    tertiaryDescriptionKeyFormatFnc={(descriptionKey) => {
                      if (!descriptionKey) return null
                      return `${t("installation_installed")} ${format(
                        new Date(descriptionKey),
                        `dd.MM.yyyy '${t("at")}' HH:mm`
                      )}`
                    }}
                    tertiaryDescriptionStyling={tw`text-lg`}
                    tertiaryDescriptionKeySeparator={","}
                    onItemSelect={onSelectDeviceHandler}
                  />
                </>
              )}
              {unverifiedDevices.length > 0 && (
                <>
                  <h4 tw="p-8 m-0 sticky top-52 bg-white z-50">
                    {t("installation_unverified")}
                  </h4>
                  <DataList
                    sort={["updated", "desc"]}
                    items={unverifiedDevices}
                    titleIcon="question-circle"
                    titleIconStyling={tw`w-6 h-6 mr-4`}
                    titleStyling={tw`flex items-center`}
                    titleKey="name"
                    descriptionKey={"note"}
                    secondaryDescriptionKey={["imei", "deviceTypeName"]}
                    secondaryDescriptionStyling={tw`text-lg`}
                    onItemSelect={onSelectDeviceHandler}
                  />
                </>
              )}
              {results.length === 0 &&
                suggestions.length === 0 &&
                query.length > 0 ? (
                <div tw="text-center p-8">
                  <p tw="text-2xl m-0">
                    {t("installation_device_not_found")} {account?.name}
                  </p>
                  {renderAlternateAccount()}
                </div>
              ) : null}
            </>
          )}
        </div>
        <FeatureComponent allowedAccountTypes={[ApiAccountTypeEnum.Installer]}>
          <ButtonGroup
            sticky="bottom"
            position="around"
            tw="px-4 bottom-0 bg-white"
          >
            <Button
              type="button"
              variant="primary"
              onClick={() => setShowStockDevices(true)}
            >
              <span tw="flex items-center">
                <span tw="text-xl">
                  {t("installation_add_unit_from_stock")}
                </span>
                <Icon icon="plus" tw="w-5 h-5 ml-1" />
              </span>
            </Button>
          </ButtonGroup>
        </FeatureComponent>
        <PopOver
          fromBottom
          show={showStockDevices}
          selector="#deviceList"
          zindex={2100}
          tw="absolute shadow-none overflow-hidden top-52 bottom-0"
        >
          <div tw="w-full h-full overflow-y-scroll bg-white ">
            {installerStockDevices.length > 0 && (
              <>
                <h4 tw="p-8 m-0 sticky top-0 bg-white z-50">
                  <span>{t("installation_in_progress_title")}</span>
                </h4>
                <DataList
                  sort={["updated", "desc"]}
                  items={installerStockDevices.filter(
                    (dev) => dev.stockStatus === DeviceStockStatusEnum.Awaiting
                  )}
                  titleKey="name"
                  titleIcon="exclamation-triangle"
                  titleIconStyling={tw`w-6 h-6 mr-4`}
                  titleStyling={tw`flex items-center text-brand-yellow-200`}
                  descriptionKey={"note"}
                  secondaryDescriptionKey={[
                    "imei",
                    "deviceTypeName",
                    "installationStartedOnAccountID",
                  ]}
                  secondaryDescriptionStyling={tw`text-lg`}
                  secondaryDescriptionKeyFormatFnc={(device, descriptionKey) => {
                    if (descriptionKey === "installationStartedOnAccountID") {
                      return (
                        <Tag
                          tw="bg-brand-yellow-200 text-brand-black-base"
                          show
                        >
                          {t("installation_started_on")}
                          {
                            assignedAccounts.find((acc) => +acc.id === +device[descriptionKey])
                              ?.name
                          }
                          '
                        </Tag>
                      )
                    }
                    return device[descriptionKey]
                  }}
                  tertiaryDescriptionKey={"statusUpdated"}
                  tertiaryDescriptionKeyFormatFnc={(descriptionKey) => {
                    if (!descriptionKey) return null
                    return `${t("installation_started")} ${format(
                      new Date(descriptionKey),
                      `dd.MM.yyyy '${t("at")}' HH:mm`
                    )}`
                  }}
                  tertiaryDescriptionStyling={tw`text-lg`}
                  tertiaryDescriptionKeySeparator={","}
                  onItemSelect={onSelectDeviceHandler}
                />
                <h4 tw="p-8 m-0 sticky top-0 bg-white z-50">
                  <span>{t("installation_stock_2")}</span>
                </h4>
                <DataList
                  items={installerStockDevices.filter(
                    (dev) => dev.stockStatus === DeviceStockStatusEnum.Stock
                  )}
                  sort={["updated", "desc"]}
                  titleKey="name"
                  titleIcon="warehouse-alt"
                  titleIconStyling={tw`w-6 h-6 mr-4`}
                  titleStyling={tw`flex items-center text-brand-blue-50`}
                  descriptionKey={["note", "defectiveReason"]}
                  descriptionKeySeparator=""
                  descriptionKeyFormatFnc={(device, descriptionKey) => {
                    if (device.defectiveUnit && descriptionKey === "defectiveReason") {
                      return `${t("installation_device_defective")} (${device.defectiveReason})`
                    } else if (!device.defectiveUnit) {
                      return device[descriptionKey] || null
                    }
                    return null
                  }}
                  secondaryDescriptionKey={["imei", "deviceTypeName"]}
                  secondaryDescriptionStyling={tw`text-lg`}
                  tertiaryDescriptionKey={"statusUpdated"}
                  tertiaryDescriptionKeyFormatFnc={(descriptionKey) => {
                    if (!descriptionKey) return null
                    return `${t("installation_stock_updated")} ${format(
                      new Date(descriptionKey),
                      `dd.MM.yyyy '${t("at")}' HH:mm`
                    )}`
                  }}
                  tertiaryDescriptionStyling={tw`text-lg`}
                  tertiaryDescriptionKeySeparator={","}
                  onItemSelect={onSelectDeviceHandler}
                />
              </>
            )}
            <ButtonGroup
              sticky="bottom"
              position="around"
              tw="px-4 bottom-0 bg-white"
            >
              <Button type="button" variant="cancel" onClick={onCancelHandler}>
                <span tw="flex items-center font-normal">
                  <Icon icon="chevron-left" tw="w-6 h-6 mr-2" />
                  <span tw="text-xl">{t("installation_back")}</span>
                </span>
              </Button>
            </ButtonGroup>
          </div>
        </PopOver>
      </div>
    </PopOver>
  )
}
