import { Account } from "app/Account/types"
import { UserTypeEnum } from "./types"
import { StringDictionary } from "lib/global.types"
import { accountFeatureOptions } from "app/Account/helper"

export const userType = [
  {
    label: "Admin",
    value: UserTypeEnum.ADMIN,
  },
  {
    label: "Montør",
    value: UserTypeEnum.FITTER,
  },
  {
    label: "Forhandler",
    value: UserTypeEnum.RETAILER,
  },
  {
    label: "Bruger",
    value: UserTypeEnum.USER,
  },
  {
    label: "CMS Admin",
    value: UserTypeEnum.CMSADMIN,
  },
]

export const userFilterGroups = [
  {
    dataKey: "userTypes",
    label: "Brugertype",
    options: userType,
  },
]

export const DEFAULTFEATURES: StringDictionary<string> = {
  START: "Kort",
  SERVICE: "Service",
  HISTORY: "Historik",
}

export const DEFAULTFUNCTIONS: StringDictionary<string> = {
  ALARMS: "Alarmopsætning",
  GROUPS: "Gruppeopsætning",
  ZONES: "Zoneopsætning",
  DEVICESETTINGS: "Enhedsindstillinger",
}

export const resolveAvailableFeatures = (account: Account) => {
  const companyFeatures = account.features
    ? Object.values(account.features).map((x) => ({
        label: x,
        value: x,
      }))
    : []
  const companyFunctions = account.functions
    ? Object.values(account.functions).map((x) => ({
        label: x,
        value: x,
      }))
    : []
  return [
    {
      label: DEFAULTFEATURES.START,
      value: DEFAULTFEATURES.START,
    },
    {
      label: DEFAULTFEATURES.SERVICE,
      value: DEFAULTFEATURES.SERVICE,
    },
    {
      label: DEFAULTFEATURES.HISTORY,
      value: DEFAULTFEATURES.HISTORY,
    },
    {
      label: DEFAULTFUNCTIONS.ALARMS,
      value: DEFAULTFUNCTIONS.ALARMS,
    },
    {
      label: DEFAULTFUNCTIONS.GROUPS,
      value: DEFAULTFUNCTIONS.GROUPS,
    },
    {
      label: DEFAULTFUNCTIONS.ZONES,
      value: DEFAULTFUNCTIONS.ZONES,
    },
    {
      label: DEFAULTFUNCTIONS.DEVICESETTINGS,
      value: DEFAULTFUNCTIONS.DEVICESETTINGS,
    },
    ...companyFeatures,
    ...companyFunctions,
  ]
}

export const generateImpersonationUrl = (user, hub = false) => {
  const strBuilder = [`https://`]
  switch (process.env.GATSBY_ACTIVE_ENV) {
    case "production":
      strBuilder.push(
        `${hub ? `hub` : `app`}.clevertrack.dk?token=${user.token}&installer=true`
      )
      break
    case "staging":
      strBuilder.push(
        `${hub ? `hub` : `app`}.staging.clevertrack.dk?token=${user.token}&installer=true`
      )
      break
    case "development":
    default:
      strBuilder.push(
        `${hub ? `localhost:8002` : `localhost:8001`}?token=${user.token}&installer=true`
      )
  }
  return strBuilder.join("")
}
