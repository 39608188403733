import React from "react"
import "twin.macro"
import Checkbox from "lib/Checkbox"

interface RadioOption {
  value: any
  label: string
  onChange: () => void
}

interface RadioButtonGroupProps {
  options: RadioOption[]
  selectedValue: any
}

export const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
  options,
  selectedValue
}) => {
  return (
    <div tw="flex items-center space-y-0 space-x-8 mt-6 mb-12">
      {options.map((option, index) => (
        <Checkbox
          key={index}
          checked={selectedValue === option.value}
          onChange={option.onChange}
          checkboxAppearance="radio"
        >
          {option.label}
        </Checkbox>
      ))}
    </div>
  )
}
