import React, { useContext, useMemo, useState } from "react"
import "twin.macro"
import { AccountsContext } from "app/Account/context"
import { UserContext } from "app/User/context"
import { MainContainer } from "app/MainContainer"
import { DataTable } from "app/DataTable"
import { useAccount } from "app/Account/hooks"
import { UserType } from "app/DataTable/components/UserType"
import { format } from "date-fns"
import { userFilterGroups } from "app/User/helper"
import { CollectionFilter } from "utils/collection/filter"
import { Filter } from "app/DataTable/Filter"
import { SearchProvider } from "app/Search/context"
import { navigate } from "gatsby"
import { IconSizeEnum } from "lib/Icon"
import { Button, Icon } from "@clevertrack/shared"

export const AccountUsers: React.FC = ({ ...props }) => {
  const {
    state: { viewAccount },
  } = useContext(AccountsContext)
  const {
    state: { users },
  } = useContext(UserContext)

  const [selectedFilters, setSelectedFilters] = useState<CollectionFilter[]>([])

  useAccount()

  const accountUsers = useMemo(() => {
    if (viewAccount) {
      return users
        .filter((user) => +user.account_id === +viewAccount.id)
        .map((user) => ({
          ...user,
          name: [user.firstName, user.lastName].join(" "),
        }))
    }
  }, [users, viewAccount])

  return (
    <MainContainer
      header={
        <>
          <h2>{viewAccount?.name}: Brugere</h2>
          <Button
            type="button"
            variant="primary"
            icon="right"
            size="sm"
            tw="ml-8 my-0"
            onClick={() => navigate(`/app/users/create/${viewAccount?.id}`)}
          >
            <span>Opret ny bruger</span>
            <Icon icon="plus" size={IconSizeEnum.SM} />
          </Button>
        </>
      }
    >
      <SearchProvider>
        <Filter
          filterGroups={userFilterGroups}
          onFilterUpdated={(filter) => setSelectedFilters(filter)}
        />
        <DataTable
          columns={[
            { key: "id", title: "ID" },
            { key: "name", title: "Navn" },
            { key: "username", title: "Brugernavn" },
            {
              key: "userTypes",
              title: "Type",
              component: UserType,
            },
            {
              key: "last_login",
              title: "Seneste login",
              formatData: (date) => format(new Date(date), "dd-MM-yyyy HH:mm"),
            },
          ]}
          filters={selectedFilters}
          dataset={accountUsers}
          columnConfig="4rem 1fr 1fr 0.5fr 1fr"
          searchKeys={["username", "name"]}
          onRowSelect={({ id }) => navigate(`/app/users/${id}`)}
          tw="px-8"
          compactSettings={{
            titleKey: "name",
            descriptionKey: ["id", "userTypes"],
            secondaryDescriptionKey: ["email", "phoneNumber"],
            onItemSelect: ({ id }) => navigate(`/app/users/${id}`),
          }}
        />
      </SearchProvider>
    </MainContainer>
  )
}
