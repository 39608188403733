import React from "react"
import "twin.macro"
import { useTranslation } from "react-i18next"
import Accordion from "lib/Accordion"
import { ExtendedBoolean, MainSwitchBypassBoolean } from "../../../types"
import { RadioButtonGroup } from "./RadioButtonGroup"

interface PowerSourceSectionProps {
  currentModel: any
  updateModel: (key: string, value: any) => void
  setCurrentModel: (model: any) => void
}

export const PowerSourceSection: React.FC<PowerSourceSectionProps> = ({
  currentModel,
  updateModel,
  setCurrentModel,
}) => {
  const { t } = useTranslation()

  const handleMainSwitchChange = (value: ExtendedBoolean) => {
    if (value === ExtendedBoolean.YES) {
      updateModel("isMainSwitchPresent", value)
      updateModel("isDeviceBypassingMainSwitch", null)
    } else {
      const {
        isMainSwitchControlledByGround,
        isDeviceBypassingMainSwitch,
        isDeviceMountedWithIgnitionWireRelay,
        ...newModel
      } = currentModel
      setCurrentModel({
        ...newModel,
        isMainSwitchPresent: value,
      })
    }
  }

  const handleBypassChange = (value: MainSwitchBypassBoolean) => {
    if (value === MainSwitchBypassBoolean.YES || value === MainSwitchBypassBoolean.POWERBYPASSED) {
      updateModel("isDeviceBypassingMainSwitch", value)
      updateModel("isMainSwitchControlledByGround", null)
    } else {
      const { isMainSwitchControlledByGround, ...newModel } = currentModel
      setCurrentModel({
        ...newModel,
        isDeviceBypassingMainSwitch: value,
      })
    }
  }

  const handleGroundControlChange = (value: boolean) => {
    if (value) {
      updateModel("isMainSwitchControlledByGround", value)
      updateModel("isDeviceMountedWithIgnitionWireRelay", null)
    } else {
      const { isDeviceMountedWithIgnitionWireRelay, ...newModel } = currentModel
      setCurrentModel({
        ...newModel,
        isMainSwitchControlledByGround: value,
      })
    }
  }

  return (
    <>
      <h4>{t("installation_power_source")}</h4>
      <div>
        <span>{t("installation_main_switch")}</span>
        <RadioButtonGroup
          options={[
            {
              value: ExtendedBoolean.YES,
              label: t("installation_yes"),
              onChange: () => handleMainSwitchChange(ExtendedBoolean.YES),
            },
            {
              value: ExtendedBoolean.NO,
              label: t("installation_no"),
              onChange: () => handleMainSwitchChange(ExtendedBoolean.NO),
            },
            {
              value: ExtendedBoolean.UNKNOWN,
              label: t("installation_unknown"),
              onChange: () => handleMainSwitchChange(ExtendedBoolean.UNKNOWN),
            },
          ]}
          selectedValue={currentModel?.isMainSwitchPresent}
        />

        <Accordion toggled={currentModel?.isMainSwitchPresent === ExtendedBoolean.YES}>
          <span>{t("installation_main_switch_description")}</span>
          <RadioButtonGroup
            options={[
              {
                value: MainSwitchBypassBoolean.YES,
                label: t("installation_yes"),
                onChange: () => handleBypassChange(MainSwitchBypassBoolean.YES),
              },
              {
                value: MainSwitchBypassBoolean.NO,
                label: t("installation_no"),
                onChange: () => handleBypassChange(MainSwitchBypassBoolean.NO),
              },
              {
                value: MainSwitchBypassBoolean.POWERBYPASSED,
                label: t("installation_power_bypassed"),
                onChange: () => handleBypassChange(MainSwitchBypassBoolean.POWERBYPASSED),
              },
            ]}
            selectedValue={currentModel?.isDeviceBypassingMainSwitch}
          />
        </Accordion>

        <Accordion
          toggled={[
            MainSwitchBypassBoolean.POWERBYPASSED,
            MainSwitchBypassBoolean.YES,
          ].includes(currentModel?.isDeviceBypassingMainSwitch)}
        >
          <span>{t("installation_main_switch_controlled_by_ground")}</span>
          <small tw="block opacity-60 mt-2 text-xl">
            {t("installation_main_switch_controlled_by_ground_description")}
          </small>
          <RadioButtonGroup
            options={[
              {
                value: true,
                label: t("installation_yes"),
                onChange: () => handleGroundControlChange(true),
              },
              {
                value: false,
                label: t("installation_no"),
                onChange: () => handleGroundControlChange(false),
              },
            ]}
            selectedValue={currentModel?.isMainSwitchControlledByGround}
          />

          <Accordion toggled={currentModel?.isMainSwitchControlledByGround === true}>
            <span>{t("installation_ignition_wire_relay_description")}</span>
            <RadioButtonGroup
              options={[
                {
                  value: true,
                  label: t("installation_yes"),
                  onChange: () => updateModel("isDeviceMountedWithIgnitionWireRelay", true),
                },
                {
                  value: false,
                  label: t("installation_no"),
                  onChange: () => updateModel("isDeviceMountedWithIgnitionWireRelay", false),
                },
              ]}
              selectedValue={currentModel?.isDeviceMountedWithIgnitionWireRelay}
            />
          </Accordion>
        </Accordion>
      </div>
    </>
  )
}
