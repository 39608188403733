import React, { useContext, useMemo } from "react"
import styled from "styled-components"
import tw from "twin.macro"

import { AccountsContext } from "app/Account/context"
import { DonutMetric } from "app/KPI/Donut"
import { InstallerHome } from "./Installer"
import { UserContext } from "app/User/context"
import { useDataInsight } from "./hooks"
import { useAccount } from "app/Account/hooks"
import { Loading } from "@clevertrack/shared"
import { useTranslation } from "react-i18next"

const StyledContent = styled.div`
  ${(props) => props.theme.media.tablet_landscape_up`
    display: grid;
    grid-template-columns: 1fr 1fr;
  `}
`

const StyledDashboard = styled.section`
  display: flex;
  flex-direction: column;
  row-gap: 4rem;
  ${tw`p-8`}

  h2 {
    margin: 0;
    font-family: ${(props) => props.theme.fontStacks.light};
    line-height: 1;
    ${tw`m-0 text-2xl`}
  }

  .card {
    padding-right: 6rem;
  }
`

export const Home: React.FC = ({ ...props }) => {
  const {
    state: { account },
  } = useContext(AccountsContext)
  const {
    state: { user },
  } = useContext(UserContext)
  useAccount(user?.companyID)

  const {
    accountTypes,
    deviceTypes,
    devicesByAccount,
    generateDonutDataSeries,
  } = useDataInsight()
  const { t } = useTranslation()

  const isLoadedFromWebApp = useMemo(() => {
    if (typeof window !== "undefined") {
      return [
        "https://localhost:8001/",
        "https://app.dev.clevertrack.dk/",
        "https://app.staging.clevertrack.dk/",
        "https://app.clevertrack.dk/",
      ].includes(document.referrer)
    }
  }, [])

  if (!account) return <Loading loadingText={t("home_loading_text")} />

  return user?.is_cms_admin && !isLoadedFromWebApp ? (
    <div tw="h-lvh bg-brand-gray-brand lg:bg-white">
      <header tw="bg-white p-8 border-solid border-0 border-b border-brand-gray-light">
        <h2 tw="m-0">Hej {user.firstName}</h2>
        <p tw="m-0">Her er dit overblik</p>
      </header>

      <StyledContent>
        <StyledDashboard>
          <DonutMetric
            title="Kontotyper"
            series={generateDonutDataSeries(accountTypes)}
            id="clients"
          />
          <DonutMetric
            title="Enhedstyper (flere end 200)"
            series={generateDonutDataSeries(deviceTypes)}
            id="devices"
          />
          <DonutMetric
            title="Enheder pr. konto (flere end 150)"
            series={generateDonutDataSeries(devicesByAccount)}
            id="devicesByAccount"
          />
        </StyledDashboard>
      </StyledContent>
    </div>
  ) : (
    <InstallerHome />
  )
}
