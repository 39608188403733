import React, { useEffect, useRef } from "react"
import { Spring } from "react-spring/renderprops"
import PropTypes from "prop-types"
import styled from "styled-components"
import { UserAgentEnum, useUserAgent } from "hooks/useUserAgent"
import { useWhyDidYouUpdate } from "hooks/useWhyDidYouUpdate"

const StyledAccordion = styled.div`
  overflow: hidden;
`

const StyledCSSAccordion = styled.div`
  max-height: ${(props) =>
    props.toggled ? `${props.maxHeight ? props.maxHeight : "500px"}` : `0`};
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  transition: max-height
    ${(props) => (props.toggled ? props.transitionDuration : `0.2s`)} ease-out;
`

function Accordion({
  children,
  toggled,
  onRest,
  onStart,
  forwardRef,
  maxHeight = "300px",
  transitionDuration = "0.4s",
  ...rest
}) {
  return (
    <Spring
      from={{ height: 0 }}
      to={{ height: toggled ? "auto" : 0 }}
      onRest={onRest}
      onStart={onStart}
      config={{
        tension: 500,
        friction: 50,
      }}
    >
      {(styleProps) => (
        <StyledAccordion style={styleProps} ref={forwardRef} {...rest}>
          {children}
        </StyledAccordion>
      )}
    </Spring>
  )
}

export default Accordion

Accordion.defaultProps = {
  toggled: false,
}

Accordion.propTypes = {
  children: PropTypes.node.isRequired,
  toggled: PropTypes.bool,
}
