import React, { useContext } from "react"
import { InstallationContext } from "app/Installation/context"
import tw from "twin.macro"
import styled from "styled-components"
import { Form, Icon } from "@clevertrack/shared"
import { InstallationScreenEnum } from "app/Installation/types"
import { ContentContainer } from "app/PopOver/ContentContainer"
import { useInstallation } from "app/Installation/hooks"
import { useTranslation } from "react-i18next"
const StyledHeader = styled.header`
  ${tw`p-4 px-8 text-center border border-solid border-0 border-b border-brand-gray-brand sticky top-0 z-80 bg-white`}
  font-family: ${(props) => props.theme.fontStacks.subheading};
`

const StyledDeviceHeader = styled.header`
  ${tw`p-4 px-8 border border-solid border-0 border-b border-brand-gray-brand`}
`

export const StyledForm = styled(Form)`
  display: grid;
  grid-template-rows: 1fr auto;

  ${(props) => props.theme.media.tablet_portrait_up`
    display: block;
  `}
`

export const StyledPopoverContentContainer = styled(ContentContainer)`
  ${tw`bg-white`}
  display: grid;
  grid-template-rows: auto auto auto 1fr;
`

type FlowHeaderType = {
  includeBackLink: boolean
  name?: string
}

export const FlowHeader: React.FC<FlowHeaderType> = ({
  includeBackLink = false,
  name,
  ...props
}) => {
  const {
    state: { account, device },
    dispatch,
  } = useContext(InstallationContext)
  const { t } = useTranslation()
  if (account) {
    return (
      <>
        <StyledHeader>
          <span>{account.name}</span>
        </StyledHeader>
        {device && (
          <StyledDeviceHeader>
            <div tw="flex items-center justify-between">
              {/* <span tw="block text-lg pb-2 mt-4">Enhedens navn</span> */}
              <span tw="flex flex-col">
                <span tw="flex items-center justify-between">
                  <span tw="text-2xl font-bold">
                    {device.name ??
                      device.imei ??
                      t("installation_unknown_unit")}
                  </span>
                </span>
                {device.note !== "" && (
                  <span tw="text-xl opacity-60 mt-2">{device.note}</span>
                )}
                {/* <span tw="text-lg">Valgt IMEI og type</span> */}
                <span tw="mt-2">
                  <span tw="flex items-center">
                    <span tw="text-lg opacity-60">
                      {device.imei ?? t("installation_imei_missing")}
                    </span>
                    <span tw="mx-1 opacity-40">-</span>
                    <span tw="text-lg opacity-60">
                      {device.deviceTypeName ??
                        t("installation_device_type_missing")}
                    </span>
                  </span>
                </span>
              </span>
            </div>
          </StyledDeviceHeader>
        )}
      </>
    )
  }
  return null
}

type ScreenHeaderType = {
  backLinkScreen?: InstallationScreenEnum
}

export const ScreenHeader: React.FC<ScreenHeaderType> = ({
  backLinkScreen,
  children,
  ...props
}) => {
  const { setCurrentScreen } = useInstallation()

  return (
    <div tw="px-8 pt-6 pb-2 flex items-center justify-between" {...props}>
      {children}
      {backLinkScreen && (
        <span
          tw="text-brand-500 flex items-center"
          onClick={() => setCurrentScreen(backLinkScreen)}
        >
          <Icon icon="chevron-left" tw="w-4 h-4 mr-2" />
          <span tw="text-xl font-normal">Tilbage</span>
        </span>
      )}
    </div>
  )
}
