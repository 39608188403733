import React, { useContext, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { Button, Dropdown, Icon, Tag } from "@clevertrack/shared"
import { Select } from "lib/Select"
import { CollectionFilter } from "utils/collection/filter"
import { FilterGroup, FilterGroups } from "./types"
import { SearchContext } from "app/Search/context"
import { SearchActions, SearchTypes } from "app/Search/actions"
import { SearchInput } from "app/Search/SearchInput"
import { IconSizeEnum } from "lib/Icon"
import { useClickAway } from "react-use"

const StyledFilterComponent = styled.div`
  ${tw`relative z-50 p-4 px-8 border-0 border-b border-solid border-brand-gray-brand`}

  .selectedFilters {
    ${tw`py-4 w-full whitespace-nowrap overflow-x-scroll space-x-4 lg:(flex items-center ml-8 overflow-x-auto)`}
  }

  .selectedFilter {
    ${tw`p-2 pr-12 inline-block bg-brand-secondary text-white relative lg:(flex items-center)`}

    > span {
      &.deselect {
        width: 2rem;
        ${tw`absolute flex justify-center pr-0 right-0 top-0 bottom-0 transition-all cursor-pointer flex items-center hover:bg-brand-red-400`}
        svg {
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }
`

type FilterProps = {
  onFilterUpdated: (filter: CollectionFilter[]) => void
  filterGroups: FilterGroups
  dataset: unknown[]
  filterByLabel?: boolean
  freetextPlaceholder?: string
  primaryFilter?: FilterGroup
}

export const Filter: React.FC<FilterProps> = ({
  filterGroups,
  filterByLabel = false,
  dataset = [],
  onFilterUpdated,
  freetextPlaceholder,
  primaryFilter,
  children,
  ...props
}) => {
  const {
    state: { query },
    dispatch,
  } = useContext(SearchContext)
  const [selectedFilters, setSelectedFilters] = useState<CollectionFilter[]>([])
  const [toggleFilters, setToggleFilters] = useState<boolean>(false)
  const ref = useRef(null)
  useClickAway(ref, () => setToggleFilters(false))

  // Determine which filter to use as primary
  const effectivePrimaryFilter =
    primaryFilter || (filterGroups.length > 0 ? filterGroups[0] : undefined)
  // Remove the primary filter from the remaining filters if it was taken from filterGroups
  const remainingFilters = primaryFilter ? filterGroups : filterGroups.slice(1)

  // Helper to get currently selected values for a filter group
  const getSelectedValuesForGroup = (dataKey: string) => {
    return selectedFilters
      .filter((filter) => filter.key === dataKey)
      .map((filter) => ({
        label: filter.labelValue,
        value: filter.value,
      }))
  }

  const onSelectHandler = (val, dataKey, remove = false) => {
    // Val is an array of options
    const allGroups = effectivePrimaryFilter
      ? [effectivePrimaryFilter, ...remainingFilters]
      : remainingFilters
    const group = allGroups.find((x) => x?.dataKey === dataKey)

    if (group) {
      if (remove) {
        // Remove the specific filter that matches both the dataKey and value
        setSelectedFilters((prev) =>
          prev.filter(
            (filter) =>
              !(
                filter.key === dataKey &&
                filter.value === (filterByLabel ? val[0].label : val[0].value)
              )
          )
        )
        return
      }

      // Create new filters from selected values
      const newFilters: CollectionFilter[] = val.map((v) => {
        return {
          key: group.dataKey,
          group: group.label,
          label: `${group.label}: ${v.label}`,
          labelValue: v.label,
          value: filterByLabel ? v.label : v.value,
        }
      })

      // If val is empty, remove all filters for this group
      if (val.length === 0) {
        setSelectedFilters((prev) =>
          prev.filter((filter) => filter.key !== dataKey)
        )
        return
      }

      // Get existing values for other groups
      setSelectedFilters((prev) => {
        const otherGroupFilters = prev.filter(
          (filter) => filter.key !== dataKey
        )
        return [...otherGroupFilters, ...newFilters]
      })
    }
  }

  useEffect(() => {
    if (onFilterUpdated) onFilterUpdated(selectedFilters)
  }, [selectedFilters])

  const onInputChange = (q) => {
    dispatch(SearchActions(SearchTypes.SetQuery, { query: q }))
  }

  const onResetFreetext = () => {
    dispatch(SearchActions(SearchTypes.ResetSearch))
  }

  return (
    <StyledFilterComponent {...props}>
      <div tw="space-x-6 flex w-full">
        <div tw="w-1/6">
          <SearchInput
            query={query}
            placeholder={freetextPlaceholder || "Søg"}
            onChange={onInputChange}
            onReset={onResetFreetext}
            tw="p-4 text-xl"
          />
        </div>
        <div tw="w-1/6">
          {effectivePrimaryFilter && (
            <Select
              placeholder={effectivePrimaryFilter.label}
              tw="bg-brand-gray-brand"
              useCheckbox
              hideSelectedOptions={false}
              controlShouldRenderValue={false}
              onChange={(val) =>
                onSelectHandler(val, effectivePrimaryFilter.dataKey)
              }
              value={getSelectedValuesForGroup(effectivePrimaryFilter.dataKey)}
              isMulti
              closeMenuOnSelect={false}
              options={effectivePrimaryFilter.options}
              noOptionsMessage={() => "Ingen matches"}
            />
          )}
        </div>
        {remainingFilters.length > 0 && (
          <div tw="relative w-1/4">
            <Button
              icon="left"
              variant="transparent"
              tw="text-brand-gray-base bg-transparent font-normal p-4 m-0 hover:bg-brand-gray-brand"
              onClick={() => setToggleFilters((prev) => !prev)}
            >
              <Icon icon="filter-solid" size={IconSizeEnum.SM} />
              <span tw="flex items-center">Flere filtre</span>
            </Button>

            <Dropdown
              toggled={toggleFilters}
              zindex={200}
              tw="absolute bg-white w-3/4 h-full left-0"
            >
              <div tw="bg-white w-full h-full p-8" ref={ref}>
                <div tw="space-y-8 relative">
                  {remainingFilters.map((filterGroup, index) => {
                    return (
                      <div
                        tw="w-full text-xl relative"
                        key={`filter_group_${filterGroup.dataKey}`}
                      >
                        <Select
                          placeholder={filterGroup.label}
                          tw="bg-brand-gray-brand"
                          useCheckbox
                          hideSelectedOptions={false}
                          controlShouldRenderValue={false}
                          onChange={(val) =>
                            onSelectHandler(val, filterGroup.dataKey)
                          }
                          value={getSelectedValuesForGroup(filterGroup.dataKey)}
                          options={filterGroup.options}
                          isMulti
                          closeMenuOnSelect={false}
                          noOptionsMessage={() => "Ingen matches"}
                          style={{ zIndex: 10 - index }}
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
            </Dropdown>
          </div>
        )}
      </div>
      {selectedFilters.length > 0 && (
        <div tw="flex flex-wrap items-center pt-6 pb-4 space-x-4">
          <span>Filtreret efter: </span>
          {selectedFilters.map((filter) => (
            <Tag
              key={`selected_filter_${filter.key}_${filter.value}`}
              onClick={() =>
                onSelectHandler(
                  [{ label: filter.labelValue, value: filter.value }],
                  filter.key,
                  true
                )
              }
              show
              tw="text-lg"
            >
              {filter.label}
            </Tag>
          ))}
          <Button
            variant="transparent"
            tw="m-0 p-0 text-sm text-brand-gray-base font-normal hover:text-brand-red-600"
            onClick={(e) => {
              e.stopPropagation()
              setSelectedFilters([])
              if (onFilterUpdated) onFilterUpdated([])
            }}
          >
            Ryd alle filtre
          </Button>
        </div>
      )}
    </StyledFilterComponent>
  )
}
