import { FilterGroups } from "app/DataTable/types"
import {
  Category,
  DeviceStockStatusEnum,
  DisplayKey,
  DisplayKeyEnum,
} from "./types"

export const deviceTypeObject = [
  { label: "FMB001 (biler)", value: 15 },
  { label: "FMB002 (biler)", value: 6 },
  { label: "FMB003 (biler)", value: 7 },
  { label: "FMB010 (biler)", value: 3 },
  { label: "FMB125", value: 19 },
  { label: "MB125 (maskiner m. fuel flow måler)", value: 44 },
  { label: "FMB130 (biler)", value: 11 },
  { label: "FMB140 ALL-CAN (biler u. brændstofdata)", value: 36 },
  { label: "FMB140 ALL-CAN (biler)", value: 21 },
  { label: "FMB140 ALL-CAN (el-biler)", value: 20 },
  { label: "FMB140 ALL-CAN (maskiner m. AEMP)", value: 34 },
  { label: "FMB140 ALL-CAN (maskiner m. trykføler + AEMP)", value: 39 },
  { label: "FMB140 ALL-CAN (maskiner m. trykføler)", value: 28 },
  { label: "FMB140 ALL-CAN (maskiner u. brændstofdata)", value: 38 },
  { label: "FMB140 ALL-CAN (maskiner u. CAN)", value: 31 },
  { label: "FMB140 ALL-CAN (maskiner)", value: 8 },
  { label: "FMB140 LV-CAN (biler u. brændstofdata)", value: 35 },
  { label: "FMB140 LV-CAN (biler)", value: 18 },
  { label: "FMB140 LV-CAN (maskiner m. AEMP)", value: 33 },
  { label: "FMB140 LV-CAN (maskiner m. trykføler + AEMP)", value: 41 },
  { label: "FMB140 LV-CAN (maskiner m. trykføler)", value: 27 },
  { label: "FMB140 LV-CAN (maskiner)", value: 24 },
  { label: "FMB204 (biler)", value: 37 },
  { label: "FMB204 (maskiner m. trykføler)", value: 29 },
  { label: "FMB204 (maskiner/materiel)", value: 1 },
  { label: "FMB204 (materielscanner)", value: 25 },
  { label: "FMB204 (trailere)", value: 30 },
  { label: "FMB204 m. AEMP (maskiner)", value: 32 },
  { label: "FMB240 ALL-CAN (maskiner)", value: 46 },
  { label: "FMB240 LV-CAN (biler)", value: 45 },
  { label: "FMB640 (biler)", value: 23 },
  { label: "FMC150 (maskiner)", value: 48 },
  { label: "FMC234 (maskiner m. trykføler)", value: 49 },
  { label: "FMC234 (maskiner)", value: 47 },
  { label: "Probe", value: 10 },
  { label: "Probe Mini", value: 14 },
  { label: "Probe Plus", value: 43 },
  { label: "Probe Ultra", value: 13 },
  { label: "TAT100", value: 17 },
  { label: "FMC003", value: 51 },
  { label: "FMC150 ext", value: 52 },
  { label: "FMC130", value: 53 },
  { label: "FMC234", value: 54 },
  { label: "FMC250 ext", value: 55 },
  { label: "FMC150", value: 56 },
  { label: "FMC250", value: 57 },
  { label: "TAT140", value: 58 },
  { label: "FMC125", value: 59 },
  { label: "FMB001", value: 60 },
  { label: "FMB003", value: 61 },
  { label: "FMB130", value: 62 },
  { label: "FMB140 LV-CAN", value: 63 },
  { label: "FMB140 ALL-CAN", value: 64 },
  { label: "FMB204", value: 65 },
  { label: "FMB240 LV-CAN", value: 66 },
  { label: "FMB240 ALL-CAN", value: 67 },
  { label: "FMB640", value: 68 },
]

export const deviceStockStatus = [
  { label: DeviceStockStatusEnum.Stock, value: DeviceStockStatusEnum.Stock },
  {
    label: DeviceStockStatusEnum.Awaiting,
    value: DeviceStockStatusEnum.Awaiting,
  },
  {
    label: DeviceStockStatusEnum.Installed,
    value: DeviceStockStatusEnum.Installed,
  },
  {
    label: DeviceStockStatusEnum.Legacy,
    value: DeviceStockStatusEnum.Legacy,
  },
]

export const deviceFilterGroups: FilterGroups = [
  {
    dataKey: "stockStatus",
    label: "Monteringsstatus",
    options: deviceStockStatus,
  },
  {
    dataKey: "deviceType",
    label: "Enhedstype",
    options: deviceTypeObject,
  },
]

export const getDisplayKey = (
  displayKeys: DisplayKey[],
  target: DisplayKeyEnum
) => {
  if (displayKeys) {
    const targetFromDisplayKeys = displayKeys.find((key) => key.name === target)

    return { ...targetFromDisplayKeys }
  }
  return null
}
