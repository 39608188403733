import React, { useContext, useEffect, useRef } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { isEqual } from "lodash-es"
import type Fuse from "fuse.js"
import { useThrottle } from "react-use"
import { Responsive } from "@clevertrack/shared"
import { SearchContext } from "./context"
import { SearchActions, SearchTypes } from "./actions"
import useOnClickOutside from "hooks/useOnClickOutside"
import { SearchInput, SearchInputProps } from "./SearchInput"

const StyledSearch = styled.div`
  ${tw`w-full`}
`

export type SearchProps = SearchInputProps & {
  dataset: Fuse<unknown>
  toggled?: boolean
  title?: string
  children?: React.ReactNode
}

const Search: React.FC<SearchProps> = ({
  dataset,
  toggled,
  title,
  children,
  ...props
}) => {
  const {
    state: { query, results, suggestions },
    dispatch,
  } = useContext(SearchContext)
  const debouncedSearchQuery = useThrottle(query, 0)
  const wrapperRef = useRef(null)
  const inputRef = useRef<HTMLInputElement | null>(null)

  useOnClickOutside(wrapperRef, () => {
    if (inputRef.current) inputRef.current.blur()
  })

  const onSearch = async (q) => {
    const searchResults = await dataset.search(q)
    const returnResults = {
      exact_matches: searchResults.filter((result) => {
        return q.length === 1
          ? +result?.score.toFixed(3) <= 0.01
          : +result?.score.toFixed(3) <= 0.04
      }),
      suggestions: searchResults.filter((result) => {
        return q.length === 1
          ? +result.score.toFixed(3) > 0.01
          : +result.score.toFixed(3) > 0.04
      }),
    }
    return returnResults
  }

  function onChangeHandler(q) {
    dispatch(SearchActions(SearchTypes.SetQuery, { query: q }))
  }

  function onResetQueryHandler() {
    dispatch(SearchActions(SearchTypes.ResetSearch))
    if (props.onReset) props.onReset()
  }

  useEffect(() => {
    if (debouncedSearchQuery) {
      onSearch(debouncedSearchQuery).then(
        ({ exact_matches, suggestions: newSuggestions }) => {
          if (debouncedSearchQuery.length > 0) {
            dispatch(
              SearchActions(SearchTypes.SetQuery, {
                query: debouncedSearchQuery,
              })
            )
            if (!isEqual(results, exact_matches))
              dispatch(
                SearchActions(SearchTypes.SetResults, {
                  results: exact_matches,
                })
              )
            if (!isEqual(suggestions, newSuggestions))
              dispatch(
                SearchActions(SearchTypes.SetSuggestions, {
                  suggestions: newSuggestions,
                })
              )

            if (props.onSubscribe)
              props.onSubscribe([...exact_matches, ...newSuggestions])
          }
        }
      )
    } else {
      dispatch(SearchActions(SearchTypes.ResetSearch))
    }
  }, [debouncedSearchQuery])

  useEffect(() => {
    onSearch(debouncedSearchQuery).then(
      ({ exact_matches, suggestions: newSuggestions }) => {
        if (!isEqual(results, exact_matches))
          dispatch(
            SearchActions(SearchTypes.SetResults, {
              results: exact_matches,
            })
          )
        if (!isEqual(suggestions, newSuggestions))
          dispatch(
            SearchActions(SearchTypes.SetSuggestions, {
              suggestions: newSuggestions,
            })
          )
      }
    )
  }, [dataset])

  return (
    <Responsive
      phone={
        <>
          <SearchInput
            onChange={onChangeHandler}
            onReset={onResetQueryHandler}
            {...props}
            query={query}
          />
          {children}
        </>
      }
      tabletLandscape={
        <>
          <StyledSearch {...props}>
            <SearchInput
              onChange={onChangeHandler}
              onReset={onResetQueryHandler}
              {...props}
              query={query}
            />
          </StyledSearch>
          {children}
        </>
      }
    />
  )
}

export { Search }
