import React from "react"
import { Button, Icon } from "@clevertrack/shared"
import "twin.macro"
import { FileUpload, MimeTypes } from "app/FileUpload"
import { IconSizeEnum } from "lib/Icon"
import { useFirebaseStorage } from "services/storage"
import cogoToast from "@clevertrackdk/cogo-toast"
import { useVertexAI } from "services/vertexai"
import { useTranslation } from "react-i18next"

type PhotoUploadType = {
  photoPropertyKey: string
  photoPropertyValue?: string | null
  photoFilename: string
  photoPath?: string | null
  photoPathPropertyKey?: string | null
  uploadLabel: string
  uploadDescription: string
  onUpdateModel: (key: any, value: string | null) => void
  onDeletePhoto: (key: string) => void
  onAcceptFilesHandler: (any, filename: string, key: string) => void
  onVertexAIResponse?: (any) => void
  vertexPrompt?: string
}

export const PhotoUpload: React.FC<PhotoUploadType> = ({
  photoPropertyKey,
  photoPropertyValue,
  photoFilename,
  photoPath,
  photoPathPropertyKey,
  uploadLabel,
  uploadDescription,
  onAcceptFilesHandler,
  onUpdateModel,
  onDeletePhoto,
  onVertexAIResponse,
  vertexPrompt,
  ...props
}) => {
  const { deleteFileByPath } = useFirebaseStorage()
  const { generateJSONFromImage } = useVertexAI()
  const { t } = useTranslation()

  const onDeleteHandler = async () => {
    try {
      if (photoPath) {
        const result = await deleteFileByPath(photoPath)
        if (onDeletePhoto) onDeletePhoto(photoPropertyKey)
        onUpdateModel(photoPropertyKey, null)
        onUpdateModel(photoPathPropertyKey, null)
      } else {
      }
    } catch (e) {
      cogoToast.error(`${t("installation_error_deleting_photo")}: ${e}`)
    }
  }

  const onAcceptHandler = async (files) => {
    if (onAcceptFilesHandler) {
      onAcceptFilesHandler(files, photoFilename, photoPropertyKey)
    }

    if (onVertexAIResponse && vertexPrompt && files[0]) {
      const result = await generateJSONFromImage(vertexPrompt, files[0].file)
      onVertexAIResponse(result)
    }
  }

  return (
    <>
      {photoPropertyValue && (
        <div tw="w-full h-auto mt-8 relative">
          <Button
            tw="absolute top-4 right-4 z-40 p-2 pl-4"
            onClick={onDeleteHandler}
            variant="danger"
            size="sm"
            type="button"
          >
            <span>{t("installation_delete")}</span>
            <Icon icon="close" size={IconSizeEnum.SM} />
          </Button>
          <img tw="w-full h-auto relative z-30" src={photoPropertyValue} />
        </div>
      )}
      {!photoPropertyValue && (
        <FileUpload
          uploadOnDrop
          onAccept={onAcceptHandler}
          allowedMimeTypes={[MimeTypes.JPEG]}
          uploadLabel={uploadLabel}
          uploadDescription={uploadDescription}
          hideAllowedMimeTypes
        />
      )}
    </>
  )
}
