import { useMemo, useState, useContext } from "react"
import { CollectionFilter } from "utils/collection/filter"
import uniq from "lodash-es/uniq"
import { deviceFilterGroups } from "./helper"
import { FilterGroup } from "app/DataTable/types"
import { Options } from "react-select"
import { DevicesContext } from "./context"
import { AccountsContext } from "app/Account/context"
import { useDeviceConfiguration } from "app/Configuration/hooks"

interface FilterOption {
  label: string
  value: any
}

interface UseDeviceFilterOptions {
  includeAccounts?: boolean
  primaryFilterKey?: string
}

interface UseDeviceFilterReturn {
  selectedFilters: CollectionFilter[]
  setSelectedFilters: (filters: CollectionFilter[]) => void
  availableDeviceFilterGroups: FilterGroup[]
  primaryFilter: FilterGroup | undefined
}

export function useDeviceFilter(
  options: UseDeviceFilterOptions = { includeAccounts: true }
): UseDeviceFilterReturn {
  const {
    state: { devices },
  } = useContext(DevicesContext)
  const {
    state: { accounts },
  } = useContext(AccountsContext)
  const { configurationListOptions } = useDeviceConfiguration()
  const [selectedFilters, setSelectedFilters] = useState<CollectionFilter[]>([])

  const { availableDeviceFilterGroups, primaryFilter } = useMemo(() => {
    const availableDeviceTypes = uniq(devices.map((x) => x.deviceTypeName))
    const availableAccountsOptions = uniq(
      accounts.map((account) => ({ label: account.name, value: account.name }))
    )

    const filterGroups = [
      ...(options.includeAccounts
        ? [
            {
              dataKey: "account",
              label: "Konto",
              options: availableAccountsOptions as Options<unknown>,
            },
          ]
        : []),
      {
        dataKey: "config.name",
        label: "Konfiguration",
        options: configurationListOptions.map((option) => ({
          label: option.label,
          value: option.label,
        })) as Options<unknown>,
      },
      ...deviceFilterGroups.map((group) => {
        if (group.dataKey === "deviceType") {
          const typedOptions = (group.options as unknown) as FilterOption[]
          return {
            ...group,
            dataKey: "deviceTypeName",
            options: typedOptions
              .filter((option) => availableDeviceTypes.includes(option.label))
              .map((option) => ({
                label: option.label,
                value: option.label,
              })) as Options<unknown>,
          }
        }
        return group
      }),
    ]

    // If primaryFilterKey is specified, extract that filter and remove it from the available filters
    let selectedPrimaryFilter: FilterGroup | undefined
    const remainingFilters = [...filterGroups]

    if (options.primaryFilterKey) {
      const filterIndex = remainingFilters.findIndex(
        (filter) => filter.dataKey === options.primaryFilterKey
      )
      if (filterIndex !== -1) {
        selectedPrimaryFilter = remainingFilters[filterIndex]
        remainingFilters.splice(filterIndex, 1)
      }
    }

    return {
      availableDeviceFilterGroups: remainingFilters,
      primaryFilter: selectedPrimaryFilter,
    }
  }, [
    devices,
    accounts,
    configurationListOptions,
    options.includeAccounts,
    options.primaryFilterKey,
  ])

  return {
    selectedFilters,
    setSelectedFilters,
    availableDeviceFilterGroups,
    primaryFilter,
  }
}
