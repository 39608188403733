import React, { useContext, useEffect, useMemo } from "react"
import tw from "twin.macro"
import { InstallationContext } from "../context"
import { FlowHeader, ScreenHeader } from "./components"
import styled from "styled-components"
import { InstallationScreenEnum, InstallationStepStateEnum } from "../types"
import { Button, ButtonGroup, Icon } from "@clevertrack/shared"
import { useInstallation } from "../hooks"
import {
  DeviceStockStatusEnum,
  DeviceTypeCategoryEnum,
} from "app/Devices/types"
import { InstallationActions, InstallationTypes } from "../actions"
import { useFirestoreDevices } from "services/firestore/devices"
import cogoToast from "@clevertrackdk/cogo-toast"
import { useTranslation } from "react-i18next"
import { AccountsContext } from "app/Account/context"
import { ApiAccountTypeEnum } from "app/Account/types"

export const InstallationStepTheme = {
  [InstallationStepStateEnum.NotStarted]: tw`flex items-center`,
  [InstallationStepStateEnum.Incomplete]: tw`flex items-center text-yellow-500`,
  [InstallationStepStateEnum.Completed]: tw`flex items-center text-green-500`,
}

const StyledWrapper = styled.div`
  ${tw`w-full h-full bg-white`}
  display: grid;
  grid-template-rows: auto auto auto 1fr 6.1rem;
`

const StyledTaskList = styled.section`
  display: grid;
  grid-template-rows: auto 1fr;
`

type TasksType = {
  onSelectScreen: (screen: InstallationScreenEnum) => void
}

export const Tasks: React.FC<TasksType> = ({ onSelectScreen, ...props }) => {
  const {
    dispatch,
    state: { device, installationSteps, fotaDevice, account, ...rest },
  } = useContext(InstallationContext)
  const {
    state: { account: installerAccount },
  } = useContext(AccountsContext)
  const {
    deleteInstallationStepsByImei,
    saveFirebaseDevice,
  } = useFirestoreDevices()
  const { deviceTypeCategory, getFotaDevicByIMEI, mapVehicles } = useInstallation()
  const { t } = useTranslation()

  const [completedSteps, totalSteps] = useMemo(() => {
    return installationSteps
      ? [
        installationSteps.filter(
          (step) => step.stepState === InstallationStepStateEnum.Completed
        ).length,
        installationSteps.length,
      ]
      : []
  }, [installationSteps, device])

  const onSelectNewDeviceHandler = () => {
    dispatch(InstallationActions(InstallationTypes.SetScreen, null))
    dispatch(InstallationActions(InstallationTypes.ClearDevice))
  }

  useEffect(() => {
    if (
      device?.imei &&
      fotaDevice === null &&
      ![
        DeviceTypeCategoryEnum.Beacon,
        DeviceTypeCategoryEnum.GPSTrackerWithBattery,
      ].includes(deviceTypeCategory)
    ) {
      getFotaDevicByIMEI(device?.imei)
    }
  }, [device, fotaDevice, deviceTypeCategory])

  const renderStepStateIcon = (stepState: InstallationStepStateEnum | null) => {
    switch (stepState) {
      case InstallationStepStateEnum.Completed:
        return <Icon tw="w-5 h-5 ml-2" icon="check" />
      case InstallationStepStateEnum.Incomplete:
        return <Icon tw="w-5 h-5 ml-2" icon="exclamation-triangle" />
      default:
        return null
    }
  }

  const onCancelInstallationHandler = async () => {
    try {
      await deleteInstallationStepsByImei(device?.imei)
      if (device?.stockStatus) {
        await saveFirebaseDevice(
          {
            installationStartedOnAccountID: null,
            imei: device?.imei.toString(),
            stockStatus: DeviceStockStatusEnum.Stock,
            statusUpdated: +new Date(),
          },
          device?.imei.toString()
        )
      }
      await mapVehicles()
      dispatch(InstallationActions(InstallationTypes.SetScreen, null))
      dispatch(InstallationActions(InstallationTypes.ClearDevice))
    } catch (error) {
      console.log(error)
      cogoToast.error("Kunne ikke fortryde montering")
    }
  }

  return (
    <StyledWrapper>
      <FlowHeader includeBackLink />
      <ScreenHeader>
        {completedSteps} /{" "}
        {[
          DeviceTypeCategoryEnum.Beacon,
          DeviceTypeCategoryEnum.GPSTrackerWithBattery,
        ].includes(deviceTypeCategory)
          ? `2 `
          : `4 `}
        {t("installation_steps_completed")}
      </ScreenHeader>
      {installationSteps && (
        <StyledTaskList>
          <ul tw="m-0 list-none p-0 border border-solid border-0 border-t border-brand-gray-brand">
            {![
              DeviceTypeCategoryEnum.Beacon,
              DeviceTypeCategoryEnum.GPSTrackerWithBattery,
            ].includes(deviceTypeCategory) && (
                <li
                  tw="px-8 py-6 border border-solid border-0 border-b border-brand-gray-brand cursor-pointer"
                  onClick={() =>
                    onSelectScreen(InstallationScreenEnum.DeviceHealthValidation)
                  }
                >
                  <span
                    css={
                      InstallationStepTheme[
                      installationSteps.find(
                        (step) =>
                          step.stepID ===
                          InstallationScreenEnum.DeviceHealthValidation
                      )?.stepState
                      ]
                    }
                  >
                    <span tw="text-2xl">{t("installation_connection")}</span>
                    {renderStepStateIcon(
                      installationSteps.find(
                        (step) =>
                          step.stepID ===
                          InstallationScreenEnum.DeviceHealthValidation
                      )?.stepState
                    )}
                  </span>
                  <span tw="block mt-2 text-xl opacity-60">
                    {t("installation_device_health_validation")}
                  </span>
                </li>
              )}
            <li
              tw="px-8 py-6 border border-solid border-0 border-b border-brand-gray-brand cursor-pointer"
              onClick={() =>
                onSelectScreen(InstallationScreenEnum.InstallationVerification)
              }
            >
              <span
                css={
                  InstallationStepTheme[
                  installationSteps.find(
                    (step) =>
                      step.stepID ===
                      InstallationScreenEnum.InstallationVerification
                  )?.stepState
                  ]
                }
              >
                <span tw="text-2xl">{t("installation_mounting")}</span>
                {renderStepStateIcon(
                  installationSteps.find(
                    (step) =>
                      step.stepID ===
                      InstallationScreenEnum.InstallationVerification
                  )?.stepState
                )}
              </span>
              <span tw="block mt-2 text-xl opacity-60">
                {t("installation_mounting_information_and_images")}
              </span>
            </li>
            {/* <li
            tw="px-8 py-6 border border-solid border-0 border-b border-brand-gray-brand"
            onClick={() =>
              onSelectScreen(InstallationScreenEnum.DataValidation)
            }
          >
            {steps.find(
              (step) => step.stepID === InstallationScreenEnum.DataValidation
            )?.stepCompleted ? (
              <span tw="flex items-center text-green-500">
                <span tw="text-2xl">Datavalidering</span>
                <Icon tw="w-5 h-5 ml-2" icon="check" />
              </span>
            ) : (
              <span tw="text-2xl flex items-center">Datavalidering</span>
            )}
            <span tw="block mt-2 text-xl opacity-60">
              Valg af programnr. og validering af evt. CAN-data
            </span>
          </li> */}
            {![
              DeviceTypeCategoryEnum.Beacon,
              DeviceTypeCategoryEnum.GPSTrackerWithBattery,
            ].includes(deviceTypeCategory) && (
                <li
                  tw="px-8 py-6 border border-solid border-0 border-b border-brand-gray-brand cursor-pointer"
                  onClick={() =>
                    onSelectScreen(InstallationScreenEnum.DeviceCalibration)
                  }
                >
                  <span
                    css={
                      InstallationStepTheme[
                      installationSteps.find(
                        (step) =>
                          step.stepID ===
                          InstallationScreenEnum.DeviceCalibration
                      )?.stepState
                      ]
                    }
                  >
                    <span tw="text-2xl">{t("installation_calibration")}</span>
                    {renderStepStateIcon(
                      installationSteps.find(
                        (step) =>
                          step.stepID === InstallationScreenEnum.DeviceCalibration
                      )?.stepState
                    )}
                  </span>
                  <span tw="block mt-2 text-xl opacity-60">
                    {t("installation_motor_timer_km_stand_and_idle_calibration")}
                  </span>
                </li>
              )}
            <li
              tw="px-8 py-6 border border-solid border-0 border-b border-brand-gray-brand cursor-pointer"
              onClick={() =>
                onSelectScreen(InstallationScreenEnum.DeviceInformation)
              }
            >
              <span
                css={
                  InstallationStepTheme[
                  installationSteps.find(
                    (step) =>
                      step.stepID === InstallationScreenEnum.DeviceInformation
                  )?.stepState
                  ]
                }
              >
                <span tw="text-2xl">
                  {t("installation_account_information")}
                </span>
                {renderStepStateIcon(
                  installationSteps.find(
                    (step) =>
                      step.stepID === InstallationScreenEnum.DeviceInformation
                  )?.stepState
                )}
              </span>
              <span tw="block mt-2 text-xl opacity-60">
                {t("installation_device_information")}
              </span>
            </li>
          </ul>
          <ButtonGroup position="around" tw="mt-auto mb-16 mx-auto px-4">
            {device?.stockStatus === DeviceStockStatusEnum.Awaiting && (
              <Button
                type="button"
                variant="cancel"
                onClick={() => onCancelInstallationHandler()}
              >
                <span tw="flex items-center">
                  <span tw="text-xl font-normal">
                    {t("installation_reset_installation")}
                  </span>
                </span>
              </Button>
            )}
            {(device?.stockStatus === DeviceStockStatusEnum.Installed ||
              device?.stockStatus === DeviceStockStatusEnum.Legacy) && (
                <Button
                  invert
                  type="button"
                  variant="danger"
                  onClick={() => onSelectScreen(InstallationScreenEnum.Uninstall)}
                >
                  <span tw="flex items-center">
                    <span tw="text-xl font-bold">
                      {t("installation_uninstall")}
                    </span>
                  </span>
                </Button>
              )}
            {installerAccount?.description === ApiAccountTypeEnum.Installer &&
              [
                DeviceStockStatusEnum.Installed,
                DeviceStockStatusEnum.Legacy,
              ].includes(device?.stockStatus) && (
                <Button
                  type="button"
                  variant="cancel"
                  tw="font-normal"
                  icon="right"
                  onClick={() =>
                    onSelectScreen(InstallationScreenEnum.ReplaceDevice)
                  }
                >
                  <span tw="flex items-center">
                    <span tw="text-xl font-normal">{t("installation_replace_device")}</span>
                    <Icon icon="sync" tw="w-4 h-4 ml-2" />
                  </span>
                </Button>
              )}
          </ButtonGroup>
        </StyledTaskList>
      )}
      <ButtonGroup sticky="bottom" position="around" tw="px-4 bottom-0">
        <Button
          type="button"
          variant="cancel"
          onClick={() => onSelectNewDeviceHandler()}
        >
          <span tw="flex items-center">
            <Icon icon="chevron-left" tw="w-4 h-4 mr-2" />
            <span tw="text-xl font-normal">
              {t("installation_select_another_unit")}
            </span>
          </span>
        </Button>
      </ButtonGroup>
    </StyledWrapper>
  )
}
